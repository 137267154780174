// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
}

type RHTimePickerComponent = (<TInputDate, TDate = TInputDate>(props: IProps & Partial<TimePickerProps<TInputDate, TDate>>) => JSX.Element) & {
  propTypes?: any;
};

const RHFDatePicker: RHTimePickerComponent = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker {...field} {...other} value={field.value} onChange={e => field.onChange(e)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              fullWidth />
          )} />)} />
  );
}

export default RHFDatePicker;