import { gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid-pro";
import { momentToDateWithoutTime, momentToFormat } from "./formatDate";
import { Moment } from "moment";
import { DateRange } from "@mui/x-date-pickers-pro";
import axiosInstance from "./axios";
import { AxiosResponse } from "axios";

export const getListExportExcel = async (api: string, period: DateRange<Moment>, apiRef: any, fileName: string) => {
    let url = api + "/export";

    let ids = gridFilteredSortedRowIdsSelector(apiRef!)

    let body = { idList: ids.join(","), date_start: momentToDateWithoutTime(period[0]), date_end: momentToDateWithoutTime(period[1]) }
    axiosInstance.post(url, body, { responseType: 'blob' }).then((response: AxiosResponse) => {
        let nb = fileName.lastIndexOf(".")
        let extension = fileName.substring(nb + 1)
        console.log("getListExportExcel - extensions : " + extension)
        fileName = fileName.substring(0, nb) + "_du_" + momentToFormat(period[0], "DD-MM-YYYY") + "_au_" + momentToFormat(period[1], "DD-MM-YYYY") + "." + extension

        const FileDownload = require('js-file-download');
        FileDownload(response.data, fileName);
    })
}

export const downloadFile = async (url: string, fileName: string) => {
    axiosInstance.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
        const FileDownload = require('js-file-download');
        FileDownload(response.data, fileName);
    })
}