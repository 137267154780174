// @mui
import { Box, BoxProps, Typography } from '@mui/material';
import { BreadcrumbsLinkProps } from '../components/custom-breadcrumbs';
import CustomBreadcrumbs from '../components/custom-breadcrumbs/CustomBreadcrumbs';

export interface PageHeaderProps extends BoxProps {
  title?: string;
  activeLast?: boolean;
  links?: BreadcrumbsLinkProps[];
}

export default function PageHeader({ title, sx, links, activeLast }: PageHeaderProps) {
  return (
    <Box className="HEADER" sx={{ mb: 0, p: 1, ...sx }}>
      {/* TITLE */}
      {title && (
        <Typography variant="h4" gutterBottom={links && links.length > 0}>
          {title}
        </Typography>
      )
      }

      {/* BREADCRUMBS */}
      {links && links.length > 0 && <CustomBreadcrumbs links={links} activeLast={activeLast} />}
    </Box >
  );
}