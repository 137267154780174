import { Theme } from "@mui/material/styles";
//

import Alert from "./Alert";
import Breadcrumbs from "./Breadcrumbs";
import Button from "./Button";
import Card from "./Card";
import Checkbox from "./Checkbox";
import DataGrid from "./DataGrid";
import Dialog from "./Dialog";
import Input from "./Input";
import Link from "./Link";
import Menu from "./Menu";
import Paper from "./Paper";
import Radio from "./Radio";
import SvgIcon from "./SvgIcon";
import Switch from "./Switch";
import Table from "./Table";
import Tabs from "./Tabs";
import Tooltip from "./Tooltip";
import Typography from "./Typography";

// ----------------------------------------------------------------------
export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Alert(theme),
    Breadcrumbs(theme),
    Button(theme),
    Card(theme),
    Checkbox(theme),
    DataGrid(theme),
    Dialog(theme),
    Input(theme),
    Link(theme),
    Menu(theme),
    Paper(theme),
    Radio(theme),
    SvgIcon(theme),
    Switch(theme),
    Table(theme),
    Tabs(theme),
    Tooltip(theme),
    Typography(theme),
  );
}
