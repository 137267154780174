import { Media, UploadPhoto } from "./media";
import { ProductTypeStock } from "./product";

export const SITE_API = "sites";

export interface SiteLight {
  id?: number | "";
  name?: string;
}

export interface Site extends SiteLight {
  latitude?: number;
  longitude?: number;
  phone?: string;
  address?: string;
  postcode?: string;
  city?: string;
  upload_photo?: UploadPhoto | string;
  cellars?: Cellar[];
  stocks?: ProductTypeStock[];
  photo?: Media;
}

export interface Cellar {
  id?: number | "";
  name?: string;
}
