import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { FieldList, FormCols, FormWithMethods } from "../../components/hook-form/Form";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { SITE_API, Site } from "../../@types/site";
import axiosInstance from "../../utils/axios";
import { Maintenance, MAINTENANCE_API, MAINTENANCE_TYPE_LABELS, PERIODICITY_TYPE_MAP } from "../../@types/maintenance";
import { SUPPLIER_API, Supplier } from "../../@types/supplier";

interface Props {
    isNew?: boolean;
    maintenance?: Maintenance;
    setMaintenance: (maintenance: Maintenance) => void;
}


// ----------------------------------------------------------------------
export default function MaintenanceInformations({
    isNew,
    maintenance,
    setMaintenance,
}: Props) {
    const { isManager } = useAuth();
    const params = useParams();
    const [sites, setSites] = useState<Site[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);

    useEffect(() => {

        axiosInstance
            .get(SITE_API)
            .then((response: any) => setSites(response.data));

        axiosInstance
            .get(SUPPLIER_API)
            .then((response: any) => setSuppliers(response.data));
    }, []);


    useEffect(() => {
        //pour récupérer les données
        if (params.id !== undefined)
            axiosInstance
                .get(MAINTENANCE_API + "/" + params.id)
                .then((response: any) => setMaintenance(response.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);


    const schema = Yup.object().shape({
        object: Yup.string().required("Objet obligatoire"),
        comment: Yup.string(),
        sites: Yup.array().min(1, "Site obligatoire").required("Site obligatoire"),
        supplier: Yup.object().nullable().required("Prestataire obligatoire"),
        maintenanceType: Yup.string(),
        periodicityType: Yup.string(),
        // periodicityComment: Yup.string(),
        // frequency: Yup.number().nullable(),
    });

    const defaultValues = useMemo(() => {

        return {
            id: maintenance?.id ?? undefined,
            object: maintenance?.object ?? "",
            comment: maintenance?.comment ?? "",
            sites: (maintenance?.sites) ? maintenance?.sites?.map((s: any) => (s.id)) ?? [] : [],
            supplier: maintenance?.supplier ?? "",
            maintenanceType: maintenance?.maintenanceType ?? "1",
            periodicityType: maintenance?.periodicityType ?? "1",
            periodicityComment: maintenance?.periodicityComment ?? "",
            frequency: maintenance?.frequency ?? "",
        }
    }, [maintenance])

    const methods = useForm<Maintenance>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const { watch } = methods;

    const periodicityTypeWatch = watch("periodicityType");

    const sitesItems = useMemo(
        () => sites.map((s) => ({ id: s.id, nom: s.name })),
        [sites]
    );

    const suppliersItems = useMemo(
        () => suppliers.map((s) => ({ id: s.id, nom: s.name })),
        [suppliers]
    );

    // useEffect(() => console.log("suppliersItems : ", suppliersItems, [suppliersItems]))
    // useEffect(() => console.log("sitesItems : ", sitesItems, [sitesItems]))


    const title = params.id ? "Maintenance : " + maintenance?.object : "Nouvelle maintenance";

    const formCols: FormCols<Maintenance>[] = useMemo(() => {

        return [
            {
                xs: 12,
                elements: [
                    [
                        {
                            name: "object",
                            label: "Objet de la maintenance",
                            required: true,
                            xs: 6,
                        },
                        {
                            name: "sites",
                            label: "Sites",
                            type: "select",
                            mode: "id",
                            selectOptions: sitesItems,
                            options: { SelectProps: { multiple: true } },
                            xs: 6,
                        },
                        {
                            name: "maintenanceType",
                            label: "Type",
                            required: true,
                            type: "radio",
                            options: Object.entries(MAINTENANCE_TYPE_LABELS).map(([key, value]) => ({ value: +key, label: value })),
                            xs: 6,
                        },
                        {
                            name: "supplier",
                            label: "Prestataire",
                            type: "select",
                            mode: "object",
                            selectOptions: suppliersItems,
                            xs: 6,
                        },
                        {
                            name: "periodicityType",
                            label: "Type",
                            required: true,
                            type: "radio",
                            options: Object.entries(PERIODICITY_TYPE_MAP).map(([key, value]) => ({ value: +key, label: value })),
                            xs: 6,
                        },
                        ...(periodicityTypeWatch !== "9" && periodicityTypeWatch !== 9 ? ([{
                            name: "frequency",
                            label: "Frenquence",
                            xs: 6,
                        }]) as FieldList<Maintenance> : ([{
                            name: "periodicityComment",
                            label: "Précision",
                            xs: 6,
                        }]) as FieldList<Maintenance>),
                        {
                            name: "comment",
                            label: "Commentaire",
                            options: { multiline: true, minRows: 3 },
                            required: false,
                        },
                    ]
                ]
            }
        ]
    }, [sitesItems, suppliersItems, periodicityTypeWatch, maintenance])

    const transformDataBeforeSubmit = (data: any) => {

        if (data.frequency === "") {
            data.frequency = undefined
        }

        if (data.sites) {
            let newSites = data.sites.map((site: any) => ({ id: site }))
            data.sites = newSites
        }

        return data

    }


    return (
        <FormWithMethods<Maintenance, Yup.AnyObjectSchema>
            formCols={formCols}
            methods={methods}
            schema={schema}
            submitButtonProps={{ fullWidth: true, size: "large" }}
            obj={maintenance}
            setObj={setMaintenance}
            isNew={params.id === undefined}
            isEdit={true}
            canDelete={isManager}
            defaultValues={defaultValues}
            service={MAINTENANCE_API}
            transformDataBeforeSubmit={transformDataBeforeSubmit}
        />
    );
}
