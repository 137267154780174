import { Box, Stack } from "@mui/material";
import { MyLog } from "../@types/myLog";
import { dateTimeDotNetToDateTime } from "../utils/formatDate";

interface LogsProps {
    logs?: MyLog[]
}

export default function Logs(props: LogsProps) {
    const { logs } = props
    if (logs) {
        return <Stack>{logs.map((log: MyLog, index: number) => <Box style={{ color: "GrayText", fontStyle: "italic" }} key={"log" + index}><span style={{ fontWeight: 'normal' }}>{dateTimeDotNetToDateTime(log.date)}</span> : <span style={{ fontWeight: 'lighter' }}>{log.message}</span></Box>)}</Stack>
    }
    return null
}