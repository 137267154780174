import { GridValidRowModel } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import List, { ListProps, ListWithApiRef } from "../components/datagrid/List";
import axiosInstance from "../utils/axios";
import Page from "./Page";
import { PageHeaderProps } from "./PageHeader";

interface SpecificListPageProps<T> {
  getListMethod?: string;
  urlFilterText?: string;
}

// ----------------------------------------------------------------------
export default function ListPage<T extends GridValidRowModel>(
  props: Omit<ListProps<T>, "rows" | "setRows"> & PageHeaderProps & SpecificListPageProps<T>
) {
  const { title, service, links, getListMethod, urlFilterText, canCreate = true, ...listProps } = props;
  const [rows, setRows] = useState<T[]>([]);

  useEffect(() => {
    axiosInstance.get(service + (getListMethod ? "/" + getListMethod : "") + (urlFilterText ? "?" + urlFilterText : "")).then((response: any) => {
      setRows(response.data);
    });
  }, [urlFilterText]);

  return (
    <Page title={title} HeaderProps={{ links }}>
      {listProps.setApiRef ?
        <ListWithApiRef<T> {...listProps} canCreate={canCreate} service={service} rows={rows} setRows={setRows} /> :
        <List<T> {...listProps} canCreate={canCreate} service={service} rows={rows} setRows={setRows} />
      }
    </Page >
  );
}
