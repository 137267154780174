import { Icon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import {
  Description,
  DESCRIPTION_API,
  DESCRIPTION_DEFAULT_TYPE,
} from "../../../@types/description";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_HOME } from "../../../routes/paths";

export default function DescriptionListPage() {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "libelle",
        headerName: "Libellé",
        editable: true,
        flex: 1,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<Description>
      title="Liste des descriptions"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des descriptions" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      editable
      defaultRow={DESCRIPTION_DEFAULT_TYPE}
      service={DESCRIPTION_API}
      deletable={true}
    />
  );
}

function searchFunc(type: Description, filterValue: string) {
  return type.libelle?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
