import { Icon, Switch } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  frFR,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ICONS from "../../assets/icons";
import axiosInstance from "../../utils/axios";
import DeleteconfirmDialog from "../confirm-dialog/DeleteConfirmDialog";
import { SERVICE_DISPLAY_NAME } from "../hook-form/Form";

interface SpecificCopastockGridProps<R extends GridValidRowModel> {
  togglable?: boolean;
  togglableData?: {
    field: string;
    headerName: string;
  };
  actions?: GridColumns<R>;
  service?: string;
  setRows?: (newRows: R[]) => void;
  deletable?: boolean;
  noActions?: boolean;
  setApiRef?: Function;
  onEdit?: Function;
}

export type CopastockDataGridProps<R extends GridValidRowModel> =
  DataGridProProps<R> &
  React.RefAttributes<HTMLDivElement> &
  SpecificCopastockGridProps<R> & { customRedirectUrl?: string, customId?: string };

export default function CopastockDataGrid<R extends GridValidRowModel = any>(
  props: CopastockDataGridProps<R>
) {
  const {
    togglable,
    togglableData,
    actions,
    service,
    setRows,
    columns,
    pagination,
    setApiRef,
    ...other
  } = props;
  const newColumns = [...columns];

  if (togglable) {
    const toggle = (row: R) => (e: any, checked: boolean) => {
      service &&
        axiosInstance.put(service, {
          ...row,
          [togglableData?.field ?? "activated"]: checked,
        });

      let updatedRow = props.rows.find((r) => r.id === row.id) as R & {
        [key in string]: boolean;
      };

      if (updatedRow && (togglableData?.field ?? "activated") in updatedRow) {
        (updatedRow as any)[togglableData?.field ?? "activated"] = checked;
        setRows && setRows([...props.rows]);
      }
    };

    newColumns.push({
      field: togglableData?.field ?? "activated",
      headerName: togglableData?.headerName ?? "Actif",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Switch
            checked={params.value}
            onChange={toggle(params.row)}
            color="primary"
          />
        );
      },
    });
  }

  if (actions) {
    newColumns.push(...actions);
  }

  const apiRef = useGridApiRef();
  useEffect(() => {
    if (setApiRef) {
      setApiRef(apiRef);
    }
  }, [setApiRef, apiRef]);

  return (
    <DataGridPro
      autoHeight
      apiRef={apiRef}
      {...other}
      columns={newColumns}
      pagination={pagination ?? true}
      disableColumnMenu={true}
      initialState={{
        pagination: {
          pageSize: 25,
        },
      }}
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
    />
  );
}

export function EditableCopastockDataGrid<R extends GridValidRowModel = any>(
  props: CopastockDataGridProps<R>
) {
  const { setRows, rows, service, deletable } = props;
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [toDelete, setToDelete] = React.useState<R>();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  useEffect(() => {
    const newRowModesModel = { ...rowModesModel };
    if (service) {
      rows
        .filter((r) => r.id < 0)
        .forEach((r) => {
          newRowModesModel[r.id] = {
            mode: GridRowModes.Edit,
            fieldToFocus: props.columns[0].field,
          };
        });
    } else {
      rows
        .filter((r) => r.new === true)
        .forEach((r) => {
          newRowModesModel[r.id] = {
            mode: GridRowModes.Edit,
            fieldToFocus: props.columns[0].field,
          };
        });
    }
    setRowModesModel(newRowModesModel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: props.columns[0].field },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (service) {
      if (editedRow!.id < 0) {
        setRows && setRows(rows.filter((row) => row.id !== id));
      }
    } else {
      if (editedRow?.new === true) {
        setRows &&
          setRows(
            rows.map((row) => {
              if (row.id === editedRow!.id) {
                delete editedRow!.new;
                return editedRow;
              }
              return row;
            })
          );
      }
    }
  };

  const processRowUpdate = async (newRow: R) => {
    let newRows = [];
    if (service) {
      let response: AxiosResponse<any, any> | null = null;
      try {
        if (newRow.id > 0) {
          response = await axiosInstance.put(service, newRow);
        } else {
          response = await axiosInstance.post(service, newRow);
        }
        enqueueSnackbar(
          `${SERVICE_DISPLAY_NAME[service as keyof typeof SERVICE_DISPLAY_NAME]
          } ${newRow.id > 0 ? "modifié(e)" : "créé(e)"}`,
          { variant: "success" }
        );
      } catch (e) {
        enqueueSnackbar(
          `La ${newRow.id > 0 ? "modification" : "création"} de ${SERVICE_DISPLAY_NAME[service as keyof typeof SERVICE_DISPLAY_NAME]
          } a échoué.`,
          { variant: "error" }
        );
        console.error(e);
      }
      newRows = rows.map((row) =>
        row.id === newRow.id ? response?.data : row
      );
    } else {
      newRows = rows.map((row) => {
        if (row.id === newRow!.id) {
          delete newRow!.new;
          return newRow;
        }
        return row;
      });
    }
    setRows && setRows(newRows);

    return newRow as R;
  };

  const actionColumns: GridColumns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      resizable: false,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        if (row.editable === false)
          return [];

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Icon>{ICONS.save}</Icon>}
              label="Save"
              onClick={handleSaveClick(row.id)}
              color="primary"
              key="save"
            />,
            <GridActionsCellItem
              icon={<Icon>{ICONS.cancel}</Icon>}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="default"
              key="cancel"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Icon>{ICONS.edit}</Icon>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="primary"
            key="edit"
          />,
          deletable ? (
            <DeleteAction
              handleDeleteClick={() => {
                setToDelete(row);
                setShowDeleteDialog(true);
              }}
              id={row.id}
              service={service}
              setRows={setRows}
              rows={[]}
              key="delete"
            />
          ) : (<></>),
        ];
      },
    },
  ];

  return (
    <>
      <DeleteconfirmDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        service={`${service}/${toDelete?.id}`}
        name={
          SERVICE_DISPLAY_NAME[service as keyof typeof SERVICE_DISPLAY_NAME] +
          " " +
          (toDelete?.name ||
            toDelete?.libelle ||
            toDelete?.valeur ||
            [toDelete?.firstName, toDelete?.lastName].join(" ").trim())
        }
        callback={() => {
          setRows && setRows(rows.filter((row) => row.id !== toDelete?.id));
        }}
      />
      <CopastockDataGrid
        {...props}
        rows={rows}
        actions={actionColumns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        editMode="row"
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
      />
    </>
  );
}

export function NonEditableCopastockDataGrid<R extends GridValidRowModel = any>(
  props: CopastockDataGridProps<R>
) {
  const { rows, setRows, service, deletable, noActions, onEdit } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [toDelete, setToDelete] = React.useState<R>();

  const actionColumns: GridColumns = noActions
    ? []
    : [
      {
        field: "actions",
        type: "actions",
        headerName: "",
        resizable: false,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<Icon>{ICONS.edit}</Icon>}
              label="Éditer"
              className="textPrimary"
              onClick={() => onEdit ? onEdit(row) :
                navigate(
                  (props.customRedirectUrl ?? location.pathname) +
                  "/" +
                  ((props.customId) ? row[props.customId] : row.id)
                )
              }
              color="primary"
            />,
            deletable ? (
              <DeleteAction
                handleDeleteClick={() => {
                  setToDelete(row);
                  setShowDeleteDialog(true);
                }}
                id={row.id}
                service={service}
                rows={rows}
                setRows={setRows}
              />
            ) : (<></>),
          ];
        },
      },
    ];

  return (
    <>
      <DeleteconfirmDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        service={`${service}/${toDelete?.id}`}
        name={
          SERVICE_DISPLAY_NAME[service as keyof typeof SERVICE_DISPLAY_NAME] +
          " " +
          (toDelete?.name ||
            toDelete?.libelle ||
            toDelete?.valeur ||
            [toDelete?.firstName, toDelete?.lastName].join(" ").trim())
        }
        callback={() => {
          setRows && setRows(rows.filter((row) => row.id !== toDelete?.id));
        }}
      />
      <CopastockDataGrid {...props} actions={actionColumns} />
    </>
  );
}

interface DeleteActionProps<R> {
  id: GridRowId;
  service?: string;
  rows: readonly R[];
  setRows?: (newRows: R[]) => void;
  handleDeleteClick?: () => void;
}

function DeleteAction<R extends GridValidRowModel>({
  id,
  handleDeleteClick,
}: DeleteActionProps<R>) {
  return (
    <GridActionsCellItem
      icon={<Icon>{ICONS.delete}</Icon>}
      label="Supprimer"
      onClick={handleDeleteClick}
      color="error"
    />
  );
}
