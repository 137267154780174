import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME, PATH_MAINTENANCE_EVENT } from "../../../routes/paths";
import { GetLibPeriodicity, MAINTENANCE_TYPE, MAINTENANCE_TYPE_CODES } from "../../../@types/maintenance";
import ExportButton from "../../../components/ExportButton";
import { dateTimeDotNetToDate, dateTimeDotNetToTimeStamp } from "../../../utils/formatDate";
import moment from "moment";
import { MaintenanceSite, MAINTENANCE_SITE_API } from "../../../@types/maintenanceSite";

// ----------------------------------------------------------------------
export default function MaintenanceListPage() {

  let today = moment()
  let immientDate = moment().add(1, "M")

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "supplier",
        headerName: "Prestataire",
        editable: false,
        flex: 1,
        valueGetter: (params: any) => (params.value == null) ? "" : params.value?.name,
      },
      {
        field: "site",
        headerName: "Site",
        editable: false,
        valueGetter: (params: any) => (params.value == null) ? "" : params.value?.name,
        flex: 1,
      },
      {
        field: "maintenanceType",
        headerName: "Type",
        editable: false,
        valueGetter: (params: any) => MAINTENANCE_TYPE_CODES[params.value as MAINTENANCE_TYPE],
        flex: 0.5,
      },
      {
        field: "object",
        headerName: "Objet",
        editable: false,
        flex: 4,
        renderCell: (params: any) => (
          <Link href={PATH_ADMIN.maintenance.root + "/" + params.row.maintenanceId}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "periodicityType",
        headerName: "Périodicité",
        editable: false,
        valueGetter: (params: any) => GetLibPeriodicity(params.row),

      },
      {
        field: "lastDate",
        headerName: "Dernière",
        editable: false,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_MAINTENANCE_EVENT.root + "/" + params.row.lastMaintenanceEventeId}>
            {dateTimeDotNetToDate(params.value)}
          </Link>
        ),
      },
      {
        field: "nextDate",
        headerName: "Prochaine",
        editable: false,
        renderCell: (params: any) => {
          let color = "green"
          if (params.value) {
            let m = dateTimeDotNetToTimeStamp(params.value)
            if (params.value && m.isBefore(today)) {
              color = "red"
            } else if (params.value && m.isBefore(immientDate)) {
              color = "orange"
            }
          }

          return <div style={{ color, fontWeight: 'bold' }}> {dateTimeDotNetToDate(params.value)}</div >
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [immientDate, today]
  );

  return (
    <ListPage
      title="Liste des maintenances"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des maintenances" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_ADMIN.maintenance.new}
      service={MAINTENANCE_SITE_API}
      customId="maintenanceId"
      deletable={false}
      headerActions={[<ExportButton api={MAINTENANCE_SITE_API} key="export" fileName="maintenances.xlsx" />]}
    />
  );
}

function searchFunc(maintenance: MaintenanceSite, filterValue: string) {
  filterValue = filterValue.toLowerCase();
  return maintenance.object?.toLowerCase().indexOf(filterValue) !== -1 ||
    maintenance.comment?.toLowerCase().indexOf(filterValue) !== -1 ||
    ((maintenance.site !== "") && maintenance.site?.name?.toLowerCase().indexOf(filterValue) !== -1) ||
    ((maintenance.supplier !== "") && maintenance.supplier?.name?.toLowerCase().indexOf(filterValue) !== -1)

}
