import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { Box, Icon, IconButton, Stack, TextField } from "@mui/material";
import ICONS from "../../assets/icons";
import moment, { Moment } from "moment";

interface DateFilterProps {
    period: DateRange<any>;
    setPeriod: (date: DateRange<any>, keyboardInputValue?: string) => void;
}

export function DateFilter(props: DateFilterProps) {
    const { period, setPeriod } = props

    const setMonth = (before: boolean) => {
        let dtStart: Moment = period[0].clone();
        if (dtStart.date() == 1) {
            dtStart = dtStart.add((before) ? -1 : 1, "month")
        } else {
            dtStart = dtStart.add(1 - dtStart.date(), "day")
            if (!before) {
                dtStart.add(1, "month")
            }
        }
        let dtEnd = dtStart.clone()
        dtEnd.add(1, "month").add(-1, "day")
        setPeriod([dtStart, dtEnd])
    }

    return (
        <Box sx={{ width: 300 }}>
            <Stack direction="row" sx={{ flex: 1 }} >
                <IconButton onClick={() => setMonth(true)} ><Icon>{ICONS.left}</Icon></IconButton>
                <DateRangePicker
                    key="rangePicker"
                    calendars={1}
                    value={period}
                    onChange={setPeriod}
                    renderInput={(startProps, endProps) => (<>
                        <TextField {...startProps} size="small" />
                        <Box sx={{ mx: 0.2 }} />
                        <TextField {...endProps} size="small" />
                    </>)}
                />
                <IconButton onClick={() => setMonth(false)} ><Icon>{ICONS.right}</Icon></IconButton>
            </Stack>
        </Box>
    );
}