import { Button, Icon } from "@mui/material"
import ICONS from "../assets/icons"
import { ProductSiteQuantity } from "../@types/product"
import { useState } from "react"
import ConfirmDialog from "./confirm-dialog"
import axiosInstance from "../utils/axios"
import { STOCK_API } from "../@types/stock"
import { enqueueSnackbar } from "./snackbar"
import { useProductContext } from "../contexts/ProductContext"
import { Role } from "../@types/user"
import useAuth from "../hooks/useAuth"
import NoAccessDialog from "./confirm-dialog/NoAccessDialog"

interface ProductSiteStatusProps {
    data: ProductSiteQuantity
    // product?: Product
}

export default function ProductSiteStatus(props: ProductSiteStatusProps) {
    const { data } = props
    const [showConfirm, setShowConfirm] = useState(false)
    const [showNoAccessDialog, setShowNoAccessDialog] = useState(false)
    const { product, setProduct } = useProductContext()


    //console.log("ProductStatus - data : ", data)

    const changeStatuts = () => {
        let body = { productId: product?.id, siteId: data.site.id, isBlocked: !data.isBlocked }
        axiosInstance
            .put(STOCK_API + "/updateProductSiteStatus", body)
            .then((res: any) => {
                console.log("ProductStatus - res.data : ", res.data)
                let msg = res.data.msg;
                if (!res.data.isUpdated) {
                    enqueueSnackbar(
                        msg,
                        { variant: "info" }
                    );
                } else {

                    if (product) {

                        console.log("ProductStatus - product : ", product)
                        let newStock = product.stocks?.find((stock: any) => stock.siteId == data.siteId)
                        if (!newStock) {

                            console.log("ProductStatus - newStock not found for siteId : " + data.siteId + " in product : ", product)
                        } else {
                            newStock.isBlocked = res.data.isBlocked

                            let stocks = product.stocks?.map((stock: any) => (stock.siteId == data.siteId) ? newStock : stock)
                            console.log("ProductStatus - stocks : ", stocks)

                            let newLogs: any
                            let newLog = { date: res.data.dt, message: msg }
                            if (product.logs && product.logs.length > 0) {
                                newLogs = product.logs
                                newLogs.push(newLog)
                            } else {
                                newLogs = [newLog]
                            }
                            let newProduct = { ...product, stocks, logs: newLogs }


                            console.log("ProductStatus - newProduct : ", newProduct)

                            setProduct(newProduct)
                        }
                    }

                    setShowConfirm(false);
                    enqueueSnackbar(
                        msg,
                        { variant: "success" }
                    );
                }
            })
            .catch((e) => {
                enqueueSnackbar(
                    "erreur",
                    { variant: "error" }
                );
                console.error(e);
            });
    }

    const { user } = useAuth();
    const onClickStock = () => {
        if (user?.authorities?.some(a => a === Role.ROLE_MANAGER)) {
            setShowConfirm(!showConfirm)
        } else {
            setShowNoAccessDialog(true)
        }
    }

    return <>
        <Button sx={{ mx: 0, px: 6, border: "solid 1px" }}
            color={(data.isBlocked) ? "error" : "primary"}
            onClick={onClickStock}
            startIcon={<Icon>{(data.isBlocked) ? ICONS.lock : ICONS.unlock}</Icon>}>{(data.isBlocked) ? "Bloqué" : "Libérable"}</Button>
        <ConfirmDialog open={showConfirm} onClose={() => setShowConfirm(false)} title={"Stock du produit " + product?.reference + " sur " + data.site.name}
            content={
                <>
                    Confirmez vous {(data.isBlocked) ? "la libéreration" : "le blocage"} du produit :
                    <strong> {product?.reference} </strong>
                    sur le site de :
                    <strong> {data.site.name} </strong> ?
                </>
            }
            action={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={changeStatuts}

                >
                    {(data.isBlocked) ? "Oui, je libère" : "Oui, je bloque"}
                </Button>
            } />
        <NoAccessDialog open={showNoAccessDialog} onClose={() => setShowNoAccessDialog(false)} />
    </>
}