import { Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import ICONS from "../../assets/icons";
import Page from "../../layouts/Page";
import { PATH_ADMIN, PATH_HOME, PATH_MAINTENANCE_EVENT } from "../../routes/paths";
import { FormCols, FormWithMethods } from "../../components/hook-form/Form";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { SITE_API, Site, SiteLight } from "../../@types/site";
import axiosInstance from "../../utils/axios";
import { MAINTENANCE_EVENT_API, MaintenanceEvent } from "../../@types/maintenanceEvent";
import { MAINTENANCE_API, Maintenance } from "../../@types/maintenance";
import { SUPPLIER_API, Supplier } from "../../@types/supplier";
import { dateTimeDotNetToDate, momentToDateWithoutTime } from "../../utils/formatDate";

// ----------------------------------------------------------------------
export default function MaintenanceEventPage() {
  const { isManager } = useAuth();
  const params = useParams();
  const [maintenances, setMaintenances] = useState<Maintenance[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [maintenanceEvent, setMaintenanceEvent] = useState<MaintenanceEvent | undefined>(undefined);

  useEffect(() => {

    axiosInstance
      .get(MAINTENANCE_API)
      .then((response: any) => setMaintenances(response.data));

    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));

    axiosInstance
      .get(SUPPLIER_API)
      .then((response: any) => setSuppliers(response.data));
  }, []);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(MAINTENANCE_EVENT_API + "/" + params.id)
        .then((response: any) => setMaintenanceEvent(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    //pour récupérer les données
    if (params.maintenanceid !== undefined && maintenances) {

      console.log("useEffect - params.maintenanceid : ", params.maintenanceid)

      let maintenance = maintenances.find((m: any) => m.id == params.maintenanceid)
      if (maintenance) {
        setValue("maintenance", maintenance)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.maintenanceid, maintenances]);


  const schema = Yup.object().shape({
    date: Yup.date().required("Date obligatoire"),
    comment: Yup.string(),
    maintenance: Yup.object().nullable().required("Maintenance obligatoire"),
    site: Yup.object().nullable().required("Site obligatoire"),
    supplier: Yup.object().nullable().required("Prestataire obligatoire"),
  });

  const defaultValues = useMemo(() => {

    return {
      id: maintenanceEvent?.id ?? undefined,
      date: maintenanceEvent?.date ?? momentToDateWithoutTime(),
      site: maintenanceEvent?.site ?? "",
      supplier: maintenanceEvent?.supplier ?? "",
      maintenance: maintenanceEvent?.maintenance ?? "",
      comment: maintenanceEvent?.comment ?? "",
      upload_photos: maintenanceEvent?.upload_photos ?? maintenanceEvent?.photos,
      photos: maintenanceEvent?.photos,
    }
  }, [maintenanceEvent])

  const methods = useForm<MaintenanceEvent>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, setValue } = methods;
  const maintenanceWatch = watch("maintenance");

  const maintenanceItems = useMemo(
    () => maintenances.map((s: Maintenance) => ({ id: s.id, nom: s.object })),
    [maintenances]
  );

  const sitesItems = useMemo(
    () => {
      let sitesFiltered = sites

      if (maintenanceWatch) {
        let maintenance = maintenances.find((m: Maintenance) => m.id === maintenanceWatch.id)
        if (maintenance && maintenance.sites) {
          sitesFiltered = sites.filter((s: Site) => maintenance?.sites && maintenance?.sites.find((x) => x.id === s.id) != null)
        }
      }
      return sitesFiltered.map((s: Site) => ({ id: s.id, nom: s.name }))
    },
    [sites, maintenances, maintenanceWatch]
  );

  const supplierItems = useMemo(
    () => suppliers.map((c: Supplier) => ({ id: c.id, nom: c.name })),
    [suppliers]
  );


  useEffect(() => {
    if (maintenanceWatch) {
      let maintenance = maintenances.find((m: Maintenance) => m.id === maintenanceWatch.id)
      if (maintenance) {
        let supplier = maintenance.supplier
        if (supplier) {

          let supplierItem = { id: supplier.id, nom: supplier.name }
          setValue("supplier", supplierItem)
        }

        if (maintenance.sites && maintenance.sites.length === 1) {
          let site = maintenance.sites[0];
          if (site) {
            site = site as SiteLight
            let siteItem = { id: site.id, nom: site.name }
            setValue("site", siteItem)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceWatch])

  const title = params.id ? "Opération de maintenance du " + dateTimeDotNetToDate(maintenanceEvent?.date) : "Nouvelle opération de maintenance";

  const formCols: FormCols<MaintenanceEvent>[] = useMemo(() => {
    return [
      {
        xs: 12,
        elements: [
          [
            {
              name: "date",
              label: "Date",
              required: true,
              type: "date",
              xs: 6,
            },
            {
              name: "maintenance",
              label: "Maintenance",
              type: "select",
              mode: "object",
              selectOptions: maintenanceItems,
              xs: 6,
            },
            {
              name: "supplier",
              label: "Prestataire",
              type: "select",
              mode: "object",
              selectOptions: supplierItems,
              xs: 6,
            },
            {
              name: "site",
              label: "Site",
              type: "select",
              mode: "object",
              selectOptions: sitesItems,
              xs: 6,
            },
            {
              name: "comment",
              label: "Commentaire",
              options: { multiline: true, minRows: 3 },
              required: false,
            },
            {
              name: "upload_photos",
              label: "PJ",
              type: "files",
              required: false,
              accept: '',
              title: "Pièce(s) jointe(s)"
            },
          ]
        ]
      }
    ]
  }, [maintenanceItems, supplierItems, sitesItems])


  let links: any[] = [
    {
      name: "Accueil",
      href: PATH_HOME,
      icon: <Icon>{ICONS.home}</Icon>,
    },
    { name: "Liste des opérations", href: PATH_MAINTENANCE_EVENT.root }
  ]

  if (isManager && maintenanceWatch) {
    links.push({ name: maintenanceWatch.object, href: PATH_ADMIN.maintenance.root + "/" + maintenanceWatch.id })
  }
  links.push({ name: params.id ? "Opération du " + dateTimeDotNetToDate(maintenanceEvent?.date) : "Nouvelle opération" })

  return (
    <Page
      title={title}
      withContainer={!params.id}
      HeaderProps={{
        links,
      }}
    >
      <FormWithMethods<MaintenanceEvent, Yup.AnyObjectSchema>
        formCols={formCols}
        methods={methods}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        obj={maintenanceEvent}
        setObj={setMaintenanceEvent}
        isNew={params.id === undefined}
        isEdit={true}
        canDelete={isManager}
        defaultValues={defaultValues}
        service={MAINTENANCE_EVENT_API}
      />


    </Page>
  );
}
