import { Button, Icon, Tooltip } from "@mui/material";
import ICONS from "../assets/icons";
import { dateTimeDotNetToLogDateTime } from "../utils/formatDate";
import { AvancementPrestation } from "../@types/prestation";

interface IconBlockedProps {
    isChecked?: boolean,
    avancementPrestation?: AvancementPrestation
    tooltipMsg?: string
    // checkedBy?: string
    // checkedDate?: Date
    onClick?: any
}

export default function IconIsCheck(props: IconBlockedProps) {
    const { isChecked, tooltipMsg, avancementPrestation, onClick } = props

    if (isChecked == undefined) {
        return <></>
    }

    let icon = (isChecked) ? <Icon color="success">{ICONS.check}</Icon> : <Icon color="error">{ICONS.close}</Icon>

    if (onClick) {
        icon = <Button onClick={() => onClick()}>{icon}</Button>
    } else {
        icon = <Button>{icon}</Button>
    }
    let title = tooltipMsg
    if (!tooltipMsg && avancementPrestation) {
        //console.log("IconIsCheck - checkedDate : " + checkedDate + " - checkedBy " + checkedBy)
        if (avancementPrestation.isChecked) {
            title = "validé par " + avancementPrestation.checkedBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.checkedDate)
        } else {
            if (avancementPrestation.uncheckedBy && avancementPrestation.uncheckedDate) {
                title = "validation supprimée par " + avancementPrestation.uncheckedBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.uncheckedDate)
            }
        }
    }

    if (title) {
        return <Tooltip title={title} >{icon}</Tooltip>
    }

    return icon


};