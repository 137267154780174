// @mui
import { styled, alpha } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemIcon } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// config
//
import { NavItemProps } from '../types';
import { ICON, NAV } from '../../../config';

// ----------------------------------------------------------------------

type StyledItemProps = Omit<NavItemProps, 'item'>;

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open',
})<StyledItemProps>(({ active, disabled, open, depth, theme }) => {
  const subItem: boolean = depth !== 1;

  const activeStyle = {
    opacity: 0.9,
    color: theme.palette.primary.light,
    backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.selectedOpacity)
  };

  const activeSubStyle: any = {
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
  };

  const hoverStyle = {
    opacity: 0.9,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.action.hover,
  };

  return {
    flexShrink: 0,
    display: 'inline-flex',
    padding: theme.spacing(0, 0.75),
    color: subItem ? theme.palette.text.primary : theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    height: NAV.H_DASHBOARD_ITEM_HORIZONTAL,
    '&:hover': hoverStyle,
    // Sub item
    ...(subItem && {
      width: '100%',
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
    // Active sub item
    ...(subItem &&
      active && {
      ...activeSubStyle,
      '&:hover': {
        ...activeSubStyle,
      },
    }),
    // Open
    ...(open && !active && hoverStyle),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        opacity: 0.64,
      },
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  marginRight: 8,
  flexShrink: 0,
  width: ICON.NAV_ITEM_HORIZONTAL,
  height: ICON.NAV_ITEM_HORIZONTAL,
  minWidth: ICON.NAV_ITEM_HORIZONTAL,
  color: 'inherit'
});

// ----------------------------------------------------------------------

export const StyledPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 300,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    boxShadow: theme.customShadows.dropdown,
    borderRadius: theme.shape.borderRadius,
    ...bgBlur({ color: theme.palette.background.default }),
  },
}));
