import { useMemo } from "react";
import { capitalize, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import Page from "../../layouts/Page";
import axios from "axios";

import * as Yup from "yup";
import Form, { FormCols } from "../../components/hook-form/Form";
import { HOST_API } from "../../config";
import useAuth from "../../hooks/useAuth";
import { nameForAuthority, Role } from "../../@types/user";
import Container from "@mui/material/Container";

interface FormValuesProps {
  firstName: string;
  lastName: string;
  email: string;
}

export default function SettingsPage() {
  const { user, update } = useAuth();

  const SettingsSchema = Yup.object().shape({
    firstName: Yup.string().required("Prénom obligatoire"),
    lastName: Yup.string().required("Nom obligatoire"),
    email: Yup.string().required("Email obligatoire"),
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axios
        .post(HOST_API + "/api/account/", {
          id: user?.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        })
        .then((res: any) => {
          if (res.data) {
            let user = res.data;
            update(user);
          }
        });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const formCols: FormCols<FormValuesProps>[] = useMemo(
    () => [
      {
        elements: [
          [
            {
              name: "firstName",
              label: "Prénom",
              type: "text",
            },
            {
              name: "lastName",
              label: "Nom",
              type: "text",
            },
            {
              name: "email",
              label: "Email",
              type: "text",
            },
          ],
        ],
      },
    ],
    []
  );

  const defaultValues = useMemo(
    () => ({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
    }),
    [user]
  );

  let accessInfo: any;
  if (user?.authorities == null || user?.authorities.length == 0) {
    accessInfo = "Vous n'avez aucun rôle";
  } else {
    let roleName = nameForAuthority(user.authorities[0] as Role).toLowerCase();
    accessInfo = "Votre rôle est : " + capitalize(roleName);
  }

  return (
    <Page title="Paramètre du compte" withContainer>
      <Box sx={{ p: 1, pb: 4 }}>{accessInfo}</Box>

      <Form<FormValuesProps, Yup.AnyObjectSchema>
        schema={SettingsSchema}
        onSubmit={onSubmit}
        formCols={formCols}
        submitLabel="Modifier"
        submitButtonProps={{ fullWidth: true, size: "large" }}
        defaultValues={defaultValues}
      />
    </Page>
  );
}
