import {
  Box,
  Button,
  Icon,
  Stack, Typography
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { Prestation } from "../../@types/prestation";
import { ProductEntry, getProductOptionLabel, PRODUCT_SUB_REF_API, ProductLight } from "../../@types/product";
import RHFAutocomplete from "../../components/hook-form/RHFAutocomplete";
import StockProductList from "./StockProductList";
import ICONS from "../../assets/icons";
import { useMemo } from "react";
import axiosInstance from "../../utils/axios";
import { enqueueSnackbar } from "notistack";

interface ProductPrestationDetailsProps {
  products: ProductEntry[];
  methods: UseFormReturn<Prestation, any>;
}

// ----------------------------------------------------------------------
export default function ProductPrestationDetails({
  products,
  methods,
}: ProductPrestationDetailsProps) {
  const { control, watch } = methods;

  const site = watch("site");
  const prestType = watch("type");
  const productsWatch = watch("products");

  const btnCreateNewProduct = useMemo(() => {
    if (site && productsWatch?.length == 1) {

      const createNewProduct = async () => {
        if (productsWatch) {
          if (!productsWatch[0].product) {
            let msg = "Veuillez déjà saisir le produit d'entrée"
            console.log(msg);
            enqueueSnackbar(msg, { variant: "error" })
            return
          }

          if (!site) {
            let msg = "Le site doit être défini avant de créer un produit"
            console.log(msg);
            enqueueSnackbar(msg, { variant: "error" })
            return
          }

          let productIn = productsWatch[0].product;
          let refIn = productIn.reference;
          let refToCreate = window.prompt("Quelle référence produit voulez vous créer ? ", refIn)
          if (refIn === refToCreate) {
            let msg = "La référence saisie est la même que la référence de produit d'entrée"
            console.log(msg);
            enqueueSnackbar(msg, { variant: "error" })
            return
          }

          let productOuts: any[] = products.filter((p) => p.reference === refToCreate);
          let productOut: ProductLight | undefined;

          if (productOuts.length > 0) {
            productOut = productOuts[0] as ProductLight;
            let msg = "La référence produit " + refToCreate + " existe déjà"
            console.log(msg);
            enqueueSnackbar(msg, { variant: "error" })

          } else {
            let body = { ProductId: productIn.id, newReference: refToCreate, siteId: site?.id }
            let response = await axiosInstance.post(PRODUCT_SUB_REF_API, body)
            if (response.status != 201) {
              return;
            }
            productOut = response.data
          }

          if (productOut) {
            let allValues = methods.getValues()
            let dest: ProductLight[] | undefined = allValues["productsDestination"]
            if (!dest) {
              dest = []
            }
            dest.push(productOut)
            methods.setValue("productsDestination", dest);
          }

        }
      };

      return <Box>
        <Button
          startIcon={<Icon>{ICONS.add}</Icon>}
          onClick={createNewProduct}
          sx={{ flexShrink: 0 }}
        >
          Créer nouveau produit
        </Button>
      </Box>
    }

    return null

  }, [productsWatch, site])


  return (
    <Box>
      <Stack
        spacing={2}
      >
        <Typography variant="subtitle2">Produits d'entrée</Typography>

        <StockProductList methods={methods} control={control} products={products} fieldName="products" />

        {prestType !== "" && prestType?.isTri &&
          <>
            <Typography variant="subtitle2">Produits de sortie</Typography>
            <RHFAutocomplete
              name="productsDestination"
              label="Produits"
              options={products}
              getOptionLabel={getProductOptionLabel}
              multiple
              isOptionEqualToValue={(opt, val) => val.id === opt.id}
              disableCloseOnSelect
              checkboxes
            />
            {btnCreateNewProduct}
          </>
        }
      </Stack>
    </Box>
  );
}
