import { Media, UploadPhoto } from "./media";
import { PrestationLight } from "./prestation";
import { ProductLight } from "./product";

export const CLIENT_API = "clients";

export interface ClientLight {
  id?: number | "";
  name?: string;
  reference?: string;
}

export interface Client extends ClientLight {
  upload_photo?: UploadPhoto | string;
  photo?: Media;
  services?: PrestationLight[];
  products?: ProductLight[];
}