import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      component="img"
      src="/logo.png"
      sx={{ height: 50, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  let infoDev: any
  if (process.env.NODE_ENV === 'development') {
    infoDev = <Typography sx={{ ml: 2, color: 'red', fontWeight: 'bold' }}>(Environnement de développement)</Typography>
  } else {
    if (process.env.REACT_APP_MODE) {
      infoDev = <Typography sx={{ ml: 2, color: 'red', fontWeight: 'bold' }}>(Environnement : {process.env.REACT_APP_MODE})</Typography>
    }
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents', color: 'white' }}>
      {logo}
      <Typography variant="h6" sx={{ ml: 2 }}>CO.PA.Stock</Typography>
      {infoDev}
    </Link>
  );
}
);

export default Logo;
