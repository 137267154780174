// assets
import ForbiddenIllustration from "../assets/ForbiddenIllustration";
import ErrorPage from "../layouts/ErrorPage";

// ----------------------------------------------------------------------
export default function Page403() {
  return (
    <ErrorPage title="Autorisation refusée" text="Vous n'avez pas le droit d'accéder à cette page" illustration={<ForbiddenIllustration />} />
  );
}
