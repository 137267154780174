import axios from "axios";
// config
import { HOST_BO_API } from "../config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_BO_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)

    if (error.response && error.response.data && error.response.status === 401) {
      return error.response;
    }


    return Promise.reject(
      (error.response && error.response.data) || "Une erreur est survenue"
    )
  }
);

export default axiosInstance;
