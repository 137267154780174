import { Icon, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ICONS from "../../../assets/icons";
import Page from "../../../layouts/Page";
import { PATH_HOME, PATH_ADMIN } from "../../../routes/paths";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../utils/axios";
import { Maintenance, MAINTENANCE_API } from "../../../@types/maintenance";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MaintenanceInformations from "../../../sections/maintenance/MaintenanceInformations";
import MaintenanceEventList from "../../../sections/maintenance/MaintenanceEventList";

// ----------------------------------------------------------------------
export default function MaintenancePage() {
  const params = useParams();
  const [value, setValue] = useState("0");
  const [maintenance, setMaintenance] = useState<Maintenance | undefined>(undefined);


  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(MAINTENANCE_API + "/" + params.id)
        .then((response: any) => setMaintenance(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const title = params.id ? "Maintenance : " + maintenance?.object : "Nouvelle maintenance";

  return (
    <Page
      title={title}
      withContainer={!params.id}
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Liste des maintenances", href: PATH_ADMIN.maintenance.root },
          { name: "Maintenances" },
        ],
      }}
    >
      {params.id ? (
        <TabContext value={value}>
          <TabList centered onChange={handleChange} >
            <Tab label="Informations" value="0" sx={{ flex: 0.2 }} />
            <Tab label="Liste des opérations" value="1" sx={{ flex: 0.2 }} />
          </TabList>
          <TabPanel value="0">
            <MaintenanceInformations maintenance={maintenance} setMaintenance={setMaintenance} />
          </TabPanel>
          <TabPanel value="1">
            <MaintenanceEventList maintenanceId={maintenance?.id ?? 0} />
          </TabPanel>
        </TabContext>
      ) : (
        <MaintenanceInformations isNew maintenance={maintenance} setMaintenance={setMaintenance} />
      )}
    </Page>
  );
}
