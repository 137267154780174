import { SiteLight } from "./site";

export const STOCK_API = "stocks";

// export interface Stock {
//   initial_quantity: number;
//   current_quantity: number;
//   site: SiteLight;
// }

export interface StockEntry {
  product_id: number;
  product_reference: string;
  product_type: string;
  client_name: string;
  site_id: number;
  site_name: string;
  stock_initial: number;
  stock_final: number;
  operations_in: number;
  operations_out: number;
  switchs_in: number;
  switchs_out: number;
}