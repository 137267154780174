import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Icon, Stack, Tab } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  PRESTATION_API
} from "../../@types/prestation";
import ICONS from "../../assets/icons";
import Page from "../../layouts/Page";
import { PATH_HOME, PATH_PRESTATION } from "../../routes/paths";
import PrestationInformations from "../../sections/prestation/PrestationInformations";
import axiosInstance from "../../utils/axios";
import AvancementsPrestationList from "../../sections/prestation/AvancementsPrestationList";
import { usePrestationContext } from "../../contexts/PrestationContext";

export default function PrestationPage() {
  const params = useParams();
  const [value, setValue] = useState("0");
  const { prestation, setPrestation } = usePrestationContext()

  useEffect(() => {
    //pour récupérer les données
    console.log("PrestationPage - useEffect")
    if (params.id !== undefined) {
      axiosInstance
        .get(PRESTATION_API + "/" + params.id)
        .then((response: any) => {
          console.log("PrestationPage - useEffect - response.data : ", response.data)
          setPrestation(response.data)
        });
    } else {
      setPrestation(undefined)
    }
  }, [params.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const title = params.id
    ? "Prestation " + prestation?.reference
    : "Nouvelle prestation";

  return <Page
    title={title}
    HeaderProps={{
      links: [
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des prestations", href: PATH_PRESTATION.root },
        { name: "Prestation" },
      ],
    }}
  >

    {params.id ? (
      <TabContext value={value}>
        <Stack flex="1" >
          <TabList centered onChange={handleChange} style={{ border: 'solid 0px black' }}  >
            <Tab label="Informations" value="0" sx={{ flex: 0.1 }} />
            <Tab label="Avancements" value="1" sx={{ flex: 0.1 }} />
          </TabList>
        </Stack>
        <TabPanel value="0">
          <PrestationInformations />
        </TabPanel>
        <TabPanel value="1">
          <AvancementsPrestationList />
        </TabPanel>
      </TabContext>
    ) : (
      <PrestationInformations isNew />
    )}
  </Page>

}
