import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { Client, CLIENT_API } from "../../@types/client";
import { Role } from "../../@types/user";
import ICONS from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import ListPage from "../../layouts/ListPage";
import { PATH_CLIENT, PATH_HOME } from "../../routes/paths";

// ----------------------------------------------------------------------
export default function ClientListPage() {
  const { user } = useAuth();

  const columns: GridColDef<Client>[] = useMemo(
    () => [
      {
        field: "reference",
        headerName: "Référence",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_CLIENT.root + "/" + params.row.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "name",
        headerName: "Nom",
        editable: false,
        flex: 1,
      },
      {
        field: "services_number",
        headerName: "Nombre de prestations",
        editable: false,
        flex: 1,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des clients"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des clients" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_CLIENT.new}
      service={CLIENT_API}
      getListMethod="list"
      canCreate={user?.authorities?.some(a => a === Role.ROLE_MANAGER)}
    />
  );
}

function searchFunc(client: Client, filterValue: string) {
  filterValue = filterValue.toLowerCase();
  return (
    client.reference?.toLowerCase().indexOf(filterValue) !== -1 ||
    client.name?.toLowerCase().indexOf(filterValue) !== -1
  );
}
