import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Page from "../../layouts/Page";

import Container from "@mui/material/Container";
import axios from "axios";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Form, { FormCols } from "../../components/hook-form/Form";
import { HOST_API } from "../../config";
import axiosInstance from "../../utils/axios";

interface FormValuesProps {
  username: string;
}

// ----------------------------------------------------------------------
export default function NewPasswordPage() {
  const navigate = useNavigate();

  const NewPasswordSchema = Yup.object().shape({
    username: Yup.string().email("Email invalid").required("Email obligatoire"),
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post(HOST_API + "/api/account/reset_password/init", {
        Username: data.username,
      });
    } catch (error) {
      console.error("Erreur : ", error);
      throw error;
    }
  };

  const formCols: FormCols<FormValuesProps>[] = useMemo(
    () => [
      {
        elements: [[{ name: "username", label: "Votre email" }]],
      },
    ],
    []
  );

  return (
    <Page title="Mot de passe oublié" withContainer noHeader>
      <Box component="img" src="/logo_big.jpg" sx={{ pb: 4 }} />

      <Typography variant="h4" sx={{ pb: 2 }}>
        Récupération du mot de passe
      </Typography>

      <Form<FormValuesProps, Yup.AnyObjectSchema>
        schema={NewPasswordSchema}
        onSubmit={onSubmit}
        formCols={formCols}
        submitLabel="Envoyer"
        submitButtonProps={{ fullWidth: true, size: "large" }}
        onCancel={() => navigate("/")}
      />
    </Page>
  );
}
