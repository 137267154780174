import { Outlet } from "react-router-dom";
// @mui
import { Box, Stack } from "@mui/material";
//
import { grey } from "@mui/material/colors";
import Header from "./Header";

export default function MainLayout() {
  return (
    <Stack sx={{ height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: 5, md: 8 },
          backgroundColor: grey[200],
        }}
      >
        <Box sx={{ p: 2, m: 2, backgroundColor: "white", borderRadius: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
}
