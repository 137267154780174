import { IconButton, InputAdornment, Typography } from "@mui/material";
import Icon from "@mui/material/Icon";
import { Box } from "@mui/system";
import axios from "axios";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import ICONS from "../../assets/icons";
import Form, { FormCols } from "../../components/hook-form/Form";
import { HOST_API } from "../../config";
import Page from "../../layouts/Page";

interface FormValuesProps {
  newPassword: string;
  confirmPassword: string;
}

// ----------------------------------------------------------------------
export default function ResetPasswordPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required("Nouveau mot de passe obligatoire"),
    confirmPassword: Yup.string().required(
      "Confirmation mot de passe obligatoire"
    ),
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axios
        .post(HOST_API + "/api/account/reset_password/finish", {
          NewPassword: data.newPassword,
          Key: params.key,
        })
        .then((data: any) => {
          if (data.data) {
            navigate("/");
          }
        });
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const formCols: FormCols<FormValuesProps>[] = useMemo(
    () => [
      {
        elements: [
          [
            {
              name: "newPassword",
              label: "Nouveau mot de passe",
              type: showNewPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        <Icon>
                          {showNewPassword ? ICONS.eyeOff : ICONS.eye}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
            {
              name: "confirmPassword",
              label: "Confirmation du nouveau mot de passe",
              type: showConfirmPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        <Icon>
                          {showConfirmPassword ? ICONS.eyeOff : ICONS.eye}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
          ],
        ],
      },
    ],
    [showNewPassword, showConfirmPassword]
  );

  return (
    <Page title="Réinitialisation du mot de passe" withContainer noHeader>
      <Box component="img" src="/logo_big.jpg" sx={{ pb: 4 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" sx={{ pb: 2 }}>
          Réinitialisation du mot de passe
        </Typography>

        <Form<FormValuesProps, Yup.AnyObjectSchema>
          schema={ResetPasswordSchema}
          onSubmit={onSubmit}
          formCols={formCols}
          submitLabel="Modifier"
          submitButtonProps={{ fullWidth: true, size: "large" }}
          onCancel={() => navigate("/")}
        />
      </Box>
    </Page>
  );
}
