import {
  Box,
  Button,
  Icon,
  IconButton, Stack
} from "@mui/material";
import { ArrayPath, Control, FieldValues, UseFormReturn, useFieldArray } from "react-hook-form";
import { ProductEntry, ProductStock, getProductOptionLabel } from "../../@types/product";
import ICONS from "../../assets/icons";
import { RHFTextField } from "../../components/hook-form";
import RHFAutocomplete from "../../components/hook-form/RHFAutocomplete";
import { useState } from "react";
import EditQtyProduct from "./EditQtyProduct";
import { usePrestationContext } from "../../contexts/PrestationContext";

export const DEFAULT_PRODUCT_STOCK: ProductStock = {
  id: -1,
  product: "",
  quantity: "",
};

interface Props<T extends FieldValues> {
  products: ProductEntry[];
  control: Control<T, any>;
  methods: UseFormReturn<T, any>;
  fieldName: ArrayPath<T>;
}

// ----------------------------------------------------------------------
export default function StockProductList<T extends FieldValues>({
  products,
  control,
  methods,
  fieldName,
}: Props<T>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
    keyName: "ufaId"
  });
  // const [showEditQty, setShowEditQty] = useState(false)
  const [editQty, setEditQty] = useState(-1)

  const handleAdd = () => {
    let newProdStock = { ...DEFAULT_PRODUCT_STOCK, id: (Math.min(...fields.filter((f: any) => !f.id || f.id < 0).map((f: any) => f.id ?? 0)) ?? 0) - 1 }
    append(newProdStock as any);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const { prestation } = usePrestationContext()

  return (
    <Stack
      spacing={1}
    >
      {fields.map((item: any, index) => (<Stack key={item.id}
        direction={{ xs: "column", md: "row" }}
        alignItems="flex-start"
        spacing={1}
      >
        <RHFAutocomplete
          name={fieldName + `[${index}].product`}
          label="Produit"
          sx={{ flex: 3 }}
          options={products}
          getOptionLabel={getProductOptionLabel}
          isOptionEqualToValue={(opt, val) => val.id === opt.id}
        />

        <RHFTextField
          type="number"
          name={fieldName + `[${index}].quantity`}
          label="Qté"
          placeholder="0"
          sx={{ flex: 1 }}
          disabled={(prestation) ? true : false}
        />
        <EditQtyProduct data={methods.getValues().products[index]} products={products} />


        {fields.length > 1 &&
          <Box sx={{ height: 56, display: "flex", alignItems: "center" }}>
            <IconButton
              color="error"
              onClick={() => handleRemove(index)}
            ><Icon>{ICONS.close}</Icon></IconButton>
          </Box>
        }
      </Stack>)
      )}

      <Stack flexDirection="row" >
        <Box style={{ flex: 1 }}>
          <Button
            startIcon={<Icon>{ICONS.add}</Icon>}
            onClick={handleAdd}
            sx={{ flexShrink: 0 }}
          // 
          >
            Ajouter
          </Button>
        </Box>
        <Box style={{ flex: 1 }}>
          {/* {editBtn} */}
        </Box>
      </Stack>


    </Stack >
  );
}
