import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
// @mui
import {
  Box,
  Button,
  ButtonProps,
  Card,
  Grid,
  GridSize,
  Icon,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// form
import {
  DeepPartial,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FormProvider,
  RHFDatePicker,
  RHFRadioGroup,
  RHFSelectWithNoChildren,
  RHFSwitch,
  RHFTextField,
  RHFUpload,
} from ".";
// import { FormProvider, RHFSwitch, RHFTextField, RHFUploadSingleFile, RHFUploadMultiFile, RHFDatePicker, RHFSelectWithNoChildren } from '.';

import { useSnackbar } from "notistack";
import * as Yup from "yup";
import Lazy from "yup/lib/Lazy";
import ICONS from "../../assets/icons";
import { NEW_TERM } from "../../routes/paths";
import palette from "../../theme/palette";
import axiosInstance from "../../utils/axios";
import { toDataURL } from "../../utils/getFileData";
import DeleteconfirmDialog from "../confirm-dialog/DeleteConfirmDialog";
import RHFAutocomplete from "./RHFAutocomplete";
import RHFTimePicker from "./RHFTimePicker";
import { Media } from "../../@types/media";
import { fileData } from "../file-thumbnail/utils";
import Logs from "../Logs";

export const SERVICE_DISPLAY_NAME = {
  products: "Produit",
  services: "Prestation",
  operations: "Opération",
  clients: "Client",
  users: "Utilisateur",
  sites: "Site",
  descriptions: "Description",
  contenances: "Contenance",
  categoryNCs: "Catégorie NC",
  serviceTypes: "Type de prestation",
  avancementPrestations: "Avancement de prestation",
  nonCompliances: "Non conformité",
  suppliers: "Prestataire",
  productTypes: "Type de produit",
  maintenances: "Maintenance",
};

interface Field<T> {
  name: Path<T>;
  label?: string | React.ReactNode;
  xs?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  type?:
  | "text"
  | "file"
  | "files"
  | "date"
  | "time"
  | "switch"
  | "number"
  | "link"
  | "select"
  | "password"
  | "radio"
  | "autocomplete"
  | "submit";
  disabled?: boolean;
  options?: any; //{ label?: string | undefined, value: any }[];
  selectOptions?: any[];
  mode?: "object" | "brut" | "id";
  accept?: string;
  required?: boolean;
}

export type FieldList<T> = (Field<T> | React.ReactNode)[];

export interface FormCols<T> {
  elements?: (FieldList<T> | React.ReactNode)[];
  xs?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

export interface FormProps<
  T extends FieldValues,
  U extends Yup.AnyObjectSchema | Lazy<any>
> {
  schema?: U;
  defaultValues?: DeepPartial<T>;
  obj?: T;
  isEdit?: boolean;
  onCancel?: Function;
  formCols: FormCols<T>[];
  submitLabel?: string;
  submitButtonProps?: Partial<ButtonProps>;
  readonly?: boolean;
  isNew?: boolean;
  canDelete?: boolean;
  service?: string;
  setObj?: (obj: T) => void;
  onSubmit?: SubmitHandler<T>;
  withPaper?: boolean;
  transformDataBeforeSubmit?: Function;
  routeAfterSubmit?: string;
  otherButton?: Action
}
export default function Form<
  T extends FieldValues,
  U extends Yup.AnyObjectSchema | Lazy<any>
>(props: FormProps<T, U>) {
  const { schema, defaultValues } = props;

  const methods = useForm<T>({
    resolver: yupResolver(schema as U),
    defaultValues,
  });

  return <FormWithMethods<T, U> {...props} methods={methods} />;
}

export function FormWithMethods<
  T extends FieldValues,
  U extends Yup.AnyObjectSchema | Lazy<any>
>(props: FormProps<T, U> & { methods: UseFormReturn<T, U> }) {
  const {
    defaultValues,
    obj,
    isEdit,
    canDelete,
    formCols,
    submitLabel,
    submitButtonProps,
    onCancel,
    readonly,
    isNew,
    service,
    setObj,
    onSubmit,
    methods,
    withPaper,
    transformDataBeforeSubmit,
    routeAfterSubmit,
    otherButton,
  } = props;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const withSubmitPosition = useMemo(
    () =>
      formCols
        .map((c) => c.elements?.flat())
        .flat()
        .filter(
          (c) => c != null && typeof c === "object" && c.hasOwnProperty("type")
        )
        .find((c) => (c as Field<any>).type === "submit"),
    [formCols]
  );
  const {
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && obj) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, obj]);

  const preHandleSubmit: SubmitHandler<T> = async (data, event) => {
    try {
      await (onSubmit !== undefined
        ? onSubmit(data, event)
        : defaultOnSubmit(data, event));
      // setErrorAfterSubmit("");
      if (service && service in SERVICE_DISPLAY_NAME)
        enqueueSnackbar(
          `${SERVICE_DISPLAY_NAME[service as keyof typeof SERVICE_DISPLAY_NAME]
          } ${isNew ? "créé(e)" : "modifié(e)"}`,
          {
            variant: "success",
          }
        );
    } catch (error: any) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        typeof error.response.data === "string"
      ) {
        enqueueSnackbar(`Une erreur est survenue.\n(${error.response.data})`, {
          variant: "error",
        });
        // setErrorAfterSubmit(error.response.data);
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        typeof error.response.data === "string") {
        enqueueSnackbar(error.response.data, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          `Une erreur est survenue${service && service in SERVICE_DISPLAY_NAME
            ? ` lors de la ${isNew ? "création" : "modification"}`
            : ""
          }.`,
          {
            variant: "error",
          }
        );
        // setErrorAfterSubmit(typeof error === "string" ? error : error.message);
      }
    }
  };

  const defaultOnSubmit: SubmitHandler<T> = async (data: T) => {
    let newData: T & { photo?: string } = {
      ...data,
      // photo: data.photo?.url ?? undefined,
    };
    console.log("defaultOnSubmit - newData : ", newData)
    try {
      if ("upload_photos" in newData) {

        if (newData.upload_photos != null) {
          if (newData.upload_photos.length === 0) {
            // @ts-ignore
            newData["upload_photos"] = undefined;
          } else {
            let tmp: any[] = []
            await Promise.all(newData["upload_photos"].map(async (file: File | Media) => {
              if (typeof file === "string") {
                return
              }

              if (file.hasOwnProperty("url")) {
                file = file as Media
                return
              }

              file = file as File

              tmp.push({
                data: (await toDataURL(file)).split(",")[1],
                mimeType: file.type,
                name: file.name
              });
            }));


            if (tmp.length == 0) {
              // @ts-ignore
              newData["upload_photos"] = undefined

            } else {
              // @ts-ignore
              newData["upload_photos"] = tmp
            }
          }
        }
      }

      if ("upload_photo" in newData && newData.upload_photo != null) {
        if (typeof newData.upload_photo === "string") {
          // @ts-ignore
          newData["upload_photo"] = undefined;
        } else {
          // @ts-ignore
          newData["upload_photo"] = {
            data: (await toDataURL(newData["upload_photo"])).split(",")[1],
            mimeType: newData["upload_photo"].type,
            name: newData["upload_photo"].name,
          };
        }
      }

      if (transformDataBeforeSubmit && newData !== undefined) {
        newData = transformDataBeforeSubmit(newData);
      }

      if (!isNew) {
        const response = await axiosInstance.put(service ?? "", newData);
        setObj && setObj(response.data);
      } else {
        const response = await axiosInstance.post(service ?? "", newData);

        if (routeAfterSubmit) {
          navigate(routeAfterSubmit);
        } else {
          const newpath = location.pathname.substring(0, location.pathname.indexOf(NEW_TERM)) + response.data.id as string
          navigate(newpath, { replace: true });
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onDelete = (r: Promise<any>) => {
    if (defaultValues?.id === undefined) return;
    // call delete service
    r.then(() => {
      navigate(-1);
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(preHandleSubmit)}>
      {/* Delete button on top right absolute to delete if isEdit and not isNew */}
      {(canDelete !== undefined ? canDelete : isEdit) && (
        <>
          <DeleteconfirmDialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog((old) => !old)}
            callback={onDelete}
            name={
              SERVICE_DISPLAY_NAME[
              service as keyof typeof SERVICE_DISPLAY_NAME
              ] +
              " " +
              // client / site
              (defaultValues?.name ||
                // product
                defaultValues?.reference ||
                // description
                defaultValues?.libelle ||
                // non-compliance
                defaultValues?.number ||
                // user
                [defaultValues?.firstName, defaultValues?.lastName]
                  .join(" ")
                  .trim() ||
                // operation
                [
                  defaultValues?.type === 0
                    ? "Entrée de"
                    : defaultValues?.type === 1
                      ? "Sortie de"
                      : undefined,
                  defaultValues?.quantity,
                  defaultValues?.product?.reference,
                ]
                  .join(" ")
                  .trim()) || ""
            }
            service={`${service}/${defaultValues?.id}`}
          />
          <Button
            variant="contained"
            startIcon={<Icon>{ICONS.delete}</Icon>}
            color="error"
            onClick={() => setShowDeleteDialog((old) => !old)}
            sx={{ position: "absolute", right: 40, top: 26 }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Supprimer</Typography>
          </Button>
        </>
      )}
      <FormContent
        setValue={setValue}
        getValues={getValues}
        formCols={formCols}
        readonly={readonly}
        withPaper={withPaper}
        submitButtonProps={submitButtonProps}
        submitLabel={submitLabel}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isEdit={isEdit}
        obj={obj}
      />

      {!readonly && !withSubmitPosition && (
        <ActionBar
          isSubmitting={isSubmitting}
          submitLabel={submitLabel}
          submitButtonProps={submitButtonProps}
          onCancel={onCancel}
          isEdit={isEdit}
          mt={2}
          otherButton={otherButton}
        />
      )}
      {/* {errorAfterSubmit && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {errorAfterSubmit}
        </Alert>
      )} */}
    </FormProvider>
  );
}

interface ContentProps<T> {
  formCols: FormCols<T>[];
  setValue: Function;
  getValues: Function;
  readonly?: boolean;
  withPaper?: boolean;
  isEdit?: boolean;
  onCancel?: Function;
  isSubmitting?: boolean;
  submitLabel?: string;
  submitButtonProps?: ButtonProps;
  obj: any
}

export function FormContent<T>({
  setValue,
  getValues,
  formCols,
  readonly,
  withPaper,
  isEdit,
  onCancel,
  isSubmitting,
  submitLabel,
  submitButtonProps,
  obj,
}: ContentProps<T>) {

  const handleDrop = useCallback(
    (name: Path<T>) => (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(name, file);
      }
    },
    [setValue]
  );

  const handleDropMulti = useCallback(
    (name: Path<T>) => (acceptedFiles: any[]) => {
      if (acceptedFiles.length > 0) {
        let oldValues = getValues(name)
        if (oldValues) {
          setValue(name, [...oldValues, ...acceptedFiles]);
        } else {
          setValue(name, [...acceptedFiles]);
        }
      }
    },
    [getValues, setValue]
  );

  const handleRemoveAllMulti = useCallback(
    (name: Path<T>) => {
      setValue(name, []);
      if (name === "upload_photos") {
        setValue("photos", []);
      }
    },
    [setValue]
  );

  const handleDelete = useCallback(
    (name: Path<T>) => {
      setValue(name, null);
      if (name === "upload_photo" || name === "photo") {
        setValue("photo", null);
      }
    },
    [setValue]
  );

  const handleRemoveOneMulti = useCallback(
    (name: Path<T>, file: File | string | Media) => {
      setValue(
        name,
        (getValues(name) as File[]).filter((f) => f !== file)
      );

      let tmp = fileData(file)

      if (name === "upload_photos") {
        let oldValues: Media[] = getValues("photos")
        if (oldValues) {
          setValue("photos", oldValues.filter((f) => f.originalFileName !== tmp.name))
        } else {
          setValue("photos", [])
        }
      }
    },
    [getValues, setValue]
  );

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      {formCols.map((fc, index) => (
        <Grid item xs={fc.xs ?? 12} md={fc.md} lg={fc.lg} key={index}>
          {fc.elements && (
            <Stack spacing={3}>
              {fc?.elements?.map((elt, index2) => {
                if (!elt || !Array.isArray(elt)) return elt;

                const fieldList = elt as FieldList<T>;
                const content = (
                  <Grid container spacing={2}>
                    {fieldList?.map((f, index3) => {
                      if (!f || !f.hasOwnProperty("name"))
                        return (
                          <Grid item xs={12} key={index3}>
                            {f as ReactNode}
                          </Grid>
                        );

                      const ff = f as Field<T>;
                      let type = ff.type;
                      const {
                        xs,
                        md,
                        lg,
                        disabled,
                        options,
                        accept,
                        selectOptions,
                        required = true,
                        ...other
                      } = ff;

                      // other.label = required ? other.label + " *" : other.label;
                      other.label = !required ? (
                        <span>
                          {other.label}
                          <Typography
                            variant="body1"
                            component="span"
                            color="grey.50065"
                            sx={{ fontSize: "inherit" }}
                          >
                            {" "}
                            (optionnel)
                          </Typography>
                        </span>
                      ) : (
                        other.label
                      );

                      if (type === "autocomplete" && !selectOptions) {
                        type = "text";
                      }

                      return (
                        <Grid item xs={xs ?? 12} md={md} lg={lg} key={index3}>
                          {type === "date" && (
                            <RHFDatePicker
                              {...other}
                              {...options}
                              disabled={disabled}
                            />
                          )}
                          {type === "time" && (
                            <RHFTimePicker
                              {...other}
                              {...options}
                              disabled={disabled}
                            />
                          )}
                          {type === "radio" && (
                            <RHFRadioGroup
                              {...other}
                              // {...options}
                              // disabled={disabled}
                              options={options}
                            />
                          )}
                          {type === "autocomplete" && (
                            <RHFAutocomplete
                              {...other}
                              {...options}
                              disabled={disabled}
                              options={selectOptions}
                            />
                          )}
                          {type === "number" && (
                            <RHFTextField
                              {...other}
                              {...options}
                              type="number"
                              step="any"
                              disabled={disabled}
                            />
                          )}
                          {type === "switch" && (
                            <RHFSwitch
                              {...other}
                              {...options}
                              label={ff.label as string}
                              disabled={disabled}
                            />
                          )}
                          {type === "select" && (
                            <RHFSelectWithNoChildren
                              {...other}
                              {...options}
                              disabled={disabled}
                              SelectProps={{
                                ...options?.SelectProps,
                                renderValue: (selected: any) => {
                                  return selected instanceof Array
                                    ? selected
                                      .map((option) =>
                                        option instanceof Object
                                          ? option?.nom
                                          : selectOptions?.find(
                                            (so) => so.id === option
                                          )?.nom
                                      )
                                      .join(", ")
                                    : selected instanceof Object
                                      ? selected?.nom ??
                                      selectOptions?.find(
                                        (so) => so.id === selected.id
                                      )?.nom
                                      : selectOptions?.find(
                                        (so) => so.id === selected
                                      )?.nom
                                },
                              }}
                              selectOptions={selectOptions}
                            />
                          )}
                          {type === "link" && (
                            <Grid container>
                              <Grid item xs={8}>
                                <Typography
                                  variant="h4"
                                  noWrap
                                  style={{ color: palette.dark.primary.main }}
                                >
                                  {ff.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ textAlign: "right" }}>
                                <Link to="" color="inherit">
                                  Cliquez ici
                                </Link>
                              </Grid>
                            </Grid>
                          )}
                          {type === "file" && (
                            <RHFUpload
                              {...other}
                              {...options}
                              disabled={readonly}
                              accept={accept}
                              onDrop={handleDrop(ff.name)}
                              onDelete={() => handleDelete(ff.name)}
                            />
                          )}
                          {type === "files" && (
                            <RHFUpload
                              multiple
                              {...other}
                              {...options}
                              disabled={readonly}
                              accept={accept}
                              onDrop={handleDropMulti(ff.name)}
                              onRemove={(file: File | string) =>
                                handleRemoveOneMulti(ff.name, file)
                              }
                              onRemoveAll={() => handleRemoveAllMulti(ff.name)}
                            />
                          )}
                          {type === "password" && (
                            <RHFTextField
                              type="password"
                              {...other}
                              {...options}
                              disabled={disabled}
                            />
                          )}
                          {(type === "text" || !type) && (
                            <RHFTextField
                              {...other}
                              {...options}
                              disabled={disabled}
                            />
                          )}
                          {type === "submit" && (
                            <ActionBar
                              isSubmitting={isSubmitting}
                              submitLabel={submitLabel}
                              submitButtonProps={submitButtonProps}
                              onCancel={onCancel}
                              isEdit={isEdit}
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                );

                return withPaper &&
                  !elt?.some((e) => (e as Field<T>)?.type === "submit") ? (
                  <Card sx={{ p: 3 }} key={index2}>
                    {content}
                  </Card>
                ) : (
                  <React.Fragment key={index2}>{content}</React.Fragment>
                );
              })}
            </Stack>
          )}
        </Grid>
      ))}

      {obj && obj.logs && obj.logs.length > 0 && <Card sx={{ p: 3, my: 2, marginLeft: 3, flex: 1 }} ><Logs logs={obj?.logs} /></Card>}
    </Grid>
  );
}

interface Action {
  name: string;
  fct: Function;
  icon?: string;
}

interface ActionBarProps {
  onCancel?: Function;
  isSubmitting?: boolean;
  submitLabel?: string;
  submitButtonProps?: ButtonProps;
  isEdit?: boolean;
  otherButton?: Action;
}
function ActionBar({
  onCancel,
  isSubmitting,
  submitLabel,
  submitButtonProps,
  isEdit,
  otherButton,
  ...other
}: ActionBarProps & StackProps) {
  return (
    <Stack
      mt={-1}
      {...other}
      direction="row"
      justifyContent="flex-end"
      mr={2}
      gap={2}
    >
      {onCancel && (
        <Button color="inherit" variant="outlined" onClick={() => onCancel()}>
          Annuler
        </Button>
      )}
      {otherButton && (
        <Button color="inherit" variant="outlined" onClick={() => otherButton.fct()} startIcon={(otherButton.icon) ? <Icon>{otherButton.icon}</Icon> : null}>
          {otherButton.name}
        </Button>
      )}
      <LoadingButton
        sx={{ width: "auto" }}
        type="submit"
        variant="contained"
        loading={isSubmitting}
        startIcon={!submitLabel && <Icon>{ICONS.save}</Icon>}
        {...submitButtonProps}
      >
        {submitLabel != null ? submitLabel : !isEdit ? "Créer" : "Enregistrer"}
      </LoadingButton>
    </Stack>
  );
}
