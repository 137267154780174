
import { SiteLight } from "./site";
import { Supplier } from "./supplier";

export const MAINTENANCE_API = "maintenances";


export enum PERIODICITY_TYPE {
  ANNUAL = 1,
  MONTHLY = 2,
  OTHER = 9,
}

export const PERIODICITY_TYPE_MAP = {
  [PERIODICITY_TYPE.ANNUAL]: "Annuel",
  [PERIODICITY_TYPE.MONTHLY]: "Mensuel",
  [PERIODICITY_TYPE.OTHER]: "Autre",
};

export enum MAINTENANCE_TYPE {
  PERIODIC_VERIFICATION = 1,
  PREVENTIVE_MAINTENANCE = 2,
}

export const MAINTENANCE_TYPE_LABELS = {
  [MAINTENANCE_TYPE.PERIODIC_VERIFICATION]: "Vérification Périodique",
  [MAINTENANCE_TYPE.PREVENTIVE_MAINTENANCE]: "Maintenance Préventive",
};

export const MAINTENANCE_TYPE_CODES = {
  [MAINTENANCE_TYPE.PERIODIC_VERIFICATION]: "VP",
  [MAINTENANCE_TYPE.PREVENTIVE_MAINTENANCE]: "MP",
};


export interface Maintenance {
  id: number | "";
  object: string;
  comment: string;
  sites: SiteLight[] | "";
  supplier: Supplier | "";
  maintenanceType: number | string;
  periodicityType: number | string;
  periodicityComment?: string;
  frequency: number | "";
}

export function GetLibPeriodicity(maintenance: Maintenance) {
  switch (maintenance.periodicityType) {
    case PERIODICITY_TYPE.ANNUAL:
      return (maintenance.frequency + " / an") ?? "Annuel";

    case PERIODICITY_TYPE.MONTHLY:
      return (maintenance.frequency + " / mois") ?? "Mensuel";

    case PERIODICITY_TYPE.OTHER:
      return maintenance.periodicityComment;
  }
  return ""
}