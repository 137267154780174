import { Chip, Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { nameForAuthority, Role, User, USER_API } from "../../../@types/user";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";

// ----------------------------------------------------------------------
export default function UserListPage() {
  const columns: GridColDef<User>[] = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Nom",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link
            sx={{ fontWeight: "bold" }}
            href={PATH_ADMIN.user.root + "/" + params.row.id}
          >
            {params.row.firstName + " " + params.row.lastName}
          </Link>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        editable: false,
        flex: 1,
      },

      {
        field: "authorities",
        headerName: "Rôle",
        editable: false,
        renderCell: (params: any) => (
          <Chip
            label={nameForAuthority(params.value[0] as Role)}
            color="secondary"
          />
        ),
        flex: 1,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des utilisateurs"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des utilisateurs" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_ADMIN.user.new}
      togglable
      service={USER_API}
      deletable={true}
    />
  );
}

function searchFunc(user: User, filterValue: string) {
  filterValue = filterValue.toLowerCase();

  return (
    user.firstName?.toLowerCase().indexOf(filterValue) !== -1 ||
    user.lastName?.toLowerCase().indexOf(filterValue) !== -1 ||
    user.email?.toLowerCase().indexOf(filterValue) !== -1 ||
    (user.authorities !== undefined &&
      user.authorities?.some(
        (a) => a.toLowerCase().indexOf(filterValue) !== -1
      ))
  );
}
