// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { momentToDateWithoutTime } from '../../utils/formatDate';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
}

type RHDatePickerComponent = (<TInputDate, TDate = TInputDate>(props: IProps & Partial<DatePickerProps<TInputDate, TDate>>) => JSX.Element) & {
  propTypes?: any;
};

const RHFDatePicker: RHDatePickerComponent = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {

        return (
          <DatePicker {...field} {...other} value={field.value}
            onChange={e => {
              field.onChange((e) ? momentToDateWithoutTime(e) : e)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error?.message}
                fullWidth />
            )} />)
      }
      } />
  );
}

export default RHFDatePicker;