import { Link as RouterLink } from "react-router-dom";
// @mui
import { Button, Typography } from "@mui/material";
// assets
import React from "react";
import Page from "./Page";

// ----------------------------------------------------------------------
interface Props {
  title: string;
  text: string;
  illustration: JSX.Element;
}

export default function ErrorPage({ title, text, illustration }: Props) {
  return (
    <Page title={title} noHeader>
      <Typography variant="h3" paragraph>
        {title}
      </Typography>

      <Typography sx={{ color: "text.secondary" }}>{text}</Typography>

      {React.cloneElement(illustration, {
        sx: { height: 260, my: { xs: 5, sm: 10 } },
      })}

      <Button to="/" component={RouterLink} size="large" variant="contained">
        Retour à l'accueil
      </Button>
    </Page>
  );
}
