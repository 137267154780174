
import { SiteLight } from "./site";
import { Supplier } from "./supplier";


export const MAINTENANCE_SITE_API = "maintenanceSites";


export interface MaintenanceSite {
  id: number | "";
  object: string;
  comment: string;
  site: SiteLight | "";
  supplier: Supplier | "";
  periodicityType: number | string;
  periodicityComment?: string;
  frequency: number | "";
}
