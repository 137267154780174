import { useLocation, matchPath } from 'react-router-dom';
import { NavListProps } from '../components/nav-section/types';

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export default function useActiveLink(data: string | NavListProps, deep = true): ReturnType {
  const { pathname } = useLocation();

  if (typeof data === "string") {
    return {
      active: isActive(data, pathname, deep),
      isExternalLink: data.includes('http'),
    };
  }
  else {
    const activeChildren = data.children.find((child: any) => isActive(child.path, pathname, deep));
    return {
      active: activeChildren != null,
      isExternalLink: activeChildren?.path?.includes('http'),
    };
  }
}

function isActive(path: string, pathname: string, deep: boolean): boolean {
  return deep ? isDeepActive(path, pathname) : isNormalActive(path, pathname);
}

function isNormalActive(path: string, pathname: string) {
  return path ? !!matchPath({ path, end: true }, pathname) : false;
}

function isDeepActive(path: string, pathname: string) {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
}
