import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { Site, SITE_API } from "../../../@types/site";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";

// ----------------------------------------------------------------------
export default function SiteListPage() {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Nom",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_ADMIN.site.root + "/" + params.row.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "stockVin",
        headerName: "Vin",
        editable: false,
        flex: 1,
        valueGetter: (params) =>
          params.row.stocks?.find(
            (s: { type: number; quantity: number }) => s.type === 0
          )?.quantity,
      },
      {
        field: "stockCaisse",
        headerName: "Caisse",
        editable: false,
        flex: 1,
        valueGetter: (params) =>
          params.row.stocks?.find(
            (s: { type: number; quantity: number }) => s.type === 1
          )?.quantity,
      },
      {
        field: "stockPlante",
        headerName: "Extrait de plante",
        editable: false,
        flex: 1,
        valueGetter: (params) =>
          params.row.stocks?.find(
            (s: { type: number; quantity: number }) => s.type === 2
          )?.quantity,
      },
      {
        field: "stockMS",
        headerName: "Matière sèche",
        editable: false,
        flex: 1,
        valueGetter: (params) =>
          params.row.stocks?.find(
            (s: { type: number; quantity: number }) => s.type === 3
          )?.quantity,
      },
      {
        field: "cellars",
        headerName: "Caves",
        editable: false,
        flex: 1,
        valueGetter: (params) => params.row.cellars?.length,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<Site>
      title="Liste des sites"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des sites" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_ADMIN.site.new}
      service={SITE_API}
      deletable={true}
    />
  );
}

function searchFunc(site: Site, filterValue: string) {
  return site.name?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
