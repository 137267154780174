import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Icon, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Product, PRODUCT_API } from "../../@types/product";
import ICONS from "../../assets/icons";
import Page from "../../layouts/Page";
import { PATH_HOME, PATH_PRODUIT } from "../../routes/paths";
import ObjectOperationList from "../../sections/operation/ObjectOperationList";
import ProductInformations from "../../sections/product/ProductInformations";
import axiosInstance from "../../utils/axios";
import { useProductContext } from "../../contexts/ProductContext";

// ----------------------------------------------------------------------
export default function ProductPage() {
  const params = useParams();
  const [value, setValue] = useState("0");

  //const [product, setProduct] = useState<Product | undefined>(undefined);
  const { product, setProduct } = useProductContext()

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(PRODUCT_API + "/" + params.id)
        .then((response: any) => setProduct(response.data));
  }, [params.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const title = params.id
    ? "Produit " + product?.reference
    : "Nouveau produit";

  return (
    <Page title={title} HeaderProps={{
      links: [
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des produits", href: PATH_PRODUIT.root },
        { name: "Produit" },
      ]
    }}
    >
      {params.id ? (
        <TabContext value={value}>
          <TabList centered onChange={handleChange} >
            <Tab label="Informations" value="0" sx={{ flex: 0.1 }} />
            <Tab label="Opérations" value="1" sx={{ flex: 0.1 }} />
          </TabList>
          <TabPanel value="0">
            <ProductInformations />
          </TabPanel>
          <TabPanel value="1">
            <ObjectOperationList type="Product" objId={params.id} />
          </TabPanel>
        </TabContext>
      ) : (
        <ProductInformations />
      )}
    </Page>
  );
}
