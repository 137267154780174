export const PRODUCT_TYPE_API = "productTypes";


// export const SUPPLIER_DEFAULT_TYPE: ProductType = {
//   id: "",
//   name: "",
// };

export interface ProductType {
  id?: number | "";
  code?: number | "";
  name?: string;
  blockedWhenIn?: boolean
}
