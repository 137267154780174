import { Icon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { Contenance, CONTENANCE_API, CONTENANCE_DEFAULT } from "../../../@types/contenance";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_HOME } from "../../../routes/paths";

export default function ContenanceListPage() {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "valeur",
        headerName: "Valeur",
        editable: true,
        flex: 1
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<Contenance>
      title="Liste des contenances"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des contenances" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      editable
      defaultRow={CONTENANCE_DEFAULT}
      service={CONTENANCE_API}
      deletable={true}
    />
  );
}

function searchFunc(type: Contenance, filterValue: string) {
  return type.valeur?.toString().toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
