import { Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import * as Yup from "yup";
import { Client, CLIENT_API } from "../../@types/client";
import { PrestationLight, PRESTATION_API } from "../../@types/prestation";
import { ProductLight, PRODUCT_API } from "../../@types/product";
import CopastockDataGrid from "../../components/datagrid/CopastockDataGrid";
import Form, { FormCols } from "../../components/hook-form/Form";
import { PATH_PRESTATION, PATH_PRODUIT } from "../../routes/paths";
import { HOST_API } from "../../config";

interface Props {
  isNew?: boolean;
  client?: Client;
  setClient: (client: Client) => void;
}

// ----------------------------------------------------------------------
export default function ClientInformations({
  isNew,
  client,
  setClient,
}: Props) {
  const schema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire").max(50),
    reference: Yup.string().required("Référence obligatoire").max(50),
  });

  const formCols: FormCols<Client>[] = useMemo(
    () => [
      {
        xs: 12,
        elements: [
          [
            { name: "name", label: "Nom" },
            { name: "reference", label: "Référence" },
            { name: "upload_photo", label: "Photo", type: "file", accept: "image/*" },
          ],
        ],
      },
    ],
    []
  );

  const defaultValues = useMemo(
    () => ({
      id: client?.id || undefined,
      name: client?.name || "",
      reference: client?.reference || "",
      photo: client?.photo || undefined,
      upload_photo:
        client?.upload_photo ??
        (client?.photo?.url ? HOST_API + client?.photo?.url : undefined),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  return (
    <Form<Client, Yup.AnyObjectSchema>
      formCols={formCols}
      schema={schema}
      submitButtonProps={{ fullWidth: true, size: "large" }}
      obj={client}
      setObj={setClient}
      isNew={isNew}
      isEdit={!isNew}
      defaultValues={defaultValues}
      service={CLIENT_API}
    />
  );
}

interface ClientPrestationsProps {
  prestations: PrestationLight[];
}

export function ClientPrestations(props: ClientPrestationsProps) {
  const columns: GridColDef<PrestationLight>[] = useMemo(
    () => [
      {
        field: "reference",
        headerName: "Référence",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link
            sx={{ fontWeight: "bold" }}
            href={PATH_PRESTATION.root + "/" + params.row.id}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "title",
        headerName: "Titre",
        editable: false,
        flex: 1,
      },
    ],
    []
  );

  return (
    <CopastockDataGrid<PrestationLight>
      pageSize={5}
      columns={columns}
      rows={props.prestations !== undefined ? [...props.prestations] : []}
      service={PRESTATION_API}
    />
  );
}

interface ClientProduitsProps {
  produits: ProductLight[];
}

export function ClientProduits(props: ClientProduitsProps) {
  const columns: GridColDef<ProductLight>[] = useMemo(
    () => [
      {
        field: "reference",
        headerName: "Référence",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link href={PATH_PRODUIT.root + "/" + params.row.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "description",
        headerName: "Nom",
        editable: false,
        flex: 1,
        valueGetter: (params: any) => params.value ?? params.row.reference,
      },
    ],
    []
  );

  return (
    <CopastockDataGrid<ProductLight>
      columns={columns}
      rows={props.produits}
      service={PRODUCT_API}
    />
  );
}
