// @mui
// components
import { Box, Button, Icon, Stack, Typography, useTheme } from "@mui/material";
import ICONS from "../assets/icons";
import useAuth from "../hooks/useAuth";
import Page from "../layouts/Page";
import ObjectOperationList from "../sections/operation/ObjectOperationList";

// ----------------------------------------------------------------------
export default function HomePage() {
  const { user, isManager } = useAuth();
  const theme = useTheme();

  return (
    <Page title="CO.PA.Stock - Accueil" noHeader>
      <Typography sx={{ textAlign: 'right', color: 'gray', fontStyle: 'italic', fontSize: 14 }}>
        Version de l'application : {process.env.REACT_APP_VERSION ?? "non définie"}
      </Typography>

      <Stack alignItems="center">
        <Box
          component="img"
          src="/logo_big.jpg"
          sx={{
            width: "45%",
          }}
        />

        <Typography variant="h4" fontWeight={"normal"}>
          Bonjour {user?.firstName}
        </Typography>
        <Box
          sx={{
            width: "60%",
            margin: 1,
            backgroundColor: theme.palette.grey[200],
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="body1">
            Depuis cette page, vous pouvez créer :
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isManager ? (
              <>
                <Button
                  variant="contained"
                  href="/clients/nouveau"
                  sx={{ fontWeight: "normal", margin: 1 }}
                  startIcon={<Icon>{ICONS.person}</Icon>}
                >
                  Un client
                </Button>
                <Button
                  variant="contained"
                  href="/produits/nouveau"
                  sx={{ fontWeight: "normal", margin: 1 }}
                  startIcon={<Icon>{ICONS.product}</Icon>}
                >
                  Un produit
                </Button>
                <Button
                  variant="contained"
                  href="/prestations/nouveau"
                  sx={{ fontWeight: "normal", margin: 1 }}
                  startIcon={<Icon>{ICONS.prestation}</Icon>}
                >
                  Une prestation
                </Button>
              </>
            ) : null}
            <Button
              variant="contained"
              href="/operations/nouveau"
              sx={{ fontWeight: "normal", margin: 1 }}
              startIcon={<Icon>{ICONS.operation}</Icon>}
            >
              Une opération
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "60%",
            margin: 1,
            backgroundColor: theme.palette.grey[200],
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="body1">
            Il est également possible de consulter :
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              href="/operations"
              sx={{ fontWeight: "normal", margin: 1 }}
              startIcon={<Icon>{ICONS.operation}</Icon>}
            >
              La liste des opérations
            </Button>
            <Button
              variant="contained"
              href="/stocks"
              sx={{ fontWeight: "normal", margin: 1 }}
              startIcon={<Icon>{ICONS.stock}</Icon>}
            >
              Le reporting des stocks
            </Button>
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          mt: 5,
          backgroundColor: theme.palette.grey[100],
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6">Liste des opérations en doublon :</Typography>
        <ObjectOperationList objId="duplicateOnly=true" />
      </Box>
    </Page>
  );
}
