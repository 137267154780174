// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Icon,
  Box,
  Stack,
} from "@mui/material";
//
import { NoAccessDialogProps } from "./types";
import ICONS from "../../assets/icons";

// ----------------------------------------------------------------------

export default function NoAccessDialog({
  title,
  content,
  open,
  onClose,
  ...other
}: NoAccessDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2, color: "red", textAlign: 'center' }}>Accès refusé</DialogTitle>

      <DialogContent sx={{ typography: "body2" }}>
        <Stack sx={{ flex: 1, flexDirection: "row", border: "solid 0px red" }}>
          <Icon style={{ fontSize: 64, color: 'red', alignmentBaseline: "central", }}>{ICONS.noAccess}</Icon> <Stack style={{ flex: 1, border: "solid 0px purple", justifyContent: "center", textAlign: 'center', color: 'red' }}>{content ?? "Vous n'avez pas accès à cette fonctionnalité"}</Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog >
  );
}
