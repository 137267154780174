export const PATH_HOME = "/";

export const NEW_TERM = "nouveau";
const PATH_NEW = "/" + NEW_TERM;

const ROOT_CLIENT = "/clients";
export const PATH_CLIENT = {
  root: ROOT_CLIENT,
  new: ROOT_CLIENT + PATH_NEW,
};

const ROOT_PRODUIT = "/produits";
export const PATH_PRODUIT = {
  root: ROOT_PRODUIT,
  new: ROOT_PRODUIT + PATH_NEW,
  switch: ROOT_PRODUIT + "/switch",
};

const ROOT_PRESTATION = "/prestations";
export const PATH_PRESTATION = {
  root: ROOT_PRESTATION,
  new: ROOT_PRESTATION + PATH_NEW,
};

const ROOT_OPERATION = "/operations";
export const PATH_OPERATION = {
  root: ROOT_OPERATION,
  new: ROOT_OPERATION + PATH_NEW,
};

const ROOT_STOCKS = "/stocks";
export const PATH_STOCK = {
  root: ROOT_STOCKS,
};

const ROOT_NON_COMPLIANCE = "/ncs";
export const PATH_NON_COMPLIANCE = {
  root: ROOT_NON_COMPLIANCE,
  new: ROOT_NON_COMPLIANCE + PATH_NEW,
};

const ROOT_MAINTENANCE_EVENT = "/maintenanceEvents";
export const PATH_MAINTENANCE_EVENT = {
  root: ROOT_MAINTENANCE_EVENT,
  new: ROOT_MAINTENANCE_EVENT + PATH_NEW,
};

const ROOT_ADMIN = "/admin";
export const PATH_ADMIN = {
  user: {
    root: ROOT_ADMIN + "/users",
    new: ROOT_ADMIN + "/users" + PATH_NEW,
    edit: (id: number) => ROOT_ADMIN + "/users/" + id,
  },
  site: {
    root: ROOT_ADMIN + "/sites",
    new: ROOT_ADMIN + "/sites" + PATH_NEW,
    edit: (id: number) => ROOT_ADMIN + "/sites/" + id,
  },
  type_prestation: {
    root: ROOT_ADMIN + "/typesPrestation",
    new: ROOT_ADMIN + "/typesPrestation" + PATH_NEW,
    edit: (id: number) => ROOT_ADMIN + "/typesPrestation/" + id,
  },
  product_type: {
    root: ROOT_ADMIN + "/productTypes",
    new: ROOT_ADMIN + "/productTypes" + PATH_NEW,
    edit: (id: number) => ROOT_ADMIN + "/productTypes/" + id,
  },
  description: ROOT_ADMIN + "/descriptions",
  contenance: ROOT_ADMIN + "/contenances",
  categoryNC: ROOT_ADMIN + "/categoryNC",
  supplier: ROOT_ADMIN + "/suppliers",
  // productType: ROOT_ADMIN + "/productTypes",
  maintenance: {
    root: ROOT_ADMIN + "/maintenances",
    new: ROOT_ADMIN + "/maintenances" + PATH_NEW,
    edit: (id: number) => ROOT_ADMIN + "/maintenances/" + id,
  },
  migration: ROOT_ADMIN + "/migration",
};

const ROOT_AUTH = "/auth";
export const PATH_AUTH = {
  login: ROOT_AUTH + "/login",
  logout: ROOT_AUTH + "/logout",
  resetPassword: ROOT_AUTH + "/reset-password",
  changePassword: ROOT_AUTH + "/change-password",
  newPassword: ROOT_AUTH + "/new-password",
};

export const PATH_REGLAGES = "/reglages";
export const PATH_APK = "/apk";
