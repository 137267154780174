import { createContext, ReactNode, useEffect, useReducer } from "react";
import { ActionMap } from "../@types/global";
import { Role, User } from "../@types/user";
import { HOST_API } from "../config";
// utils
import { isValidToken, setSession } from "../utils/jwt";
import axiosInstance from "../utils/axios";

// ----------------------------------------------------------------------
enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Update = "UPDATE",
}

type AuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: null | User;
  };
  [Types.Login]: {
    user: User;
  };
  [Types.Logout]: undefined;
  [Types.Update]: {
    user: User;
  };
};

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | User;
  isManager: boolean;
}

interface AuthContextType {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  isManager: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  update: (user: any) => Promise<void>;
}

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isManager: false,
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        isManager: (action.payload.user?.authorities) ? action.payload.user?.authorities?.some(a => a === Role.ROLE_MANAGER) : false
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isManager: (action.payload.user?.authorities) ? action.payload.user?.authorities?.some(a => a === Role.ROLE_MANAGER) : false
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isManager: false,
      };

    case "UPDATE":
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------
interface AuthProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = (await axiosInstance.get(HOST_API + "/connect/userinfo")).data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    const response = await axiosInstance.post(HOST_API + "/connect/token", {
      username,
      password,
    }).catch((e) => {
      console.log("login - erreur : ", e)
      throw new Error(e);
    });

    if (response.status === 401) { // Unauthorised

      if (response.data) {
        throw response;
      }
    }

    const access_token = response.data;

    if (!access_token) {
      throw new Error("Token d'accès invalide");
    }

    setSession(access_token);

    const user = (await axiosInstance.get(HOST_API + "/connect/userinfo")).data;
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const update = async (user: any) => {

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
