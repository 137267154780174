// assets
import { Link as RouterLink } from "react-router-dom";
import { Button, Icon, Stack, Typography } from "@mui/material";
import Page from "../../layouts/Page";
import React, { useState } from "react";
import { PATH_HOME } from "../../routes/paths";
import ICONS from "../../assets/icons";
import axiosInstance from "../../utils/axios";
import { STOCK_API } from "../../@types/stock";
import { OPERATION_API } from "../../@types/operation";
import { AVANCEMENT_PRESTATION_API, PRESTATION_API } from "../../@types/prestation";
import { MAINTENANCE_SITE_API } from "../../@types/maintenanceSite";
import { CATEGORYNC_API } from "../../@types/categoryNC";
import { PRODUCT_TYPE_API } from "../../@types/productType";

interface action {
    libelle: string,
    url: string
}

// ----------------------------------------------------------------------
export default function MigrationPage() {
    const [resultat, setResultat] = useState("")
    const [action, setAction] = useState<action | undefined>(undefined)

    let links = [
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Mise à jour des données" },
    ]

    const checkUrl = (url: string) => {

        console.log("url : " + url)
        axiosInstance.get(url).then((response: any) => {
            let data = response.data
            console.log("Info : ", data)
            //setResultat(data)
        })
    }

    const check = (action: action, modeEcriture: boolean) => {
        let url = action.url + ((modeEcriture === true) ? "/true" : "")
        console.log("url : " + url)
        axiosInstance.get(url).then((response: any) => {
            let data = response.data
            console.log("Info : ", data)
            setResultat(data)
            setAction((modeEcriture === true) ? undefined : action)
        })
    }

    const download = (url: string) => {
        console.log("url : " + url)
        axiosInstance.get(url)
        // .then((response: any) => {
        // let data = response.data
        // console.log("Info : ", data)
        // setResultat(data)
        // setAction((modeEcriture === true) ? undefined : action)
        // })
    }

    let infoResultat: any
    if (resultat && resultat !== "") {
        let resultats = resultat.split("\n")
        if (resultats.length > 0) {
            infoResultat = <Stack direction="column" sx={{ py: 1, px: 2, color: 'grey', fontStyle: 'italic' }}>
                {resultats.map((r: string, index: number) => <span key={index}>{(r == "") ? <br /> : r}</span>)}
                {action && <Stack sx={{ py: 2 }}><Button onClick={() => check(action, true)} size="medium" variant="outlined" color="error" > {"Relancer " + action.libelle + " en mode écriture"} </Button></Stack>}
            </Stack>
        }
    }

    let majs: action[] = [
        { libelle: "Prestations", url: PRESTATION_API + "/maj" },
        { libelle: "Maintenances", url: MAINTENANCE_SITE_API + "/init" },
        { libelle: "Types de produit", url: PRODUCT_TYPE_API + "/init" },
    ]

    let controles: action[] = [
        { libelle: "Prestations", url: PRESTATION_API + "/fix" },
        { libelle: "Avancements", url: AVANCEMENT_PRESTATION_API + "/fix" },
        { libelle: "Stocks", url: STOCK_API + "/fix" },
        { libelle: "Opérations", url: OPERATION_API + "/fix" },
        { libelle: "Catégorie NC", url: CATEGORYNC_API + "/fix" },
    ]

    const listButtons = (listActions: action[]) => listActions.map((item: action, index: number) => <Button onClick={() => check(item, false)} size="large" variant="contained" key={index}> {item.libelle} </Button>)

    return (
        <Page title="Mise à jour des données" HeaderProps={{ links }}>

            <Typography variant="h6" paragraph sx={{ py: 1, px: 2 }}>Liste des boutons de mises à jour des données</Typography>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ py: 1, px: 2 }}
                // justifyContent="space-between" 
                spacing={2}
                alignItems="stretch"
            >
                {listButtons(majs)}
            </Stack>

            <Typography variant="h6" paragraph sx={{ py: 1, px: 2 }}>Liste des boutons de contrôle des données</Typography>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ py: 1, px: 2 }}
                // justifyContent="space-between" 
                spacing={2}
                alignItems="stretch"
            >
                {listButtons(controles)}

            </Stack>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ py: 1, px: 2 }}
                // justifyContent="space-between" 
                spacing={2}
                alignItems="stretch"
            >
                {/* <Button onClick={() => checkUrl("categoryNCs?timestamp=1705936299729")} size="large" variant="contained" > Test</Button>
                <Button onClick={() => checkUrl("categoryNCs")} size="large" variant="contained" > Test</Button> */}

            </Stack>
            {infoResultat}
        </Page>
    );
}
