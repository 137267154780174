import { PrestationType } from "./PrestationType";
import { ClientLight } from "./client";
import { Media, UploadPhoto } from "./media";
import { MyLog } from "./myLog";
import { Operation } from "./operation";
import { ProductLight, ProductStock } from "./product";
import { Cellar, Site, SiteLight } from "./site";

export const PRESTATION_API = "prestations";
export const AVANCEMENT_PRESTATION_API = "avancementPrestations";

export const PRESTATION_TYPE_DEFAULT: PrestationType = {
  id: "",
  title: "",
  isTri: false,
  withRilsan: false,
};

export interface PrestationLight {
  id: number;
  title: string;
  reference: string;
  pourcentageAvancement: number;
  nbProdEntree: number;
  nbProdEntreeTraites: number;

}

export interface Prestation extends PrestationLight {
  begin_date: Date;
  end_date: Date;
  type?: PrestationType | "";
  client?: ClientLight | "";
  products?: ProductStock[];
  productsDestination?: ProductLight[];
  comment?: string;
  medias?: Media[];
  upload_photos?: UploadPhoto[] | string[] | string | Media[];
  avancements?: AvancementPrestation[];
  site?: SiteLight | "";
  cellar?: Cellar | "";
  logs?: MyLog[]
}

export interface PrestationEntry {
  id: number;
  reference: string;
  title: string;
  client_id: number;
  client_name: string;
  type_id: number;
  type_name: string;
  from_date: Date;
  to_date: Date;
}

export interface AvancementPrestation {
  id: number;
  archived: boolean;
  dateRealisation: Date;
  prestationId: number;
  operationsProdEntree?: { [key: number]: Operation };
  operationsProdSortie?: { [key: number]: Operation };
  nbRilsan?: number
  isChecked?: boolean
  checkedBy?: string
  checkedDate?: Date
  uncheckedBy?: string
  uncheckedDate?: Date
}
