import { Icon, Link, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import {
  PRESTATION_TYPE_DEFAULT,
} from "../../../@types/prestation";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import { PRESTATION_TYPE_API, PrestationType } from "../../../@types/PrestationType";

export default function PrestationTypeListPage() {


  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Nom",
        //editable: true,
        flex: 4,
        renderCell: (params: any) => (
          <Link
            sx={{ fontWeight: "bold" }}
            href={PATH_ADMIN.type_prestation.root + "/" + params.row.id}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "withRilsan",
        headerName: "Avec Rilsan",
        flex: 1,
        renderCell: (params: any) => ((params.value === true) ? <Icon color="primary">{ICONS.check}</Icon> : <></>)
        ,
      },
      {
        field: "isTri",
        headerName: "Est un tri",
        flex: 1,
        renderCell: (params: any) => ((params.value === true) ? <Icon color="primary">{ICONS.check}</Icon> : <></>)
        ,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<PrestationType>
      title="Liste des types de prestation"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des types de prestation" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      //editable
      defaultRow={PRESTATION_TYPE_DEFAULT}
      service={PRESTATION_TYPE_API}
      pathNew={PATH_ADMIN.type_prestation.new}
      deletable={true}
    //togglable
    //togglableData={{ field: "isTri", headerName: "Est un tri" }}
    />
  );
}

function searchFunc(type: PrestationType, filterValue: string) {
  return type.title?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
