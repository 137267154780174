import React, { useEffect, useState } from "react"
import { Prestation } from "../@types/prestation"

export interface PrestationContextInterface {
    prestation: Prestation | undefined
    setPrestation: any // (obj: any) => void // () => {}
}

const PrestationContext = React.createContext<PrestationContextInterface>({
    prestation: undefined,
    setPrestation: (x: any) => { }
})

export const PrestationProvider = ({ children }: any) => {
    const [prestation, setPrestation] = useState(undefined)

    return <PrestationContext.Provider value={{ prestation, setPrestation }}>
        {children}
    </PrestationContext.Provider>
}

export const usePrestationContext = () => React.useContext(PrestationContext)