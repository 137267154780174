export const CATEGORYNC_API = "categoryNCs";

// export const CATEGORYNC_DEFAULT: CategoryNC = {
//   id: "",
//   value: "",
// };

export interface CategoryNC {
  id: number | "";
  value: string;
}
