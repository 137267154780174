import moment from "moment";

export const dateTimeDotNetTo = (datetime: string | Date | undefined, format: string) => {
    if (datetime == null || datetime === undefined || datetime === "") {
        return "";
    }

    let dt = moment(datetime, 'YYYY-MM-DDTHH:mm', "fr");
    return dt.format(format);
}

export const dateTimeDotNetToDate = (datetime?: string | Date) => {
    return dateTimeDotNetTo(datetime, "DD/MM/YYYY")
}

export const dateTimeDotNetToShortDate = (datetime?: string | Date) => {
    return dateTimeDotNetTo(datetime, "DD/MM/YY")
}

export const dateTimeDotNetToDateTime = (datetime?: string | Date) => {
    return dateTimeDotNetTo(datetime, "DD/MM/YYYY HH:mm")
}

export const dateTimeDotNetToLogDateTime = (datetime?: string | Date) => {
    return dateTimeDotNetTo(datetime, "DD/MM/YYYY à HH:mm")
}

export const dateTimeDotNetToTimeStamp = (datetime?: string | Date) => {
    if (datetime == null || datetime === undefined || datetime === "") {
        return moment();
    }

    return moment(datetime, 'YYYY-MM-DDTHH:mm', "fr");
}

export const momentToDateWithoutTime = (datetime?: any) => {
    let m = moment(datetime)
    if (m.isValid()) {
        return m.format("YYYY-MM-DD") + "T00:00:00.000Z"
    }
    return datetime
}

export const momentToFormat = (datetime: any, format: string) => {
    let m = moment(datetime)
    if (m.isValid()) {
        return m.format(format)
    }
    return ""
}