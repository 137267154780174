// @mui
// components
import { Box, BoxProps, Container } from "@mui/material";
import { forwardRef, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import PageHeader, { PageHeaderProps } from "./PageHeader";

// ----------------------------------------------------------------------

export interface PageProps extends BoxProps {
  title?: string;
  children: React.ReactNode;
  meta?: ReactNode;
  HeaderProps?: PageHeaderProps;
  withContainer?: boolean;
  noHeader?: boolean;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = "",
      meta,
      HeaderProps,
      withContainer,
      noHeader,
      ...other
    },
    ref
  ) => {
    const content = (
      <>
        {!noHeader && <PageHeader {...HeaderProps} title={title} />}
        {children}
      </>
    );

    return (
      <>
        <Helmet>
          <title>{title}</title>
          {meta}
        </Helmet>

        <Box
          sx={{
            overflow: "hidden",
            position: "relative",
            bgcolor: "background.default",
            py: 0,
          }}
          ref={ref}
          {...other}
        >
          {withContainer ? (
            <Container maxWidth="md" sx={{ position: "relative" }}>
              {content}
            </Container>
          ) : (
            content
          )}
        </Box>
      </>
    );
  }
);

export default Page;
