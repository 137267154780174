// @mui
// hooks
// import useAuth from '../hooks/useAuth';
// assets
// import Page403 from 'src/pages/Page403';

import { Role } from "../@types/user";
import useAuth from "../hooks/useAuth";
import Page403 from "../pages/Page403";

// ----------------------------------------------------------------------

interface RoleBasedGuardProp {
  hasContent?: boolean;
  roles?: Role[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
  const { user } = useAuth();
  const currentRoles = user?.authorities;

  if (typeof roles !== 'undefined' && !roles.some(r => currentRoles?.includes(r))) {
    return hasContent ? <Page403 /> : null;
  }

  return <>{children}</>;
}
