export const PRESTATION_TYPE_API = "prestationTypes";


export interface PrestationType {
  id?: any;
  title: string;
  isTri: boolean;
  withRilsan: boolean;
}

