import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Icon } from "@mui/material";
import Page from "../../../layouts/Page";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import ICONS from "../../../assets/icons";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { useForm } from "react-hook-form";
import { FormCols, FormWithMethods } from "../../../components/hook-form/Form";
import { PRESTATION_TYPE_API, PrestationType } from "../../../@types/PrestationType";

export default function PrestationTypePage() {

    const params = useParams();
    const [param, setParam] = useState<PrestationType | undefined>(undefined);

    useEffect(() => {
        //pour récupérer les données
        if (params.id !== undefined) {
            console.log("TypePrestationPage - useEffect")
            axiosInstance
                .get(PRESTATION_TYPE_API + "/" + params.id)
                .then((response: any) => setParam(response.data));
        }
    }, [params.id]);

    const schema = Yup.object().shape({
        title: Yup.string().required("Nom obligatoire").max(50),
    });

    const defaultValues: PrestationType = useMemo(
        () =>
        ({
            id: param?.id ?? undefined,
            title: param?.title ?? "",
            isTri: param?.isTri ?? false,
            withRilsan: param?.withRilsan ?? false,

        }),
        [param]
    );

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });


    const formCols: FormCols<PrestationType>[] = useMemo(
        () => [
            {
                elements: [
                    [
                        { name: "title", label: "Nom" },
                        {
                            name: "isTri", label: "Est un tri",
                            type: "switch",
                            xs: 6,
                        },
                        {
                            name: "withRilsan", label: "Avec nb Rilsan",
                            type: "switch",
                            xs: 6,
                        },
                    ],
                ],
            },
        ],
        []
    );

    return <Page
        title={
            params.id
                ? param?.title
                : "Nouveau type de prestation"
        }
        HeaderProps={{
            links: [
                {
                    name: "Accueil",
                    href: PATH_HOME,
                    icon: <Icon>{ICONS.home}</Icon>,
                },
                { name: "Admin" },
                { name: "Liste des types de prestation", href: PATH_ADMIN.type_prestation.root },
                { name: "Type de prestation" },
            ],
        }}
        withContainer
    >
        <FormWithMethods<PrestationType, Yup.AnyObjectSchema>
            methods={methods}
            formCols={formCols}
            schema={schema}
            submitButtonProps={{ fullWidth: true, size: "large" }}
            service={PRESTATION_TYPE_API}
            obj={param}
            setObj={setParam}
            isNew={!params.id}
            isEdit={!!params.id}
            defaultValues={defaultValues}
        />

    </Page>
}