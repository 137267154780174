export const SUPPLIER_API = "suppliers";


export const SUPPLIER_DEFAULT_TYPE: Supplier = {
  id: "",
  name: "",
};

export interface Supplier {
  id?: number | "";
  name?: string;
}
