export const USER_API = "users";

export const USER_DEFAULT: User = {
  id: undefined,
  firstName: "",
  lastName: "",
  activated: false,
};

export interface UserLight {
  id?: any;
  firstName?: string;
  lastName?: string;
}

export interface User extends UserLight {
  email?: string;
  activated?: Boolean;
  authorities?: string[];
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}

export enum Role {
  ROLE_USER = "ROLE_USER",
  ROLE_MANAGER = "ROLE_MANAGER",
}

export function nameForAuthority(role: Role): string {
  switch (role) {
    case Role.ROLE_USER:
      return "TECHNICIEN";
    case Role.ROLE_MANAGER:
      return "MANAGER";
  }
}

export function roleDescription(role: Role): string {
  switch (role) {
    case Role.ROLE_USER:
      return "Technicien";
    case Role.ROLE_MANAGER:
      return "Manager (gestionnaire de contenus)";
  }
}
