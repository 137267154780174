import { SiteLight } from "./site";
import { ClientLight } from "./client";
import { Media, UploadPhoto } from "./media";
import { Icon } from "@mui/material";
import { MyLog } from "./myLog";

export const PRODUCT_API = "products";
export const PRODUCT_SUB_REF_API = PRODUCT_API + "/createSubRef"

export interface ProductTypeStock {
  type: ProductType;
  quantity: number;
}

export enum ProductType {
  WINE = 0,
  BOX = 1,
  PLANT_EXTRACT = 2,
  DRY_MATERIAL = 3,
}

export const PRODUCT_TYPE_MAP = {
  [ProductType.WINE]: "Vin",
  [ProductType.BOX]: "Caisse",
  [ProductType.PLANT_EXTRACT]: "Extrait de plante",
  [ProductType.DRY_MATERIAL]: "Matière sèche",
};

export const PRODUCT_CODE_MAP = {
  ["WINE"]: "Vin",
  ["BOX"]: "Caisse",
  ["PLANT_EXTRACT"]: "Extrait de plante",
  ["DRY_MATERIAL"]: "Matière sèche",
};

export const ProductTypeGetLabel = (code: string) => {
  switch (code) {
    case "" + ProductType.WINE:
    case "WINE":
      return "Vin";

    case "" + ProductType.BOX:
    case "BOX":
      return "Caisse";

    case "" + ProductType.PLANT_EXTRACT:
    case "PLANT_EXTRACT":
      return "Extrait de plante";

    case "" + ProductType.DRY_MATERIAL:
    case "DRY_MATERIAL":
      return "Matière sèche";

    default:
      return code;
  }
};

export interface ProductLight {
  id: number | "";
  reference: string;
  productType?: number | "";
  type?: number;
  description: string;
  bottle_size: string;
  client_id: number;
}

export interface Product extends ProductLight {
  contents?: string;
  color?: string;
  client?: ClientLight | number | "";
  bottle_date?: Date;
  old_bottle: boolean;
  stocks?: ProductSiteQuantity[];
  site_id?: number;
  client_name?: string;
  site_name?: string;
  photo?: Media;
  upload_photo?: UploadPhoto | string;
  photos?: Media[];
  upload_photos?: UploadPhoto[] | string[] | string | Media[];
  logs?: MyLog[]
}

export interface ProductStock {
  id?: number;
  product?: ProductLight | "";
  quantity: number | "";
}

export interface ProductEntryStock {
  site_id: number;
  site_name?: string;
  stock: number;
}

export interface ProductSiteQuantity {
  id?: string
  siteId?: number;
  initialQuantity: number;
  currentQuantity: number;
  site: SiteLight;
  isBlocked?: boolean
}

export interface ProductEntry {
  id: number;
  reference: string;
  client_id: number;
  client_name: string;
  productType: number;
  site_id?: number;
  site_name?: string;
  quantity: number;
  old_bottle: boolean;
}

export function getProductOptionLabel(p: any) {
  return p == "" ? "" : p.reference + (p.description ? " - (" + p.description + ")" : "") + (p.bottle_size ? " - " + p.bottle_size + "L" : "")
}