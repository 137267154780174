import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import { PRODUCT_TYPE_API, ProductType } from "../../../@types/productType";
import IconProductType from "../../../components/IconProductType";

// ----------------------------------------------------------------------
export default function ProductTypeListPage() {
  const columns: GridColDef<ProductType>[] = useMemo(
    () => [
      {
        field: "icon",
        headerName: "Icône",
        editable: false,
        flex: 0.25,
        renderCell: (params: any) => <IconProductType code={"" + params.row.code} />,
        align: "center",
      },
      {
        field: "name",
        headerName: "Libellé",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link
            sx={{ fontWeight: "bold" }}
            href={PATH_ADMIN.product_type.root + "/" + params.row.id}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "code",
        headerName: "Code",
        flex: 0.5,
      },
      {
        field: "blockedWhenIn",
        headerName: "Blocage en entrée",
        flex: 1,
        renderCell: (params: any) => ((params.value === true) ? <Icon color="primary">{ICONS.check}</Icon> : <></>)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des types de produit"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des types de produit" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      canCreate={false}
      //pathNew={PATH_ADMIN.product_type.new}
      service={PRODUCT_TYPE_API}
      deletable={false}
      defaultTableProps={{
        defaultOrderBy: "id"
      }}

    />
  );
}

function searchFunc(param: ProductType, filterValue: string) {
  filterValue = filterValue.toLowerCase();

  return (
    param.name?.toLowerCase().indexOf(filterValue) !== -1
  );
}
