import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Icon, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Client, CLIENT_API } from "../../@types/client";
import ICONS from "../../assets/icons";
import Page from "../../layouts/Page";
import { PATH_CLIENT, PATH_HOME } from "../../routes/paths";
import ClientInformations, {
  ClientPrestations,
  ClientProduits,
} from "../../sections/client/ClientInformations";
import ObjectOperationList from "../../sections/operation/ObjectOperationList";
import axiosInstance from "../../utils/axios";

// ----------------------------------------------------------------------
export default function ClientPage() {
  const params = useParams();
  const [value, setValue] = useState("0");

  const [client, setClient] = useState<Client | undefined>(undefined);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(CLIENT_API + "/" + params.id)
        .then((response: any) => setClient(response.data));
  }, [params.id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const title = params.id ? "Client " + client?.name : "Nouveau client";

  return (
    <Page
      title={title}
      withContainer={!params.id}
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Liste des clients", href: PATH_CLIENT.root },
          { name: "Client" },
        ],
      }}
    >
      {params.id ? (
        <TabContext value={value}>
          <TabList centered value={value} onChange={handleChange}>
            <Tab label="Informations" value="0" />
            {client?.products ? <Tab label="Produits" value="1" /> : null}
            {client?.services ? <Tab label="Prestations" value="2" /> : null}
            <Tab label="Opérations" value="3" />
          </TabList>
          <Container>
            <TabPanel value="0">
              <ClientInformations client={client} setClient={setClient} />
            </TabPanel>
            {client?.products?.length ? (
              <TabPanel value="1">
                <ClientProduits produits={client?.products} />
              </TabPanel>
            ) : null}
            {client?.services?.length ? (
              <TabPanel value="2">
                <ClientPrestations prestations={client?.services ?? []} />
              </TabPanel>
            ) : null}
          </Container>
          <TabPanel value="3">
            <ObjectOperationList type="Client" objId={params.id} />
          </TabPanel>
        </TabContext>
      ) : (
        <ClientInformations isNew client={client} setClient={setClient} />
      )}
    </Page>
  );
}
