import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Stack } from "@mui/material";
import RHFAutocomplete from "../../components/hook-form/RHFAutocomplete";
import { RHFTextField } from "../../components/hook-form";
import { getProductOptionLabel } from "../../@types/product";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ICONS from "../../assets/icons";
import axiosInstance from "../../utils/axios";
import { PRESTATION_API } from "../../@types/prestation";
import { usePrestationContext } from "../../contexts/PrestationContext";
import { useSnackbar } from "notistack";

interface EditQtyProductProps {
    data: any
    products: any
}

export default function EditQtyProduct(props: EditQtyProductProps) {
    const { data, products } = props
    const [showDialog, setShowDialog] = useState(false)
    const [qty, setQty] = useState<number>(data.quantity)
    const [raison, setRaison] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    const qtyInit = data.quantity

    const schema = useMemo(() => (
        Yup.object().shape({
            quantity: Yup.number().integer("Qté non entière").typeError("Qté obligatoire"),
            raison: Yup.string().required("Raison obligatoire"),
        })
    ), []);

    // useEffect(() => console.log("EditQtyProduct - data : ", data), [data])
    const { prestation, setPrestation } = usePrestationContext()

    const updateQty = async () => {

        if (raison == "") {
            enqueueSnackbar(
                "Veuillez indiquer le motif",
                { variant: "error" }
            );
            return
        }

        if (qtyInit == qty) {
            enqueueSnackbar(
                "La quantité saisie est la même qu'avant.",
                { variant: "error" }
            );
            return
        }

        console.log("updateQty")
        //let valeurs = methods.getValues();
        let body = { prestationId: prestation?.id, productId: data.product.id, quantity: qty, raison }
        console.log("updateQty - body : ", body)
        await axiosInstance.put(PRESTATION_API + "/EditQtyProduct", body).then((res: any) => {
            console.log("res : ", res)
            setShowDialog(false)
            setPrestation(res.data)
            enqueueSnackbar(
                "la modification a bien été prise en compte",
                { variant: "success" }
            );
            setRaison("")
        })
    }

    return <>
        <Box sx={{ height: 56, display: "flex", alignItems: "center" }}>
            <IconButton
                color="primary"
                onClick={() => setShowDialog(true)}
            ><Icon>{ICONS.edit}</Icon></IconButton>
        </Box>

        <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="sm" fullWidth={true}  >
            <DialogTitle>Modification de la quantité</DialogTitle>
            <DialogContent>
                <Stack padding={2} spacing={1}>
                    {/* <DialogContentText>Veuillez indiquer la quantité et la raison</DialogContentText> */}

                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <RHFAutocomplete
                            name={"product"}
                            label="Produit"
                            sx={{ flex: 2 }}
                            options={products}
                            value={data.product}
                            getOptionLabel={getProductOptionLabel}
                            isOptionEqualToValue={(opt, val) => val.id === opt.id}
                            disabled
                        />

                        <RHFTextField
                            type="number"
                            name="quantity"
                            label="Qté"
                            placeholder="0"
                            value={qty}
                            onChange={(event) => setQty(parseInt(event.target.value))}
                            sx={{ flex: 1 }}
                        />
                    </Stack>

                    <RHFTextField
                        type="text"
                        name={"raison"}
                        value={raison}
                        label="Raison de la modification"
                        placeholder=""
                        onChange={(event) => setRaison(event.target.value)}
                    />
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialog(false)}>Femer</Button>
                <Button onClick={updateQty}>Enregistrer</Button>
            </DialogActions>
        </Dialog>
    </>
}