export const CONTENANCE_API = "contenances";

export const CONTENANCE_DEFAULT: Contenance = {
  id: "",
  valeur: 0,
};

export interface Contenance {
  id: number | "";
  valeur: number;
}
