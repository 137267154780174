// @mui
// components
// assets
import { PageNotFoundIllustration } from '../assets';
import ErrorPage from '../layouts/ErrorPage';

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <ErrorPage title="Page non trouvée" text="Désolé, nous n'avons pas trouvé la page que vous recherchez. Peut-être y a-t-il une erreur dans l'URL ? Assurez-vous de son écriture." illustration={<PageNotFoundIllustration />} />
  );
}
