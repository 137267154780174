import { Icon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_HOME } from "../../../routes/paths";
import { CategoryNC, CATEGORYNC_API } from "../../../@types/categoryNC";

export default function CategoryNCListPage() {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "value",
        headerName: "Valeur",
        editable: true,
        flex: 1
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<CategoryNC>
      title="Liste des catégories des non-conformités"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des catégories" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      editable
      defaultRow={{ value: "", id: 0 }}
      service={CATEGORYNC_API}
      deletable={true}
    />
  );
}

function searchFunc(type: CategoryNC, filterValue: string) {
  return type.value?.toString().toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
