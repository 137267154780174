import { Icon, Link, Tooltip } from "@mui/material";
import { GridColDef, gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { Prestation, PRESTATION_API } from "../../@types/prestation";
import ICONS from "../../assets/icons";
import { DateFilter } from "../../components/datagrid/DateFilter";
import ListPage from "../../layouts/ListPage";
import { PATH_HOME, PATH_PRESTATION } from "../../routes/paths";
import CircularProgressWithLabel from "../../components/progress/CircularProgressWithLabel";
import { ProductLight, ProductStock } from "../../@types/product";
import { SITE_API, Site } from "../../@types/site";
import axiosInstance from "../../utils/axios";
import ExportButton from "../../components/ExportButton";
import { getListExportExcel } from "../../utils/toolsPost";
import IconIsCheck from "../../components/IconIsCheck";

// ----------------------------------------------------------------------
export default function PrestationListPage() {
  const [period, setPeriod] = useState<DateRange<Moment>>([
    moment().subtract(1, "month").add(1, "days"),
    moment(),
  ]);
  const [sites, setSites] = useState<Site[]>([]);
  const [apiRef, setApiRef] = useState();

  useEffect(() => {
    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);

  const columns: GridColDef<Prestation>[] = useMemo(
    () => [
      {
        field: "reference",
        headerName: "N° commande",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_PRESTATION.root + "/" + params.row.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "site",
        headerName: "Site",
        editable: false,
        flex: 1,
        valueGetter: (params) => params.value.name
      },
      {
        field: "medias",
        headerName: "PJ",
        editable: false,
        flex: 0.1,
        valueGetter: (params: any) => (params.value.length === 0) ? null : params.value.length
      },
      {
        field: "type",
        headerName: "Type",
        editable: false,
        flex: 4,
        valueGetter: (params) => params.value.title
      },
      {
        field: "begin_date",
        headerName: "Début",
        editable: false,
        flex: 1,
        type: "date",
        valueGetter: ({ value }) => value && new Date(value)
      },
      {
        field: "end_date",
        headerName: "Fin",
        editable: false,
        flex: 1,
        type: "date",
        valueGetter: ({ value }) => value && new Date(value)
      },
      {
        field: "qteTraitee",
        headerName: "Quantité traitée",
        editable: false,
        sortable: false,
        filterable: false,
        align: "center",
        headerAlign: "center",
        flex: 1,
        valueGetter: ({ row }) => row.nbProdEntreeTraites.toLocaleString() + "/" + row.nbProdEntree.toLocaleString()
      },
      {
        field: "productName",
        headerName: "Produit",
        editable: false,
        flex: 1.5,
        valueGetter: ({ row }) => (row.products) ? (row.products[0].product) ? row.products[0].product.reference : "" : ""
      },
      {
        field: "pourcentageAvancement",
        headerName: "Avancement %",
        type: "number",
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 1,
        renderCell: (params) => <CircularProgressWithLabel value={params.value} />
      },
      {
        field: "diffES",
        headerName: "",
        editable: false,
        filterable: false,
        align: "center",
        resizable: false,
        flex: 0.5,
        renderCell: ({ value }) => {
          return value === false ? <></> : <Tooltip title="Un ou plusieurs avancements n'ont pas le même nombre d'entrées que de sorties"><Icon>{ICONS.warning}</Icon></Tooltip>;
        }
      },
      {
        field: "isChecked",
        headerName: "Contrôlé",
        editable: false,
        align: "center",
        headerAlign: "center",
        flex: 0.5,
        renderCell: (params) => <IconIsCheck isChecked={params.value} />
      },

      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des prestations"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des prestations" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_PRESTATION.new}
      service={PRESTATION_API}
      getListMethod="list"
      urlFilterText={"date_start=" + period[0]?.toISOString() + "&date_end=" + period[1]?.toISOString()}
      canCreate={true}
      filterComponents={[
        <DateFilter period={period} setPeriod={setPeriod} key="df" />
      ]}
      filters={[
        {
          name: "site.id",
          type: "select",
          label: "Site",
          items: sites.map(s => ({ value: s.id + "", label: s.name! })),
        },
      ]}
      setApiRef={setApiRef}
      headerActions={[
        // <ExportButton launchFct={() => exportExcel(PRESTATION_API, period, apiRef, "prestations.xlsx")} key="export" />,
        <ExportButton launchFct={() => getListExportExcel(PRESTATION_API, period, apiRef, "prestations.xlsx")} key="export" />,
      ]}
    />
  );
}

function searchFunc(pres: Prestation, filterValue: string) {

  filterValue = filterValue.toLowerCase();

  return (
    pres.reference?.toLowerCase().indexOf(filterValue) !== -1
    // || pres.title?.toLowerCase().indexOf(filterValue) !== -1
    || ((pres.products?.filter((p: ProductStock) => p.product && p.product?.reference.toLowerCase().indexOf(filterValue) !== -1).length ?? 0) > 0)
    || ((pres.products?.filter((p: ProductStock) => p.quantity && p.quantity.toString().indexOf(filterValue) !== -1).length ?? 0) > 0)
    || ((pres.productsDestination?.filter((p: ProductLight) => p && p.reference.toLowerCase().indexOf(filterValue) !== -1).length ?? 0) > 0)
    || (pres.nbProdEntree.toString().indexOf(filterValue) !== -1)
    || (pres.nbProdEntreeTraites.toString().indexOf(filterValue) !== -1)
    || (pres.type !== "" && pres.type?.title?.toLowerCase().indexOf(filterValue) !== -1)
    || (pres.client !== "" && pres.client?.name?.toLowerCase().indexOf(filterValue) !== -1)
    // || (pres.begin_date && new Date(pres.begin_date).toDateString().indexOf(filterValue) !== -1)
    // || (pres.end_date && new Date(pres.end_date).toDateString().indexOf(filterValue) !== -1)
  );
}
