import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import axiosInstance from "../../utils/axios";
import { SERVICE_DISPLAY_NAME } from "../hook-form/Form";
import ConfirmDialog from "./ConfirmDialog";

export type DeleteConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  name: string;
  service: string;
  callback?: (data: any) => void;
};

export default function DeleteconfirmDialog({
  open,
  onClose,
  name,
  service,
  callback,
}: DeleteConfirmDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Suppression"
      content={
        <>
          Êtes-vous sûr de vouloir supprimer
          <strong> {name} </strong> ?
        </>
      }
      action={
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            let _ = axiosInstance
              .delete(service)
              .then(() => {
                onClose();
                enqueueSnackbar(
                  `${
                    SERVICE_DISPLAY_NAME[
                      service.split("/")[0] as keyof typeof SERVICE_DISPLAY_NAME
                    ]
                  } supprimé(e)`,
                  { variant: "success" }
                );
              })
              .catch((e) => {
                enqueueSnackbar(
                  `La suppression de ${
                    SERVICE_DISPLAY_NAME[
                      service.split("/")[0] as keyof typeof SERVICE_DISPLAY_NAME
                    ]
                  } a échoué.`,
                  { variant: "error" }
                );
                console.error(e);
              });
            if (callback) callback(_);
          }}
        >
          Supprimer
        </Button>
      }
    />
  );
}
