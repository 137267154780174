import { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../utils/axios";
import { MAINTENANCE_EVENT_API, MaintenanceEvent, searchMaintenanceEvent } from "../../@types/maintenanceEvent";
import { Box, Button, Icon, Link, Stack } from "@mui/material";
import { dateTimeDotNetToShortDate } from "../../utils/formatDate";
import { GridColDef } from "@mui/x-data-grid-pro";
import List from "../../components/datagrid/List";
import { PATH_MAINTENANCE_EVENT } from "../../routes/paths";
import useAuth from "../../hooks/useAuth";
import { Role } from "../../@types/user";
import ICONS from "../../assets/icons";
import { Link as RouterLink } from "react-router-dom";

interface MaintenanceEventListProps {
    maintenanceId: number | "";
}

export default function MaintenanceEventList({ maintenanceId }: MaintenanceEventListProps) {
    const [maintenanceEvents, setMaintenanceEvents] = useState<MaintenanceEvent[]>([]);
    const { user } = useAuth();

    useEffect(() => {
        axiosInstance
            .get(MAINTENANCE_EVENT_API + "/list/" + maintenanceId)
            .then((response) => setMaintenanceEvents(response.data));

    }, [maintenanceId]);

    const pathNew = PATH_MAINTENANCE_EVENT.new + "/" + maintenanceId

    if (!maintenanceId || maintenanceEvents.length == 0) {

        return <>
            <Stack direction="row" sx={{ flex: 1 }} spacing={2}>
                <Button
                    variant="contained"
                    component={RouterLink}
                    to={pathNew}
                    startIcon={<Icon>{ICONS.add}</Icon>}
                >
                    Nouveau
                </Button>
            </Stack>
            <div style={{ color: 'gray', textAlign: 'center', marginTop: 10, fontStyle: 'italic' }} >
                Aucune opération de maintenance n'a été réalisée
            </div>

        </>
    }

    const columns: GridColDef<MaintenanceEvent>[] = [
        {
            field: "date",
            headerName: "Date",
            editable: false,
            flex: 1,
            renderCell: (params: any) => (
                <Link sx={{ fontWeight: "bold" }} href={PATH_MAINTENANCE_EVENT.root + "/" + params.row.id}>
                    {dateTimeDotNetToShortDate(params.value)}
                </Link>
            ),
        },
        {
            field: "photos",
            headerName: "PJ",
            editable: false,
            flex: 0.1,
            valueGetter: (params: any) => (params.value.length === 0) ? null : params.value.length
        },
        {
            field: "site",
            headerName: "Site",
            editable: false,
            valueGetter: (params: any) => (params.value == null) ? null : params.value?.name,
            flex: 2,
        },
        {
            field: "supplier",
            headerName: "Prestataire",
            editable: false,
            valueGetter: (params: any) => (params.value == null) ? null : params.value?.name,
            flex: 2,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]

    return <List<MaintenanceEvent>
        rows={maintenanceEvents}
        columns={columns}
        searchFunc={searchMaintenanceEvent}
        customRedirectUrl="/maintenanceEvents"
        deletable={false}
        pathNew={pathNew}
        service={MAINTENANCE_EVENT_API}
        // getListMethod="list"
        canCreate={user?.authorities?.some(a => a === Role.ROLE_MANAGER)}
    />
}