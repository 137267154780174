export const DESCRIPTION_API = "descriptions";

export const DESCRIPTION_DEFAULT_TYPE: Description = {
  id: "",
  libelle: "",
};

export interface Description {
  id: number | "";
  libelle: string;
}
