import { Icon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_HOME } from "../../../routes/paths";
import { Supplier, SUPPLIER_API, SUPPLIER_DEFAULT_TYPE } from "../../../@types/supplier";

export default function SupplierListPage() {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Nom",
        editable: true,
        flex: 1,
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage<Supplier>
      title="Liste des prestataires"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des prestataires" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      editable
      defaultRow={SUPPLIER_DEFAULT_TYPE}
      service={SUPPLIER_API}
      deletable={true}
    />
  );
}

function searchFunc(type: Supplier, filterValue: string) {
  return type.name?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}
