import { ComponentsProps } from '@mui/material';
import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover' as 'hover'
      }
    }
  };
}
