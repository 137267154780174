import Container from "@mui/material/Container";
import { Box } from "@mui/system";
import { useEffect } from "react";
import Page from "../../layouts/Page";
import { PATH_AUTH } from "../../routes/paths";

// ----------------------------------------------------------------------
export default function LogoutPage() {
  useEffect(() => {
    localStorage.removeItem("accessToken");
    window.location.href = PATH_AUTH.login;
  }, []);

  return (
    <Page title="Déconnexion" withContainer noHeader>
      Déconnexion en cours ...
    </Page>
  );
}
