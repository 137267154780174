import { Box, Button, Icon, Stack, Typography } from "@mui/material";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Cellar, Site } from "../../@types/site";
import ICONS from "../../assets/icons";
import { RHFTextField } from "../../components/hook-form";

interface CellarSiteDetailsProps {
  methods: UseFormReturn<Site, any>;
}

const DEFAULT_ROW: Cellar = {
  name: "",
};

export const CellarSiteDetails = ({ methods }: CellarSiteDetailsProps) => {
  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "cellars",
  });

  const handleAdd = () => {
    append(DEFAULT_ROW);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="subtitle1" sx={{ color: "primary", mb: 3 }}>
        Emplacement :
      </Typography>

      <Stack
        //divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        spacing={2}
      >
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ width: 1 }}
            >
              <RHFTextField
                size="small"
                type="text"
                name={`cellars[${index}].name`}
                label="Emplacement"
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 296 } }}
              />

              <Button
                size="small"
                color="error"
                startIcon={<Icon>{ICONS.delete}</Icon>}
                onClick={() => handleRemove(index)}
              />
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Button
        size="small"
        startIcon={<Icon>{ICONS.add}</Icon>}
        onClick={handleAdd}
        sx={{ flexShrink: 0, mt: 1 }}
      >
        Ajouter
      </Button>
    </Box>
  );
};
