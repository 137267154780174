import { Icon } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import ICONS from "../../assets/icons";
import Page from "../../layouts/Page";
import { PATH_HOME, PATH_NON_COMPLIANCE } from "../../routes/paths";
import { NON_COMPLIANCE_API, NC_STATUS_MAP, NC_TYPES, NC_TYPE_MAP, NonCompliance, NC_TYPES_REALISATION, NC_TYPES_REALISATION_MAP } from "../../@types/nonCompliance";
import { FormCols, FormWithMethods } from "../../components/hook-form/Form";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Cellar, SITE_API, Site } from "../../@types/site";
import axiosInstance from "../../utils/axios";
import { PRODUCT_API, Product, ProductLight, getProductOptionLabel } from "../../@types/product";
import { CATEGORYNC_API, CategoryNC } from "../../@types/categoryNC";
import { HOST_API } from "../../config";
import { Media } from "../../@types/media";
import { downloadFile } from "../../utils/toolsPost";

// ----------------------------------------------------------------------
export default function NonCompliancePage() {
  const { isManager } = useAuth();
  const params = useParams();
  const [products, setProducts] = useState<ProductLight[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [cellars, setCellars] = useState<Cellar[]>([]);
  const [categories, setCategories] = useState<CategoryNC[]>([]);
  const [prevSite, setPrevSite] = useState<number | "" | undefined>("");

  const [nonCompliance, setNonCompliance] = useState<NonCompliance | undefined>(undefined);

  useEffect(() => {

    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));

    axiosInstance
      .get(PRODUCT_API + "/ListForSelect")
      .then((response: any) => setProducts(response.data));

    axiosInstance
      .get(CATEGORYNC_API)
      .then((response: any) => setCategories(response.data));
  }, []);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(NON_COMPLIANCE_API + "/" + params.id)
        .then((response: any) => initReclamation(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);


  const schema = Yup.object().shape({
    date: Yup.date().required("Date obligatoire"),
    description: Yup.string().required("Description obligatoire"),
    comment: Yup.string(),
    number: Yup.string(),
    categoryNC: Yup.object().nullable().required("Catégorie de non-conformité obligatoire"),
    typeNC: Yup.string().required("Type de non-conformité obligatoire"),
    typeRealisation: Yup.string().required("Type de prestation obligatoire"),
    status: Yup.string(),
    site: Yup.object().nullable(),
    cellar: Yup.object().nullable(),
    product: Yup.object().nullable(),
  });

  const defaultValues = useMemo(() => {

    return {
      id: nonCompliance?.id ?? undefined,
      date: nonCompliance?.date ?? new Date(),
      number: nonCompliance?.number ?? "",
      typeNC: nonCompliance?.typeNC ?? "", //NC_TYPES.BUSINESS,
      typeRealisation: nonCompliance?.typeRealisation ?? "", // NC_TYPES_REALISATION.INTERNAL,
      description: nonCompliance?.description ?? "",
      comment: nonCompliance?.comment ?? "",
      status: nonCompliance?.status ?? "0",
      categoryNC: nonCompliance?.categoryNC ?? "",
      site: nonCompliance?.site ?? "",
      cellar: nonCompliance?.cellar ?? "",
      product: nonCompliance?.product ?? "",
      // upload_photo:
      //   nonCompliance?.upload_photo ??
      //   (nonCompliance?.photo?.url ? HOST_API + nonCompliance?.photo?.url : undefined),
      upload_photos:
        nonCompliance?.upload_photos ??
        //  ((nonCompliance?.photos && nonCompliance?.photos.length > 0) ? nonCompliance?.photos?.map((p: Media) => { return { url: HOST_API + p.url } }) : undefined),
        nonCompliance?.photos,
      //undefined,
      photos: nonCompliance?.photos,


    }
  }, [nonCompliance])

  const methods = useForm<NonCompliance>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, setValue } = methods;

  const sitesItems = useMemo(
    () => sites.map((s) => ({ id: s.id, nom: s.name })),
    [sites]
  );

  const cellarsItems = useMemo(
    () => cellars.map((c) => ({ id: c.id, nom: c.name })),
    [cellars]
  );

  const categoriesItems = useMemo(
    () => categories.map((c) => ({ id: c.id, nom: c.value })),
    [categories]
  );

  const siteWatch = watch("site");

  useEffect(() => {
    //pour récupérer les données
    if (siteWatch !== "") {
      if (typeof siteWatch?.id === "number") {
        let site: Site | undefined = sites.find((s) => s.id === siteWatch?.id);
        if (site) {
          setCellars(site.cellars ?? []);
          if (typeof prevSite === "number" && prevSite !== site.id) {
            setValue("cellar", undefined);
          }
        } else {
          console.log("site non trouvé - siteWatch?.id : ", siteWatch?.id)
        }
      }
      setPrevSite(siteWatch?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteWatch]);

  const initReclamation = useCallback((newReclamation: NonCompliance) => {
    setNonCompliance(newReclamation);
  }, []);

  const title = params.id ? "Non-conformité " + nonCompliance?.number : "Nouvelle non-conformité";

  const formCols: FormCols<NonCompliance>[] = useMemo(() => {
    return [
      {
        xs: 4,
        elements: [
          [
            // {
            //   name: "upload_photo",
            //   label: "Photo",
            //   type: "file",
            //   required: false,
            // },
            {
              name: "upload_photos",
              label: "Photos",
              type: "files",
              required: false,
              accept: '',
              title: "Pièce(s) jointe(s)"
            },
            // {
            //   name: "photos",
            //   label: "Photos",
            //   type: "files",
            //   required: false,
            // },
          ],
        ]
      },
      {
        xs: 8,
        elements: [
          [
            {
              name: "description",
              label: "Description",
              required: true,
            },
            {
              name: "date",
              label: "Date",
              required: true,
              type: "date",
              xs: 6,
            },
            {
              name: "status",
              label: "Statut",
              required: true,
              type: "radio",
              options: Object.entries(NC_STATUS_MAP).map(([key, value]) => ({ value: +key, label: value })),
              xs: 6,
            },
            {
              name: "categoryNC",
              label: "Catégorie",
              type: "select",
              mode: "object",
              selectOptions: categoriesItems, // categories, //categories.map((cat: CategoryNC) => ({ id: cat.id, nom: cat.value })),
              xs: 6,
            },
            {
              name: "typeNC",
              label: "Type",
              required: true,
              type: "radio",
              options: Object.entries(NC_TYPE_MAP).map(([key, value]) => ({ value: key, label: value })),
              xs: 6,
            },
            {
              name: "site",
              label: "Site",
              type: "select",
              mode: "object",
              selectOptions: sitesItems,
              required: false,
              xs: 6,
            },
            {
              name: "typeRealisation",
              label: "Type",
              required: true,
              type: "radio",
              options: Object.entries(NC_TYPES_REALISATION_MAP).map(([key, value]) => ({ value: key, label: value })),
              xs: 6,
            },
            {
              name: "cellar",
              label: "Emplacement",
              type: "select",
              mode: "object",
              selectOptions: cellarsItems,
              required: false,
              xs: 6,
            },
            {
              name: "product",
              label: "Produit",
              type: "autocomplete",
              mode: "object",
              selectOptions: products,
              options: { getOptionLabel: getProductOptionLabel },
              required: false,
              xs: 6,
            },
            {
              name: "comment",
              label: "Suivi de la non-conformité",
              options: { multiline: true, minRows: 3 },
              required: false,
            },
          ]
        ]
      }
    ]
  }, [sitesItems, cellarsItems, categoriesItems, products])

  const transformDataBeforeSubmit = (data: any) => {

    let newData = { ...data };
    if (!params.id) {
      newData = { ...newData, userId: 0, number: "" }
    }

    if (newData.cellar?.id === "") {
      newData = { ...newData, cellar: undefined };
    }

    newData.categoryNCId = newData.categoryNC.id
    newData.categoryNC = undefined

    return newData;
  };

  return (
    <Page
      title={title}
      withContainer={!params.id}
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Liste des non-conformités", href: PATH_NON_COMPLIANCE.root },
          { name: "Non-conformité" },
        ],
      }}
    >
      <FormWithMethods<NonCompliance, Yup.AnyObjectSchema>
        formCols={formCols}
        methods={methods}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        obj={nonCompliance}
        setObj={initReclamation}
        isNew={params.id === undefined}
        isEdit={true}
        canDelete={isManager}
        defaultValues={defaultValues}
        service={NON_COMPLIANCE_API}
        transformDataBeforeSubmit={transformDataBeforeSubmit}
        otherButton={{ name: "Exporter en PDF", icon: ICONS.downloadFile, fct: () => downloadFile(NON_COMPLIANCE_API + "/exportPDF/" + params.id, "Non-Conformite.pdf") }}
      />


    </Page>
  );
}
