import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import 'moment/locale/fr';

LicenseInfo.setLicenseKey("ce1f713c4c8b830b417036a347d85d62Tz04MzQ1MyxFPTE3Mzg0OTAwNTcwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"} localeText={{ start: 'Début', end: 'Fin' }}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </LocalizationProvider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
