import { Button, IconButton, InputAdornment } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import ICONS from "../../assets/icons";
import Form, { FormCols } from "../../components/hook-form/Form";
import { HOST_API } from "../../config";
import Page from "../../layouts/Page";
import axiosInstance from "../../utils/axios";

interface FormValuesProps {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

// ----------------------------------------------------------------------
export default function ChangePasswordPage() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Ancien mot de passe obligatoire"),
    newPassword: Yup.string().required("Nouveau mot de passe obligatoire"),
    confirmPassword: Yup.string().required(
      "Confirmation mot de passe obligatoire"
    ),
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post(HOST_API + "/api/account/change_password", {
        OldPassword: data.oldPassword,
        NewPassword: data.newPassword,
        ConfirmPassword: data.confirmPassword,
      });
    } catch (error) {
      //console.error(error);
      throw error;
    }
  };

  const formCols: FormCols<FormValuesProps>[] = useMemo(
    () => [
      {
        elements: [
          [
            {
              name: "oldPassword",
              label: "Ancien mot de passe",
              type: showOldPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        edge="end"
                      >
                        <Icon>
                          {showOldPassword ? ICONS.eyeOff : ICONS.eye}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
            {
              name: "newPassword",
              label: "Nouveau mot de passe",
              type: showNewPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        <Icon>
                          {showNewPassword ? ICONS.eyeOff : ICONS.eye}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
            {
              name: "confirmPassword",
              label: "Confirmation du nouveau mot de passe",
              type: showConfirmPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        <Icon>
                          {showConfirmPassword ? ICONS.eyeOff : ICONS.eye}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
          ],
        ],
      },
    ],
    [showOldPassword, showNewPassword, showConfirmPassword]
  );

  return (
    <Page title="Modification du mot de passe" withContainer>
      <Form<FormValuesProps, Yup.AnyObjectSchema>
        schema={ChangePasswordSchema}
        onSubmit={onSubmit}
        formCols={formCols}
        submitLabel="Modifier"
        submitButtonProps={{ fullWidth: true, size: "large" }}
      />
    </Page>
  );
}
