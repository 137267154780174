import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Role, roleDescription, User, USER_API } from "../../../@types/user";
import ICONS from "../../../assets/icons";
import { FormCols, FormWithMethods } from "../../../components/hook-form/Form";
import Page from "../../../layouts/Page";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import axiosInstance from "../../../utils/axios";

// ----------------------------------------------------------------------

export default function UserPage() {
  const params = useParams();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get("/users/" + params.id)
        .then((response: any) => setUser(response.data));
  }, [params.id]);

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Prénom obligatoire").max(50),
    lastName: Yup.string().required("Nom obligatoire").max(50),
    email: Yup.string()
      .email("Email invalide")
      .required("Email obligatoire")
      .max(100)
      .min(5),
    activated: Yup.boolean(),
    authorities: Yup.array().required("Rôle obligatoire"),
  });

  const rolesItems = useMemo(
    () =>
      Object.values(Role).map((r) => ({
        id: r,
        nom: roleDescription(r as Role),
      })),
    []
  );

  const defaultValues = useMemo(
    () =>
    ({
      id: user?.id ?? undefined,
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
      activated: user?.activated ?? false,
      authorities:
        user?.authorities && user.authorities.length > 0
          ? [user.authorities[0]]
          : [Role.ROLE_USER],
    } as User),
    [user]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch } = methods;

  const watchAuthorities = watch("authorities");

  //convert authorities to array if is is a string
  useEffect(() => {
    if (watchAuthorities && typeof watchAuthorities === "string") {
      methods.setValue("authorities", [watchAuthorities]);
    }
  }, [watchAuthorities, methods]);

  const formCols: FormCols<any>[] = useMemo(
    () => [
      {
        elements: [
          [
            { name: "firstName", label: "Prénom" },
            { name: "lastName", label: "Nom" },
            { name: "email", label: "Email" },
            //{ name: "activated", label: "Activer", type: "switch" },
            {
              name: "authorities",
              label: "Rôle",
              type: "select",
              selectOptions: rolesItems,
              mode: "brut",
              options: { SelectProps: { multiple: false } },
            },
          ],
        ],
      },
    ],
    [rolesItems]
  );

  const sendAppLink = () => {

    axiosInstance
      .get(USER_API + "/sendAppLink/" + params.id)
      .then((response: any) => console.log(response.data));
  }

  return (
    <Page
      title={
        params.id
          ? "Utilisateur " + user?.firstName + " " + user?.lastName
          : "Nouvel utilisateur"
      }
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Admin" },
          { name: "Liste des utilisateurs", href: PATH_ADMIN.user.root },
          { name: "Utilisateur" },
        ],
      }}
      withContainer
    >
      <FormWithMethods<User, Yup.AnyObjectSchema>
        methods={methods}
        formCols={formCols}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        service={USER_API}
        obj={user}
        setObj={setUser}
        isNew={!params.id}
        isEdit={!!params.id}
        defaultValues={defaultValues}
        otherButton={{ name: "Envoyer lien vers application mobile", fct: sendAppLink }}
      />

    </Page>
  );
}
