import { memo, useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import Map, { MarkerDragEvent } from "react-map-gl";
import { Site } from "../../@types/site";
import { RHFTextField } from "../../components/hook-form";
// components
import { MapBoxProps, MapControl, MapMarker } from "../../components/map";

// ----------------------------------------------------------------------
function MapDraggableMarker({
  methods,
  isNew,
  ...other
}: MapBoxProps & {
  methods: UseFormReturn<Site, any>;
  isNew?: boolean;
}) {
  const longitude = methods.watch("longitude", 0) ?? 0;
  const latitude = methods.watch("latitude", 0) ?? 0;
  const [isLoaded, setIsLoaded] = useState(false);

  const onMarkerDrag = useCallback(
    (event: MarkerDragEvent) => {
      if (!event.lngLat.lat && !event.lngLat.lng) return;

      methods.setValue("longitude", event.lngLat.lng);
      methods.setValue("latitude", event.lngLat.lat);
    },
    [methods]
  );

  useEffect(() => {
    if (longitude === 3.797711508948757 && latitude === 48.97165412112263) {
      methods.setValue("longitude", 3.79771150894876);
      methods.setValue("latitude", 48.9716541211226);
    }
    if (
      longitude === 0 ||
      latitude === 0 ||
      (!isNew &&
        longitude === 3.797711508948757 &&
        latitude === 48.97165412112263)
    )
      return;
    setIsLoaded(true);
  }, [longitude, latitude, isNew]);

  if (!isLoaded) return null;
  return (
    <>
      <Map initialViewState={{ longitude, latitude, zoom: 12 }} {...other}>
        <MapControl />

        {/* latitude and longitude RHF fields (firefox is bad at number fields) */}
        <RHFTextField
          sx={{ display: "none" }}
          name="latitude"
          value={latitude}
        />
        <RHFTextField
          sx={{ display: "none" }}
          name="longitude"
          value={longitude}
        />
        <MapMarker
          // Random values, don't remove to keep the marker at the right position when zooming.
          offset={[10, 25]}
          longitude={longitude}
          latitude={latitude}
          anchor="bottom"
          draggable
          onDragEnd={onMarkerDrag}
        />
      </Map>
    </>
  );
}

export default memo(MapDraggableMarker);
