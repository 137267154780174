import {
  Box,
  Button,
  Icon,
  InputAdornment,
  Stack,
  TableContainer,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { GridValidRowModel } from "@mui/x-data-grid-pro";
import { ReactNode, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ICONS from "../../assets/icons";
import useTable, { getComparator } from "../../hooks/useTable";
import Scrollbar from "../Scrollbar";
import {
  CopastockDataGridProps,
  EditableCopastockDataGrid,
  NonEditableCopastockDataGrid,
} from "./CopastockDataGrid";

export interface ListProps<T extends GridValidRowModel>
  extends CopastockDataGridProps<T> {
  editable?: boolean;
  headerActions?: ReactNode[];
  filterMenu?: boolean;
  filters?: FilterType[];
  filterComponents?: ReactNode | ReactNode[];
  searchFunc?: (item: T, filterValue: any) => boolean;
  canCreate?: boolean;
  onCreate?: () => void;
  pathNew?: string;
  defaultRow?: T;
  onEdit?: Function;
  defaultTableProps?: any;
  onDataChange?: Function;
  setApiRef?: Function;
  apiRef?: any;
}

export default function List<T extends GridValidRowModel>(props: ListProps<T>) {
  const [apiRef, setApiRef] = useState();

  return (
    <ListWithApiRef {...props} apiRef={apiRef} setApiRef={setApiRef} />
  );
}

export function ListWithApiRef<T extends GridValidRowModel>({
  searchFunc,
  filterMenu = true,
  editable = false,
  headerActions,
  filterComponents,
  filters,
  canCreate,
  pathNew,
  setRows,
  defaultRow,
  rows,
  onCreate,
  defaultTableProps,
  onDataChange,
  setApiRef,
  apiRef,
  ...other
}: ListProps<T>) {

  const { order, orderBy, setPage } = useTable(defaultTableProps);
  const [search, setSearch] = useState("");
  const [filterValues, setFilterValues] = useState<FilterValue[] | undefined>(() => filters?.map(f => ({ name: f.name, value: "" })));

  const handleFilter = (filter: string) => {
    setSearch(filter);
    setPage(0);
  };

  const dataFiltered = useMemo(() => {
    const newData = applySortFilter({
      data: [...rows],
      searchValue: search,
      comparator: getComparator(order, orderBy as keyof T),
      searchFunc,
      filterValues
    });

    if (onDataChange) onDataChange(newData);
    return newData;
  }, [rows, search, order, orderBy, searchFunc, filterValues]);

  const handleFilterUpdate = (name: string) => (value: string) => {
    let newFilters = [...filterValues!];
    filterValues!.find(fv => fv.name === name)!.value = value;
    setFilterValues(newFilters);
  }

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ py: 1, px: 2 }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        {(headerActions || canCreate) && <Stack direction="row" sx={{ flex: 1 }} spacing={2}>
          {canCreate && <Button
            variant="contained"
            component={RouterLink}
            to={pathNew ?? ""}
            onClick={editable && !onCreate ? () => setRows && setRows([...rows, defaultRow as T]) : onCreate}
            startIcon={<Icon>{ICONS.add}</Icon>}
          >
            Nouveau
          </Button>}
          {headerActions}
        </Stack>}
        {(searchFunc || filterMenu || filters || filterComponents) && (
          <Stack direction="row" sx={{ flex: 1 }} spacing={2} justifyContent="flex-end">
            {filterMenu &&
              <Button variant="contained" startIcon={<Icon>{ICONS.filters}</Icon>} onClick={() => (apiRef as any).current.showFilterPanel()}>
                FILTRER
              </Button>
            }
            {filterComponents}
            {filters && filters.map(f => <Filter key={f.name} filter={f} value={filterValues?.find(fv => fv.name === f.name)?.value ?? ""} onChange={handleFilterUpdate(f.name)} />)}
            {searchFunc && <TextField
              value={search}
              onChange={(event) => handleFilter(event.target.value)}
              placeholder="Rechercher..."
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon sx={{ color: "text.disabled", width: 20, height: 20 }}>
                      {ICONS.search}
                    </Icon>
                  </InputAdornment>
                ),
              }}
              sx={{ width: 200, ml: 2 }}
            />
            }
          </Stack>
        )}
      </Stack>

      <Scrollbar>
        <TableContainer
          sx={{
            position: "relative",
            minWidth: 800,
          }}
        >
          <Box
            sx={{
              margin: "15px",
            }}
          >
            {editable ? (
              <EditableCopastockDataGrid {...other} rows={dataFiltered} setRows={setRows} setApiRef={setApiRef} />
            ) : (
              <NonEditableCopastockDataGrid {...other} rows={dataFiltered} setRows={setRows} setApiRef={setApiRef} />
            )}
          </Box>
        </TableContainer>
      </Scrollbar>
    </>
  );
}

// ----------------------------------------------------------------------

interface ApplySortFilterProps<T> {
  data: T[];
  comparator: (a: T, b: T) => number;
  searchValue: any;
  searchFunc?: (item: T, filterValue: any) => boolean;
  filterValues?: FilterValue[]
}

function applySortFilter<T>({
  data,
  comparator,
  searchValue,
  searchFunc,
  filterValues
}: ApplySortFilterProps<T>) {
  const stabilizedThis = data.map(
    (el: T, index: number) => [el, index] as const
  );

  stabilizedThis.sort((a, b) => {
    if ("total" in (a as any)[0]) return 1;
    if ("total" in (b as any)[0]) return -1;

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  data = stabilizedThis.map((el) => el[0]);

  if (searchValue && searchFunc) {
    data = data.filter((item: any) => searchFunc(item, searchValue));
  }

  filterValues?.filter(fv => fv.value !== "").forEach(fv => {
    data = data.filter((item: any) => {
      //if (fv.name.indexOf(".")) {
      let fieldNames = fv.name.split(".")
      let value = item;
      if (fieldNames.length > 1) {
        for (let i = 0; i < fieldNames.length - 1; i++) {
          value = value[fieldNames[i]];
          if (!value || typeof value !== 'object') {
            return false
          }
        }
      }
      value = value[fieldNames[fieldNames.length - 1]];
      return value?.toString().toLowerCase() === fv.value.toLowerCase()
      // }

      // let value = (typeof item[fv.name] === 'object') ? item[fv.name].id : item[fv.name];
      // return value?.toString().toLowerCase() === fv.value.toLowerCase()
    })
  })

  return data;
}

interface FilterType {
  type: "text" | "select";
  items?: { value: string, label: string }[];
  name: string;
  label: string;
}

interface FilterValue {
  name: string;
  value: string;
}

interface SpecificFilterProps {
  filter: FilterType;
  onChange: Function;
  value: string;
}

function Filter(props: SpecificFilterProps) {
  const { filter, value, onChange } = props;
  const { type, items, label } = filter;

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    type === "select" ?
      <TextField label={label} select value={value} onChange={handleValueChange} sx={{ width: 150 }} size="small">
        <MenuItem value="">&nbsp;</MenuItem>
        {items?.map((item, index) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
      </TextField>
      : null
  )
}
