// routes
import { PATH_HOME } from "./routes/paths";

// API
// ----------------------------------------------------------------------
export const HOST_API = (process.env.REACT_APP_HOST_API_KEY ?? "");
export const HOST_BO_API = HOST_API + "/api/bo/v1";

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_HOME; // as '/dashboard/app'

export const MAP_API = process.env.REACT_APP_MAPBOX_API;


// LAYOUT
// ----------------------------------------------------------------------
export const HEADER = 64;

export const NAV = {
  W_BASE: 260,
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
};
