import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Client, CLIENT_API } from "../../@types/client";
import { getProductOptionLabel, Product, PRODUCT_API, PRODUCT_TYPE_MAP, ProductLight } from "../../@types/product";
import { Cellar, Site, SITE_API } from "../../@types/site";
import { SwitchProduct } from "../../@types/switchProduct";
import ICONS from "../../assets/icons";
import { FormCols, FormWithMethods } from "../../components/hook-form/Form";
import Page from "../../layouts/Page";
import { PATH_HOME, PATH_PRODUIT } from "../../routes/paths";
import axiosInstance from "../../utils/axios";

interface SwitchProductProps { }

export default function SwitchProductPage(props: SwitchProductProps) {
    const [clients, setClients] = useState<Client[]>([]);
    const [products, setProducts] = useState<ProductLight[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [cellars, setCellars] = useState<Cellar[]>([]);
    const [prevSite, setPrevSite] = useState<number | "" | undefined>("");

    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance
            .get(SITE_API)
            .then((response: any) => setSites(response.data));

        axiosInstance
            .get(CLIENT_API)
            .then((response: any) => setClients(response.data));

        axiosInstance
            .get(PRODUCT_API + "/ListForSelect")
            .then((response: any) => setProducts(response.data));
    }, []);

    const clientsItems = useMemo(
        () => clients.map((c) => ({ id: c.id, nom: c.name })),
        [clients]
    );

    const sitesItems = useMemo(
        () => sites.map((s) => ({ id: s.id, nom: s.name })),
        [sites]
    );

    const cellarsItems = useMemo(
        () => cellars.map((c) => ({ id: c.id, nom: c.name })),
        [cellars]
    );

    const defaultValues: SwitchProduct = useMemo(
        () => ({
            date: new Date(),
            time: new Date(),
            productType: "",
            client: "",
            site: "",
            productIn: "",
            productOut: "",
            quantity: "",
        }),
        []
    );

    const schema = Yup.object().shape({
        date: Yup.date().required("Date obligatoire"),
        time: Yup.date().required("Heure obligatoire"),
        site: Yup.object().nullable().required("Site obligatoire"),
        client: Yup.object().nullable(),
        productIn: Yup.object().nullable().required("Produit initial obligatoire"),
        quantity: Yup.number().typeError("Quantité obligatoire"),
        productOut: Yup.object()
            .nullable()
            .required("Produit de destination obligatoire"),
    });

    const methods = useForm<SwitchProduct>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const { watch, setValue } = methods;

    const siteWatch = watch("site");

    useEffect(() => {
        //pour récupérer les données
        if (siteWatch !== "" && typeof siteWatch?.id === "number") {
            let site: Site | undefined = sites.find((s) => s.id === siteWatch?.id);
            if (site) {
                setCellars(site.cellars ?? []);
            }
        }

        if (typeof prevSite === "number") {
            setValue("cellarIn", "");
            setValue("cellarOut", "");
        }

        if (siteWatch !== "") setPrevSite(siteWatch?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteWatch]);

    const typeProductWatch = watch("productType");
    const clientWatch = watch("client");
    const productInWatch = watch("productIn");

    const productsItems = useMemo(
        () =>
            products
                .filter(
                    (p) =>
                        (typeProductWatch === undefined ||
                            typeProductWatch === "" ||
                            p.productType === typeProductWatch) &&
                        (clientWatch === undefined ||
                            clientWatch === "" ||
                            p.client_id === clientWatch.id)
                ),
        [products, typeProductWatch, clientWatch]
    );

    useEffect(() => {
        if (productInWatch !== null && productInWatch !== undefined && productInWatch !== "") {
            if (typeProductWatch === undefined || typeProductWatch === "") {
                let product = products.find((p) => p.id === productInWatch.id);
                if (product) {
                    setValue("productType", product.productType ?? "");
                    let productClientItems = clientsItems.filter((ci) => ci.id === product?.client_id)
                    if (productClientItems.length == 1) {
                        setValue("client", productClientItems[0]);
                    }
                }
            }
        }
    }, [productInWatch]);

    const formCols: FormCols<SwitchProduct>[] = useMemo(() => {
        return [
            {
                elements: [
                    [
                        {
                            name: "date",
                            label: "Date",
                            required: true,
                            type: "date",
                            xs: 3,
                        },
                        {
                            name: "time",
                            label: "Heure",
                            required: true,
                            type: "time",
                            xs: 3,
                        },
                        {
                            name: "site",
                            label: "Site",
                            type: "select",
                            mode: "object",
                            selectOptions: sitesItems,
                            required: true,
                            xs: 6,
                        },
                        {
                            name: "productType",
                            label: "Type de produit",
                            type: "select",
                            mode: "brut",
                            selectOptions: Object.entries(PRODUCT_TYPE_MAP).map(([key, value]) => ({ id: +key, nom: value })),
                            xs: 6,
                        },
                        {
                            name: "client",
                            label: "Client associé",
                            type: "select",
                            mode: "object",
                            selectOptions: clientsItems,
                            xs: 6,
                        },
                    ],
                    [
                        {
                            name: "productIn",
                            label: "Produit initial",
                            type: "autocomplete",
                            selectOptions: productsItems,
                            options: { getOptionLabel: getProductOptionLabel },
                            required: true,
                            xs: 6,
                        },
                        {
                            name: "cellarIn",
                            label: "Emplacement intitial",
                            type: "select",
                            mode: "object",
                            selectOptions: cellarsItems,
                            required: false,
                            xs: 6,
                        },
                        {
                            name: "quantity",
                            label: "Quantité",
                            required: true,
                            xs: 6,
                            type: "number",
                        },
                    ],
                    [
                        {
                            name: "productOut",
                            label: "Produit de destination",
                            type: "autocomplete",
                            selectOptions: productsItems,
                            options: { getOptionLabel: getProductOptionLabel },
                            required: true,
                            xs: 6,
                        },
                        {
                            name: "cellarOut",
                            label: "Emplacement de destination",
                            type: "select",
                            mode: "object",
                            selectOptions: cellarsItems,
                            required: false,
                            xs: 6,
                        },
                        {
                            name: "comment",
                            label: "Commentaire",
                            options: { multiline: true, minRows: 3 },
                            required: false,
                        },
                    ],
                ],
            },
        ];
    }, [clientsItems, sitesItems, productsItems, cellarsItems]);

    return (
        <Page
            title="Switch de produits"
            HeaderProps={{
                links: [
                    { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
                    { name: "Liste des produits", href: PATH_PRODUIT.root },
                    { name: "Switch de produits" },
                ],
            }}
        >
            <FormWithMethods<SwitchProduct, Yup.AnyObjectSchema>
                methods={methods}
                formCols={formCols}
                schema={schema}
                submitButtonProps={{ fullWidth: true, size: "large" }}
                // obj={product}
                // setObj={setProduct}
                isNew={true}
                defaultValues={defaultValues}
                service={PRODUCT_API + "/switch"}
                routeAfterSubmit="/operations"
                onCancel={() => navigate("/produits")}
                withPaper
            />
        </Page>
    );
}
