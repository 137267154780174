import { Icon, SxProps } from "@mui/material";
import ICONS from "../assets/icons";

interface IconBlockedProps {
    isBlocked?: boolean,
    sx?: SxProps
}

export default function IconBlocked(props: IconBlockedProps) {
    const { isBlocked, sx } = props

    // return <Icon sx={{ height: 24, ...sx }}>{(isBlocked) ? ICONS.lock : ICONS.unlock}</Icon>
    return (isBlocked) ? <Icon sx={{ height: 24, ...sx }}>{ICONS.lock}</Icon> : null

};