// assets
import { Link as RouterLink } from "react-router-dom";
import { Button, Typography, Link } from "@mui/material";
import Page from "../layouts/Page";
import React from "react";

// ----------------------------------------------------------------------
export default function PageDownloadApk() {

  let title = "Application mobile"
  return (
    // <ErrorPage title="Application mobile" text="Vous n'avez pas le droit d'accéder à cette page" illustration={<ForbiddenIllustration />} />
    <Page title={title} noHeader withContainer>
      <Typography variant="h3" paragraph>
        {title}
      </Typography>

      <Link href={"/appCoPaStock.apk"} >
        <Typography sx={{ my: { xs: 5, sm: 10 } }}>Lien pour télécharger l'application mobile</Typography>
      </Link>

      <Button to="/" component={RouterLink} size="large" variant="contained">
        Retour à l'accueil
      </Button>
    </Page>
  );
}
