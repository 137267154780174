import { Box, SxProps } from "@mui/material";
import { iconUrl } from "./file-thumbnail/utils";

interface IconProductTypeProps {
    code: string,
    sx?: SxProps
}

export default function IconProductType(props: IconProductTypeProps) {
    const { code, sx } = props

    switch (code) {
        case "WINE":
        case "0":
            return <Box
                component="img"
                src={iconUrl("ic_wine")}
                sx={{ height: 24, ...sx }}
            />

        case "BOX":
        case "1":
            return <Box
                component="img"
                src={iconUrl("ic_box")}
                sx={{ height: 24, ...sx }}
            />

        default:
            return null
    }
};