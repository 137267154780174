import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { Role } from "../../@types/user";
import ICONS from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import ListPage from "../../layouts/ListPage";
import { PATH_HOME, PATH_NON_COMPLIANCE } from "../../routes/paths";
import { NON_COMPLIANCE_API, NC_STATUS, NC_STATUS_MAP, NonCompliance } from "../../@types/nonCompliance";
import { dateTimeDotNetToShortDate } from "../../utils/formatDate";
import { getProductOptionLabel } from "../../@types/product";
import ExportButton from "../../components/ExportButton";

// ----------------------------------------------------------------------
export default function NonComplianceListPage() {
  const { user } = useAuth();

  const columns: GridColDef<NonCompliance>[] = useMemo(
    () => [
      {
        field: "number",
        headerName: "Numéro",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_NON_COMPLIANCE.root + "/" + params.row.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "date",
        headerName: "Date",
        editable: false,
        flex: 1,
        valueGetter: (params: any) => dateTimeDotNetToShortDate(params.value)
      },
      {
        field: "status",
        headerName: "Status",
        editable: false,
        valueGetter: (params: any) => NC_STATUS_MAP[params.value as NC_STATUS],
        flex: 1,
      },
      {
        field: "categoryNC",
        headerName: "Catégorie",
        editable: false,
        valueGetter: (params: any) => params.value.value,
        flex: 2,
      },
      {
        field: "photos",
        headerName: "PJ",
        editable: false,
        flex: 0.1,
        valueGetter: (params: any) => (params.value.length === 0) ? null : params.value.length
      },
      {
        field: "description",
        headerName: "Description",
        editable: false,
        flex: 5,
      },
      {
        field: "site",
        headerName: "Site",
        editable: false,
        valueGetter: (params: any) => (params.value == null) ? null : params.value?.name + ((params.row.cellar) ? " / " + params.row.cellar.name : ""),
        flex: 2,
      },
      {
        field: "product",
        headerName: "Produit",
        editable: false,
        valueGetter: (params: any) => (params.value == null) ? null : getProductOptionLabel(params.value),
        flex: 2,
      },
      {
        field: "user",
        headerName: "Auteur",
        editable: false,
        flex: 1,
        valueGetter: (params: any) => params.value.firstName + " " + params.value.lastName
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des non-conformités"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des non-conformités" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_NON_COMPLIANCE.new}
      service={NON_COMPLIANCE_API}
      getListMethod="list"
      canCreate={true}
      headerActions={[<ExportButton api={NON_COMPLIANCE_API} key="export" fileName="Non-conformites.xlsx" />]}
    />
  );
}

function searchFunc(nonCompliance: NonCompliance, filterValue: string) {
  filterValue = filterValue.toLowerCase();
  return (
    nonCompliance.description?.toLowerCase().indexOf(filterValue) !== -1 ||
    nonCompliance.number?.toLowerCase().indexOf(filterValue) !== -1 ||
    (nonCompliance.categoryNC !== "" && nonCompliance.categoryNC.value?.toLowerCase().indexOf(filterValue) !== -1) ||
    nonCompliance.user.firstName?.toLowerCase().indexOf(filterValue) !== -1) || (nonCompliance.user.lastName?.toLowerCase().indexOf(filterValue) !== -1
    );
}
