// @mui
import { AppBar, Box, BoxProps, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// hooks
import useOffSetTop from "../hooks/useOffSetTop";
// utils
import { bgBlur } from "../utils/cssStyles";
// config
import { HEADER } from "../config";
//
import Logo from "../components/Logo";
import useAuth from "../hooks/useAuth";
import navConfig from "./nav/config";
import { NavSectionHorizontal } from "../components/nav-section";

export default function Header() {
  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER);
  const { isAuthenticated } = useAuth();

  return (
    <AppBar sx={{ boxShadow: 0, backgroundColor: "#353d47" }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER,
          },
          minHeight: {
            xs: HEADER - 16,
          },
          paddingX: 6,
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            height: {
              xs: HEADER - 16,
            },
          }),
        }}
      >
        {/* <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}> */}
        <Logo sx={{ height: "80%" }} />

        <Box sx={{ flexGrow: 1 }} />

        {/* {isAuthenticated && <NavDesktop isOffset={isOffset} data={navConfig} />} */}
        {isAuthenticated && <NavSectionHorizontal data={navConfig} />}

        {/* </Container> */}
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
