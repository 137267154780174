import { CategoryNC } from "./categoryNC";
import { Media, UploadPhoto } from "./media";
import { ProductLight } from "./product";
import { Cellar, SiteLight } from "./site";
import { UserLight } from "./user";

export const NON_COMPLIANCE_API = "nonCompliances";


export enum NC_STATUS {
  IN_PROGRESS = "0",
  CLOSE = "1",
}

export const NC_STATUS_MAP = {
  [NC_STATUS.IN_PROGRESS]: "En cours",
  [NC_STATUS.CLOSE]: "Clôturé"
};

export enum NC_TYPES {
  INTERNAL = "NM",
  BUSINESS = "PM",
}

export const NC_TYPE_MAP = {
  [NC_TYPES.INTERNAL]: "Non métier",
  [NC_TYPES.BUSINESS]: "Prestation métier"
};

export enum NC_TYPES_REALISATION {
  INTERNAL = "IN",
  EXTERNAL = "EX",
}

export const NC_TYPES_REALISATION_MAP = {
  [NC_TYPES_REALISATION.INTERNAL]: "Prestation interne",
  [NC_TYPES_REALISATION.EXTERNAL]: "Prestation externe"
};

export interface NonCompliance {
  id: number | "";
  number: string;
  description: string;
  comment: string;
  categoryNC: CategoryNC | "";
  status: number | string;
  typeNC: string;
  typeRealisation: string;
  date: Date;
  user: UserLight;
  product?: ProductLight | "";
  site?: SiteLight | "";
  cellar?: Cellar | "";
  upload_photos?: UploadPhoto[] | string[] | string | Media[];
  photos?: Media[];
}
