import { PrestationType } from "./PrestationType";
import { ClientLight } from "./client";
import { ProductLight } from "./product";
import { Cellar, SiteLight } from "./site";
import { UserLight } from "./user";

export const OPERATION_API = "operations";
export const OPERATION_DESTINATIONS_API = OPERATION_API + "/destinations";
export const FORMAT_DATE = "DD/MM/yyyy à HH:mm";

export enum AllOperationType {
    IN = "0",
    OUT = "1",
    PREST = "2",
    PREST_IN = "3",
    PREST_OUT = "4"
}

export enum OperationType {
    IN = "0",
    OUT = "1",
}

export const OPERATION_TYPE_MAP = {
    [OperationType.IN]: "Entrée",
    [OperationType.OUT]: "Sortie"
};

export const ALL_OPERATION_TYPE_MAP = {
    [AllOperationType.IN]: "Entrée",
    [AllOperationType.OUT]: "Sortie",
    [AllOperationType.PREST]: "Prestation",
    [AllOperationType.PREST_IN]: "Entrée Prestation",
    [AllOperationType.PREST_OUT]: "Sortie Prestation"
};

export interface Operation {
    id: number;
    dateTime?: Date;
    date?: Date;
    time?: Date;
    type: string | "";
    product?: ProductLight | "";
    quantity?: number | "";
    client?: ClientLight | "";
    productType?: number | "";
    customs?: string;
    site?: SiteLight | "";
    cellar?: Cellar | "";
    jhiComment?: string;
    destination?: string;
    user?: UserLight;
    duplicate?: boolean;
    duplicateAccepted?: boolean;
    serviceType?: PrestationType;
}

export interface OperationEntry {
    id: number;
    date: Date;
    type: OperationType;
    quantity: number;
    product_id: number;
    product_reference: string;
    product_type: string;
    client_id: number;
    client_name: string;
    site_id: number;
    site_name: string;
    user_id: number;
    user_name: string;
    destination?: string;
    customs?: string;
    duplicate?: boolean;
    isSwitch?: boolean;
}

export function getOperationTypeName(code: number | string) {
    return ALL_OPERATION_TYPE_MAP[code as OperationType]
}