import { Button, Icon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ICONS from "../assets/icons";
import { PATH_PRODUIT } from "../routes/paths";

export default function SwitchButton() {
    return (
        <Button
            key="switch"
            variant="contained"
            component={RouterLink}
            to={PATH_PRODUIT.switch}
            startIcon={<Icon>{ICONS.switch}</Icon>}
        >
            Switch
        </Button>
    );
}