import {
  Box,
  Button,
  Divider,
  Icon,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Product, ProductSiteQuantity } from "../../@types/product";
import { Site } from "../../@types/site";
import ICONS from "../../assets/icons";
import { RHFSelect, RHFTextField } from "../../components/hook-form";
import ProductSiteStatus from "../../components/ProductSiteStatus";

interface StockProductDetailsProps {
  sites: Site[];
  methods: UseFormReturn<Product, any>;
}

const DEFAULT_ROW: ProductSiteQuantity = {
  siteId: undefined,
  initialQuantity: 0,
  currentQuantity: 0,
  site: { id: undefined, name: "" },
};

export const StockProductDetails = ({
  sites,
  methods,
}: StockProductDetailsProps) => {
  const { control } = methods;

  const siteItems = useMemo(
    () => sites.map((s) => ({ value: s.id, label: s.name })),
    [sites]
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "stocks",
  });

  const handleAdd = () => {
    append(DEFAULT_ROW);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ color: "primary", mb: 3 }}>
        Stocks
      </Typography>

      <Stack
        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        spacing={2}
      >
        {fields.map((item: ProductSiteQuantity, index: number) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ width: 1 }}
            >
              <ProductSiteStatus data={item} />
              <RHFSelect
                name={`stocks[${index}].siteId`}
                size="small"
                label="Site"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 200 }, minWidth: 120 }}
              >
                <MenuItem
                  value=""
                  sx={{ fontStyle: "italic", color: "text.secondary" }}
                >
                  Aucun
                </MenuItem>
                {siteItems.map((pi: any) => (
                  <MenuItem key={pi.value} value={pi.value}>
                    {pi.label}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                type="number"
                name={`stocks[${index}].initialQuantity`}
                label="Qté initiale"
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 96 } }}
              />

              <RHFTextField
                disabled
                size="small"
                type="number"
                name={`stocks[${index}].currentQuantity`}
                label="Qté actuelle"
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 96 } }}
              />

              <Button
                // size="small"
                color="error"
                startIcon={<Icon>{ICONS.delete}</Icon>}
                onClick={() => handleRemove(index)}
                sx={{ px: 6, border: "solid 1px" }}
              >
                Supprimer
              </Button>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Button
        size="small"
        startIcon={<Icon>{ICONS.add}</Icon>}
        onClick={handleAdd}
        sx={{ flexShrink: 0, mt: 1 }}
      >
        Ajouter
      </Button>
    </Box>
  );
};
