import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import {
  Product,
  ProductType,
  PRODUCT_API,
  PRODUCT_TYPE_MAP,
} from "../../@types/product";
import ICONS from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import ListPage from "../../layouts/ListPage";
import {
  PATH_ADMIN,
  PATH_CLIENT,
  PATH_HOME,
  PATH_PRODUIT,
} from "../../routes/paths";
import SwitchButton from "../../components/SwitchButton";
import { Site, SITE_API } from "../../@types/site";
import axiosInstance from "../../utils/axios";
import IconProductType from "../../components/IconProductType";
import { CLIENT_API, Client } from "../../@types/client";
import IconBlocked from "../../components/IconBlocked";

// ----------------------------------------------------------------------
export default function ProductListPage() {
  const { isManager } = useAuth();

  const [sites, setSites] = useState<Site[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    axiosInstance
      .get(CLIENT_API)
      .then((response: any) => setClients(response.data));
  }, [])

  useEffect(() => {
    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);

  const columns: GridColDef<Product>[] = useMemo(
    () => {

      const iconPhoto = <Icon>{ICONS.photo}</Icon>;
      const iconMedias = <Icon>{ICONS.attachment}</Icon>;

      return [
        {
          field: "hasPhoto",
          renderHeader: () => null,
          headerAlign: "center",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => ((params.value === true) ? iconPhoto : <></>),
          align: "center",
        },
        {
          field: "hasMedias",
          renderHeader: () => null,
          headerAlign: "center",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => ((params.value === true) ? iconMedias : <></>),
          align: "center",
        },
        {
          field: "reference",
          headerName: "Référence",
          editable: false,
          flex: 1,
          renderCell: (params: any) => (
            <Link
              sx={{ fontWeight: "bold" }}
              href={PATH_PRODUIT.root + "/" + params.row.id}
            >
              {params.value}
            </Link>
          ),
        },
        {
          field: "client_name",
          headerName: "Client",
          editable: false,
          flex: 1,
          renderCell: (params: any) => (
            <Link href={PATH_CLIENT.root + "/" + params.row.client_id}>
              {params.value}
            </Link>
          ),
        },
        {
          field: "productTypeIco",
          // renderHeader: () => <><IconProductType code="0" /> / <IconProductType code="1" sx={{ paddingLeft: 0.5 }} /></>,
          headerName: "",
          // headerAlign: "center",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => <IconProductType code={"" + params.row.productType} />,
          align: "center",
        },
        {
          field: "productType",
          headerName: "Type",
          editable: false,
          valueGetter: (params: { value: ProductType }) =>
            PRODUCT_TYPE_MAP[params.value as ProductType],
          flex: 0.5,
        },
        {
          field: "isBlocked",
          headerName: "",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => <IconBlocked isBlocked={params.value} />
        },
        {
          field: "site_name",
          headerName: "Site",
          editable: false,
          flex: 1,
          renderCell: (params: any) => (
            <Link href={PATH_ADMIN.site.root + "/" + params.row.site_id}>
              {params.value}
            </Link>
          ),
        },
        {
          field: "quantity",
          headerName: "Quantité",
          editable: false,
          flex: 0,
          type: "number",
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ]
    },
    []
  );

  return (
    <ListPage
      title="Liste des produits"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des produits" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_PRODUIT.new}
      service={PRODUCT_API}
      canCreate={isManager}
      getListMethod="list"
      getRowId={(r) => r.id + "-" + r.site_id}
      headerActions={[<SwitchButton key="switch" />]}
      filters={[
        {
          name: "productType",
          type: "select",
          label: "Type",
          items: Object.entries(PRODUCT_TYPE_MAP).map(([key, value]) => ({ value: key, label: value }))
        },
        {
          name: "site_id",
          type: "select",
          label: "Site",
          items: sites.map(s => ({ value: s.id + "", label: s.name! }))
        },
        {
          name: "client_id",
          type: "select",
          label: "Client",
          items: clients.map(s => ({ value: s.id + "", label: s.name! }))
        },
      ]}
    />
  );
}

function searchFunc(product: Product, filterValue: string) {
  filterValue = filterValue.toLowerCase();
  return (
    (product.reference &&
      product.reference.toLowerCase().indexOf(filterValue) !==
      -1) ||
    (product.client_name &&
      product.client_name.toLowerCase().indexOf(filterValue) !==
      -1) ||
    (product.site_name &&
      product.site_name.toLowerCase().indexOf(filterValue) !==
      -1) ||
    (product.type &&
      ProductType[product.type]
        .toLowerCase()
        .indexOf(filterValue) !== -1) ||
    false
  );
}
