import { IconButton, InputAdornment, Link, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Page from "../../layouts/Page";

import Icon from "@mui/material/Icon";

import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import ICONS from "../../assets/icons";
import { RHFCheckbox } from "../../components/hook-form";
import Form, { FormCols } from "../../components/hook-form/Form";
import useAuth from "../../hooks/useAuth";
import { PATH_AUTH } from "../../routes/paths";
import Container from "@mui/material/Container";

interface FormValuesProps {
  username: string;
  password: string;
  remember: boolean;
}

// ----------------------------------------------------------------------
export default function LoginPage() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email("Email invalid").required("Email obligatoire"),
    password: Yup.string().required("Mot de passe obligatoire"),
  });

  const onSubmit = async (data: FormValuesProps) => {
    await login(data.username, data.password).catch((e) => {
      throw { response: e };
    });
  };

  const defaultValues: FormValuesProps = {
    username: "",
    password: "",
    remember: false,
  };

  const formCols: FormCols<FormValuesProps>[] = useMemo(
    () => [
      {
        elements: [
          [
            { name: "username", label: "Email" },
            {
              name: "password",
              label: "Mot de passe",
              type: showPassword ? "text" : "password",
              options: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Icon>{showPassword ? ICONS.eyeOff : ICONS.eye}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            },
            <Stack
              key="remember"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <RHFCheckbox name="rememberMe" label="Se souvenir de moi" />
              <Link
                component={RouterLink}
                variant="subtitle2"
                to={PATH_AUTH.newPassword}
              >
                Mot de passe oublié ?
              </Link>
            </Stack>,
          ],
        ],
      },
    ],
    [showPassword]
  );

  return (
    <Page title="Connectez-vous" withContainer noHeader>
      <Box component="img" src="/logo_big.jpg" sx={{ pb: 4 }} />

      <Form<FormValuesProps, Yup.AnyObjectSchema>
        schema={LoginSchema}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        formCols={formCols}
        submitLabel="Se connecter"
        submitButtonProps={{ fullWidth: true, size: "large" }}
      />
    </Page>
  );
}
