
import { Maintenance } from "./maintenance";
import { Media, UploadPhoto } from "./media";
import { SiteLight } from "./site";
import { Supplier } from "./supplier";

export const MAINTENANCE_EVENT_API = "maintenanceEvents";

export interface MaintenanceEvent {
  id: number | "";
  date: string;
  comment: string;
  site: SiteLight | "";
  maintenance: Maintenance | "";
  supplier: Supplier | "";
  photos?: Media[];
  upload_photos?: UploadPhoto[] | string[] | string | Media[];
}

export function searchMaintenanceEvent(maintenanceEvent: MaintenanceEvent, filterValue: string) {
  filterValue = filterValue.toLowerCase();

  return (
    (maintenanceEvent && maintenanceEvent.maintenance) && maintenanceEvent.maintenance.object?.toLowerCase().indexOf(filterValue) !== -1) ||
    (maintenanceEvent.site && maintenanceEvent.site.name && maintenanceEvent.site.name?.toLowerCase().indexOf(filterValue) !== -1) ||
    (maintenanceEvent.supplier && maintenanceEvent.supplier.name && maintenanceEvent.supplier.name?.toLowerCase().indexOf(filterValue) !== -1) ||
    maintenanceEvent.comment?.toLowerCase().indexOf(filterValue) !== -1 ||
    maintenanceEvent.date?.toString().indexOf(filterValue) !== -1
}
