import { Button } from "@mui/material";
import { ExcelIcon } from "./image/CustomIcon";
import { HOST_BO_API } from "../config";
import axiosInstance from "../utils/axios";
import { AxiosResponse } from "axios";

interface ExportButtonProps {
    launchFct?: () => void
    api?: string
    name?: string
    fileName?: string
    fileType?: string
}

export default function ExportButton(props: ExportButtonProps) {
    const { launchFct, api, name, fileName } = props

    const exportExcel = () => {
        const FileDownload = require('js-file-download');

        let url = HOST_BO_API + "/" + api + "/export"
        axiosInstance.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
            FileDownload(response.data, fileName ?? 'export.xlsx');
        })

    }

    return (
        <Button
            variant="contained"
            onClick={launchFct ?? exportExcel}
            startIcon={<ExcelIcon />}
        > {name ?? "Export"}</Button>)
}