import { Navigate, Outlet, useRoutes } from "react-router-dom";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import GuestGuard from "../guards/GuestGuard";
import MainLayout from "../layouts";
import LoginPage from "../pages/auth/LoginPage";
import HomePage from "../pages/HomePage";
import Page404 from "../pages/Page404";
import UserListPage from "../pages/admin/user/UserListPage";
import UserPage from "../pages/admin/user/UserPage";
import SiteListPage from "../pages/admin/site/SiteListPage";
import { Role } from "../@types/user";
import AuthGuard from "../guards/AuthGuard";
import { NEW_TERM } from "./paths";
import SitePage from "../pages/admin/site/SitePage";
import Page403 from "../pages/Page403";
import PrestationTypeListPage from "../pages/admin/TypePrestation/PrestationTypeListPage";
import DescriptionListPage from "../pages/admin/parameters/DescriptionListPage";
import ContenanceListPage from "../pages/admin/parameters/ContenanceListPage";
import ClientListPage from "../pages/client/ClientListPage";
import ClientPage from "../pages/client/ClientPage";
import ProductPage from "../pages/product/ProductPage";
import ProductListPage from "../pages/product/ProductListPage";
import ChangePasswordPage from "../pages/auth/ChangePasswordPage";
import NewPasswordPage from "../pages/auth/NewPasswordPage";
import PrestationListPage from "../pages/prestation/PrestationListPage";
import PrestationPage from "../pages/prestation/PrestationPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import LogoutPage from "../pages/auth/Logout";
import OperationListPage from "../pages/operation/OperationListPage";
import OperationPage from "../pages/operation/OperationPage";
import StockListPage from "../pages/stock/StockListPage";
import SettingsPage from "../pages/settings/SettingsPage";
import SwitchProductPage from "../pages/product/SwitchProductPage";
import NonComplianceListPage from "../pages/nonCompliance/NonComplianceListPage";
import NonCompliancePage from "../pages/nonCompliance/NonCompliancePage";
import CategoryNCListPage from "../pages/admin/parameters/CategoryNCListPage";
import MaintenanceListPage from "../pages/admin/maintenance/MaintenanceListPage";
import MaintenancePage from "../pages/admin/maintenance/MaintenancePage";
import SupplierListPage from "../pages/admin/parameters/SupplierListPage";
import MaintenanceEventListPage from "../pages/maintenanceEvent/MaintenanceEventListPage";
import MaintenanceEventPage from "../pages/maintenanceEvent/MaintenanceEventPage";
import PageDownloadApk from "../pages/PageDownloadApk";
import MigrationPage from "../pages/admin/MigrationPage";
import { PrestationProvider } from "../contexts/PrestationContext";
import PrestationTypePage from "../pages/admin/TypePrestation/PrestationTypePage";
import { ProductProvider } from "../contexts/ProductContext";
import ProductTypeListPage from "../pages/admin/ProductType/ProductTypeListPage";
import ProductTypePage from "../pages/admin/ProductType/ProductTypePage";

export default function Router() {
  return useRoutes([
    {
      path: "*",
      element: <MainLayout />,
      children: [
        {
          path: "auth",
          children: [
            { element: <Navigate to="/auth/login" replace />, index: true },
            {
              path: "login",
              element: (
                <GuestGuard>
                  <LoginPage />
                </GuestGuard>
              ),
            },
            { path: "logout", element: <LogoutPage /> },
            { path: "change-password", element: <ChangePasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "reset-password/:key", element: <ResetPasswordPage /> },
          ],
        },
        {
          element: (
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            { element: <HomePage />, index: true },
            {
              path: "admin",
              element: (
                <RoleBasedGuard hasContent roles={[Role.ROLE_MANAGER]}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: "users",
                  children: [
                    { element: <UserListPage />, index: true },
                    { path: NEW_TERM, element: <UserPage /> },
                    { path: ":id", element: <UserPage /> },
                  ],
                },
                {
                  path: "sites",
                  children: [
                    { element: <SiteListPage />, index: true },
                    { path: NEW_TERM, element: <SitePage /> },
                    { path: ":id", element: <SitePage /> },
                  ],
                },
                {
                  path: "typesPrestation",
                  children: [
                    { element: <PrestationTypeListPage />, index: true },
                    { path: NEW_TERM, element: <PrestationTypePage /> },
                    { path: ":id", element: <PrestationTypePage /> },
                  ],
                },
                {
                  path: "descriptions",
                  element: <DescriptionListPage />,
                },
                {
                  path: "contenances",
                  element: <ContenanceListPage />,
                },
                {
                  path: "categoryNC",
                  element: <CategoryNCListPage />,
                },
                {
                  path: "suppliers",
                  element: <SupplierListPage />,
                },
                {
                  path: "productTypes",
                  children: [
                    { element: <ProductTypeListPage />, index: true },
                    { path: NEW_TERM, element: <ProductTypePage /> },
                    { path: ":id", element: <ProductTypePage /> },
                  ],
                },
                {
                  path: "maintenances",
                  children: [
                    { element: <MaintenanceListPage />, index: true },
                    { path: NEW_TERM, element: <MaintenancePage /> },
                    { path: ":id", element: <MaintenancePage /> },
                  ],
                },
                {
                  path: "migration",
                  element: <MigrationPage />,
                },
              ],
            },
            // { path: "reglages", children: [{ element: <SettingsPage />, index: true }] },
            { path: "reglages", element: <SettingsPage />, index: true },
            {
              path: "clients",
              children: [
                { element: <ClientListPage />, index: true },
                {
                  path: NEW_TERM,
                  element: (
                    <RoleBasedGuard hasContent roles={[Role.ROLE_MANAGER]}>
                      <ClientPage />
                    </RoleBasedGuard>
                  ),
                },
                { path: ":id", element: <ClientPage /> },
              ],
            },
            {
              path: "ncs",
              children: [
                { element: <NonComplianceListPage />, index: true },
                {
                  path: NEW_TERM,
                  element: (
                    <NonCompliancePage />
                  ),
                },
                { path: ":id", element: <NonCompliancePage /> },
              ],
            },
            {
              path: "maintenanceEvents",
              children: [
                { element: <MaintenanceEventListPage />, index: true },
                { path: NEW_TERM, element: (<RoleBasedGuard hasContent><MaintenanceEventPage /></RoleBasedGuard>), },
                { path: NEW_TERM + "/:maintenanceid", element: (<RoleBasedGuard hasContent><MaintenanceEventPage /></RoleBasedGuard>), },
                { path: ":id", element: <MaintenanceEventPage /> },
              ],
            },
            {
              path: "operations",
              children: [
                { element: <OperationListPage />, index: true },
                {
                  path: NEW_TERM,
                  element: (
                    <RoleBasedGuard hasContent>
                      <OperationPage />
                    </RoleBasedGuard>
                  ),
                },
                { path: ":id", element: <OperationPage /> },
              ],
            },
            {
              path: "prestations",
              children: [
                { element: <PrestationListPage />, index: true },
                {
                  path: NEW_TERM,
                  element: (
                    <PrestationPage />
                  ),
                },
                { path: ":id", element: <PrestationProvider><PrestationPage /></PrestationProvider> },
              ],
            },
            {
              path: "produits",
              children: [
                { element: <ProductListPage />, index: true },
                {
                  path: NEW_TERM,
                  element: (
                    <RoleBasedGuard hasContent roles={[Role.ROLE_MANAGER]}>
                      <ProductPage />
                    </RoleBasedGuard>
                  ),
                },
                { path: ":id", element: <ProductProvider><ProductPage /></ProductProvider> },
                { path: "switch", element: <SwitchProductPage /> },
              ],
            },
            {
              path: "stocks",
              children: [{ element: <StockListPage />, index: true }],
            },
          ],
        },
        { path: "apk", element: <PageDownloadApk /> },
        { path: "403", element: <Page403 /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
