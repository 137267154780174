// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import shadows from './shadows';
import { frFR } from '@mui/x-data-grid-pro';
import { frFR as pickersFrFR } from '@mui/x-date-pickers-pro';
import { frFR as coreFrFR } from '@mui/material/locale';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const THEME_OPTIONS: ThemeOptions = {
  palette: palette.light,
  typography,
  shape: { borderRadius: 8 },
  direction: "ltr",
  shadows: shadows("light"),
  customShadows: customShadows("light"),
};

export default function ThemeProvider({ children }: Props) {

  const theme = createTheme(THEME_OPTIONS, frFR, pickersFrFR, coreFrFR);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
