import { Icon } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FORMAT_DATE,
  Operation,
  OperationType,
  OPERATION_API,
  OPERATION_DESTINATIONS_API,
  OPERATION_TYPE_MAP,
} from "../../@types/operation";
import ICONS from "../../assets/icons";
import { FormCols, FormWithMethods } from "../../components/hook-form/Form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Page from "../../layouts/Page";
import { PATH_HOME, PATH_OPERATION } from "../../routes/paths";
import axiosInstance from "../../utils/axios";
import { Client, CLIENT_API } from "../../@types/client";
import { getProductOptionLabel, Product, PRODUCT_API, PRODUCT_TYPE_MAP, ProductLight } from "../../@types/product";
import { Cellar, Site, SITE_API } from "../../@types/site";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { PRESTATION_TYPE_API, PrestationType } from "../../@types/PrestationType";

export default function OperationPage() {
  const { isManager } = useAuth();
  const params = useParams();
  const [operation, setOperation] = useState<Operation | undefined>(undefined);
  const [clients, setClients] = useState<Client[]>([]);
  const [products, setProducts] = useState<ProductLight[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [cellars, setCellars] = useState<Cellar[]>([]);
  const [destinations, setDestinations] = useState<string[]>([]);
  const [prestationTypes, setPrestationTypes] = useState<PrestationType[]>([]);
  const [prevSite, setPrevSite] = useState<number | "" | undefined>("");

  useEffect(() => {
    axiosInstance
      .get(CLIENT_API)
      .then((response: any) => setClients(response.data));

    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));

    axiosInstance
      .get(PRODUCT_API + "/ListForSelect")
      .then((response: any) => setProducts(response.data));
  }, []);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(OPERATION_API + "/" + params.id)
        .then((response: any) => initOperation(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const initOperation = useCallback((newOperation: Operation) => {
    setOperation(newOperation);
  }, []);

  const clientsItems = useMemo(
    () => clients.map((c) => ({ id: c.id, nom: c.name })),
    [clients]
  );

  const sitesItems = useMemo(
    () => sites.map((s) => ({ id: s.id, nom: s.name })),
    [sites]
  );

  const cellarsItems = useMemo(
    () => cellars.map((c) => ({ id: c.id, nom: c.name })),
    [cellars]
  );

  const schema = Yup.object().shape({
    date: Yup.date().required("Date obligatoire"),
    time: Yup.date().required("Heure obligatoire"),
    type: Yup.string().required("Type obligatoire"),
    client: Yup.object().nullable().required("Client obligatoire"),
    product: Yup.object().nullable().required("Produit obligatoire"),
    quantity: Yup.number().required("Qauntité obligatoire"),
    site: Yup.object().nullable().required("Site obligatoire"),
    destination: Yup.string().required("Destination / Prestation obligatoire"),
  });

  const defaultValues = useMemo(() => {
    return {
      id: operation?.id ?? undefined,
      date: operation?.date ?? new Date(),
      time: operation?.date ?? new Date(),
      type: operation?.type ?? "",
      destination: operation?.destination ?? "",
      client: operation?.client ?? "",
      //product: (operation?.product) ? { id: operation.product.id, label: operation.product.reference } : "",
      product: operation?.product ?? "",
      productType: (operation?.product) ? operation.product.productType : "",
      customs: operation?.customs ?? "",
      quantity: operation?.quantity ?? "",
      site: operation?.site ?? "",
      cellar: operation?.cellar ?? "",
      jhiComment: operation?.jhiComment ?? "",
    };
  }, [operation]);

  const methods = useForm<Operation>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, setValue } = methods;

  const operationTypeWatch = watch("type");

  useEffect(() => {

    if (operationTypeWatch) {
      switch (operationTypeWatch) {

        case "" + OperationType.IN:
          if (prestationTypes.length == 0) {
            axiosInstance
              .get(PRESTATION_TYPE_API)
              .then((response: any) => setPrestationTypes(response.data));
          }
          break;

        case "" + OperationType.OUT:

          if (destinations.length == 0) {
            axiosInstance
              .get(OPERATION_DESTINATIONS_API)
              .then((response: any) => setDestinations(response.data));
          }

          break;

      }
    }

  }, [operationTypeWatch])


  const destinationItems = useMemo(
    () => {

      if (operationTypeWatch) {
        switch (operationTypeWatch) {

          case "" + OperationType.IN:
            return prestationTypes.map((pt) => pt.title)

          case "" + OperationType.OUT:
            return destinations.map((dest) => dest)

        }
      }

      return []
    },
    [operationTypeWatch, prestationTypes, destinations]
  );


  const clientWatch = watch("client");
  const typeProductWatch = watch("productType");

  const productsItems = useMemo(
    () =>
      products
        .filter(
          (p) =>
            (typeProductWatch === undefined ||
              typeProductWatch === "" ||
              p.productType === typeProductWatch) &&
            (clientWatch === undefined ||
              clientWatch === "" ||
              p.client_id === clientWatch.id)
        )
    ,
    [products, typeProductWatch, clientWatch]
  );


  const siteWatch = watch("site");

  useEffect(() => {
    //pour récupérer les données
    if (siteWatch !== "" && typeof siteWatch?.id === "number") {
      let site: Site | undefined = sites.find((s) => s.id === siteWatch?.id);
      if (site) {
        setCellars(site.cellars ?? []);
      }
    }

    if (typeof prevSite === "number") {
      setValue("cellar", undefined);
    }

    if (siteWatch !== "") setPrevSite(siteWatch?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteWatch]);

  const productWatch = watch("product");
  useEffect(() => {

    if (productWatch !== "" && typeof productWatch?.id === "number") {
      if (clientWatch !== undefined || clientWatch === "") {
        setValue("client", { id: productWatch.client_id });
      }
    }
  }, [productWatch]);



  const formCols: FormCols<Operation>[] = useMemo(() => {
    let libDestination = "Destination / Prestation";
    let operationType = (operationTypeWatch) ? operationTypeWatch : operation?.type ?? ""
    switch ("" + operationType) {

      case OperationType.IN:
        libDestination = "Prestation";
        break;
      case OperationType.OUT:
        libDestination = "Destination";
        break;

    }
    return [
      {
        elements: [
          [
            {
              name: "type",
              label: "Type d'opération",
              required: true,
              type: "radio",
              options: Object.entries(OPERATION_TYPE_MAP).map(([key, value]) => ({ value: +key, label: value })),
              xs: 6,
            },
            {
              name: "date",
              label: "Date",
              required: true,
              type: "date",
              xs: 3,
            },
            {
              name: "time",
              label: "Heure",
              required: true,
              type: "time",
              xs: 3,
            },
            {
              name: "productType",
              label: "Type de produit",
              type: "select",
              mode: "brut",
              selectOptions: Object.entries(PRODUCT_TYPE_MAP).map(([key, value]) => ({ id: +key, nom: value })),
              xs: 6,
            },
            {
              name: "client",
              label: "Client associé",
              type: "select",
              mode: "object",
              selectOptions: clientsItems,
              required: true,
              xs: 6,
            },
            {
              name: "product",
              label: "Produit",
              type: "autocomplete",
              // mode: "object",
              selectOptions: productsItems,
              options: { getOptionLabel: getProductOptionLabel },
              required: true,
              xs: 6,
            },
            {
              name: "destination",
              label: libDestination,
              type: "autocomplete",
              selectOptions: destinationItems,
              // options: { getOptionLabel: (option: any) => option.reference },
              required: true,
              xs: 6
            },
            { name: "quantity", label: "Quantité", required: true, xs: 6 },
            {
              name: "customs",
              label: "N° douane/remorque",
              xs: 6,
              required: false,
            },
            {
              name: "site",
              label: "Site",
              type: "select",
              mode: "object",
              selectOptions: sitesItems,
              required: true,
              xs: 6,
            },
            {
              name: "cellar",
              label: "Emplacement",
              type: "select",
              mode: "object",
              selectOptions: cellarsItems,
              required: false,
              xs: 6,
            },
            {
              name: "jhiComment",
              label: "Commentaire",
              options: { multiline: true, minRows: 3 },
              required: false,
            },
          ],
        ],
      },
    ];
  }, [clientsItems, productsItems, sitesItems, cellarsItems, operationTypeWatch, destinationItems]);

  const transformDataBeforeSubmit = (data: any) => {
    let date = moment(data.date);
    let time = moment(data.time);
    let dt: Date = date.toDate();
    dt.setHours(time.get("hour"));
    dt.setMinutes(time.get("minute"));

    let operationType = data.type;
    switch (operationType) {
      case "0":
        operationType = "INPUT";
        break;

      case "1":
        operationType = "OUTPUT";
        break;
    }
    let newData = { operationType: operationType, JhiDate: dt, ...data };

    if (newData.cellar?.id === "") {
      newData = { ...newData, cellar: undefined };
    }

    console.log("newData : ", newData)

    return newData;
  };

  let dt = moment(operation?.date);
  const title = params.id
    ? "Opération " + dt.format(FORMAT_DATE)
    : "Nouvelle opération";

  return (
    <Page
      withContainer
      title={title}
      HeaderProps={{
        links: [
          { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
          { name: "Liste des opérations", href: PATH_OPERATION.root },
          { name: "Opération" },
        ],
      }}
    >
      <FormWithMethods<Operation, Yup.AnyObjectSchema>
        formCols={formCols}
        methods={methods}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        obj={operation}
        setObj={initOperation}
        isNew={params.id === undefined}
        isEdit={true}
        canDelete={isManager}
        defaultValues={defaultValues}
        service={OPERATION_API}
        transformDataBeforeSubmit={transformDataBeforeSubmit}
      />
    </Page>
  );
}
