// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, MenuItem, TextField, TextFieldProps } from '@mui/material';
// ----------------------------------------------------------------------

interface IProps {
  name: string;
  children: any;
}

export default function RHFSelect({ name, children, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          helperText={error?.message}
          defaultValue={other.value ?? ""}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

export function RHFSelectWithNoChildren({ name, children, selectOptions, mode = 'object', ...other }: IProps & TextFieldProps & { selectOptions: any[], mode?: 'brut' | 'object' | 'id' }) {
  const { control } = useFormContext();


  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {

        return (

          <TextField
            {...field}
            select
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...other}
          >
            {!other?.SelectProps?.multiple && <MenuItem value="">&nbsp;</MenuItem>}
            {selectOptions?.map((option) => {

              return (

                <MenuItem key={option.id} value={mode === 'object' ? option : option.id}>
                  {other?.SelectProps?.multiple && <Checkbox checked={mode === 'object' ? field.value.indexOf(option) >= 0 : mode === 'id' ? field.value.filter((el: any) => (el === option.id)).length > 0 : field.value.indexOf(option.id) >= 0} />}
                  {(option.nom) ? option.nom : option.value}
                </MenuItem>
              )
            })}
          </TextField>
        )
      }
      }
    />
  );
}
