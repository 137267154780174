import "./App.css";
import ThemeProvider from "./theme";
import Router from "./routes";
import SnackbarProvider from "./components/snackbar/SnackbarProvider";

// map
import "./utils/mapboxgl";
import "mapbox-gl/dist/mapbox-gl.css";

function App() {

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
