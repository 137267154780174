import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import ICONS from "../../../assets/icons";
import { FormCols, FormWithMethods } from "../../../components/hook-form/Form";
import Page from "../../../layouts/Page";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import { PRODUCT_TYPE_API, ProductType } from "../../../@types/productType";
import axiosInstance from "../../../utils/axios";

// ----------------------------------------------------------------------

export default function ProductTypePage() {
  const params = useParams();
  const [productType, setProductType] = useState<ProductType | undefined>(undefined);

  useEffect(() => {
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get("/" + PRODUCT_TYPE_API + "/" + params.id)
        .then((response: any) => setProductType(response.data));
  }, [params.id]);

  const schema = Yup.object().shape({
    name: Yup.string().required("libellé obligatoire").max(50),
  });


  const defaultValues = useMemo(
    () =>
    ({
      id: productType?.id ?? undefined,
      code: productType?.code ?? "",
      name: productType?.name ?? "",
      blockedWhenIn: productType?.blockedWhenIn ?? false
    } as ProductType),
    [productType]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });


  const formCols: FormCols<any>[] = useMemo(
    () => [
      {
        elements: [
          [
            { name: "name", label: "Libellé" },
            { name: "code", label: "Code" },
            {
              name: "blockedWhenIn", label: "Blocage en entrée",
              type: "switch",
              xs: 6,
            },

          ],
        ],
      },
    ],
    []
  );


  return (
    <Page
      title={
        params.id
          ? productType?.name
          : "Nouveau type de produit"
      }
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Admin" },
          { name: "Liste des types de produit", href: PATH_ADMIN.product_type.root },
          { name: "Type de produit" },
        ],
      }}
      withContainer
    >
      <FormWithMethods<ProductType, Yup.AnyObjectSchema>
        methods={methods}
        formCols={formCols}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        service={PRODUCT_TYPE_API}
        obj={productType}
        setObj={setProductType}
        isNew={!params.id}
        isEdit={!!params.id}
        defaultValues={defaultValues}
      />

    </Page>
  );
}
