import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Client, CLIENT_API } from "../../@types/client";
import { Contenance, CONTENANCE_API } from "../../@types/contenance";
import { Description, DESCRIPTION_API } from "../../@types/description";
import {
  Product,
  ProductType,
  PRODUCT_API,
  PRODUCT_TYPE_MAP,
} from "../../@types/product";
import { SiteLight, SITE_API } from "../../@types/site";
import {
  FieldList,
  FormCols,
  FormWithMethods,
} from "../../components/hook-form/Form";
import { HOST_API } from "../../config";
import axiosInstance from "../../utils/axios";
import { StockProductDetails } from "./StockProductDetails";
import { useProductContext } from "../../contexts/ProductContext";

interface Props {
}

// ----------------------------------------------------------------------
export default function ProductInformations({
}: Props) {
  const [clients, setClients] = useState<Client[]>([]);
  const [contenances, setContenances] = useState<Contenance[]>([]);
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  const [sites, setSites] = useState<SiteLight[]>([]);

  const { product, setProduct } = useProductContext()

  const schema = Yup.object().shape({
    reference: Yup.string().required("Référence obligatoire").max(50),
    type: Yup.number().required("Type obligatoire"),
    client: Yup.string().required("Client obligatoire"),
    bottle_date: Yup.date()
      .when("type", {
        is: ProductType.WINE,
        then: Yup.date().required("Date de bouteille obligatoire"),
      })
      .required("Date de bouteille obligatoire"),
    stocks: Yup.array(),
  });

  const defaultValues = useMemo(
    () => ({
      id: product?.id || undefined,
      reference: product?.reference || "",
      type: product?.type ?? 0,
      bottle_size: product?.bottle_size
        ? +(product?.bottle_size + "").replace(",", ".") + ""
        : "0.75",
      bottle_date: product?.bottle_date || new Date(),
      contents: product?.contents ?? "",
      color: product?.color ?? "",
      description: product?.description ?? "",
      client: product
        ? product?.client !== undefined &&
          typeof product?.client !== "number" &&
          product?.client !== ""
          ? product?.client?.id
          : product?.client
        : "",
      stocks: product?.stocks ?? [],
      upload_photo:
        product?.upload_photo ??
        (product?.photo?.url ? HOST_API + product?.photo?.url : undefined),
      photo: product?.photo ?? undefined,
      upload_photos: product?.upload_photos ?? product?.photos,
      photos: product?.photos,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product]
  );

  const methods = useForm<Product>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue, watch } = methods;
  const type = watch("type");

  const clientItems = useMemo(
    () => clients.map((c) => ({ id: c.id, nom: c.name })),
    [clients]
  );
  const contenancesItems = useMemo(
    () =>
      contenances.map((c) => ({
        id: c.valeur + "",
        nom: (c.valeur + " L").replace(".", ","),
      })),
    [contenances]
  );
  const descriptionsItems = useMemo(() => {
    let items = descriptions.map((c) => ({ id: c.libelle, nom: c.libelle }));

    if (product && product.description && product?.description != "") {

      if (!descriptions.some((d) => d.libelle === product?.description + ""))
        items.push({
          id: product?.description + "",
          nom: product?.description + "",
        });
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptions]);

  useEffect(() => {
    axiosInstance
      .get(CLIENT_API)
      .then((response: any) => setClients(response.data));

    axiosInstance
      .get(CONTENANCE_API)
      .then((response: any) => setContenances(response.data));

    axiosInstance
      .get(DESCRIPTION_API)
      .then((response: any) => setDescriptions(response.data));

    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);

  useEffect(() => {
    setValue("stocks", product?.stocks ?? []);
  }, [product]);

  const formCols: FormCols<Product>[] = useMemo(
    () => [
      {
        lg: 3,
        xs: 5,
        elements: [
          [
            <Typography variant="subtitle1" sx={{ color: "primary", mb: 1 }}>
              Photo & pièces jointes
            </Typography>,
            { name: "upload_photo", label: "Photo", type: "file", accept: "image/*", title: "Photo du produit" },
            {
              name: "upload_photos", label: "PJ", type: "files", required: false, accept: '', title: "Pièce(s) jointe(s)"
            },
          ],
        ],
      },
      {
        lg: 4,
        xs: 7,
        elements: [
          [
            <Typography variant="subtitle1" sx={{ color: "primary", mb: 1 }}>
              Informations
            </Typography>,
            { name: "reference", label: "Référence" },
            {
              name: "type",
              label: "Type de produit",
              type: "select",
              mode: "brut",
              selectOptions: Object.entries(PRODUCT_TYPE_MAP).map(
                ([key, value]) => ({ id: +key, nom: value })
              ),
            },
            ...(type === ProductType.WINE
              ? ([
                {
                  name: "bottle_size",
                  label: "Volume",
                  xs: 6,
                  type: "select",
                  mode: "brut",
                  selectOptions: contenancesItems,
                },
                {
                  name: "bottle_date",
                  label: "Date de mise en bouteille",
                  type: "date",
                  xs: 6,
                },
              ] as FieldList<Product>)
              : type === ProductType.BOX
                ? ([
                  {
                    name: "contents",
                    label: "Contenu de la caisse",
                    xs: 6,
                    required: false,
                  },
                  {
                    name: "color",
                    label: "Couleur de la caisse",
                    xs: 6,
                    required: false,
                  },
                ] as FieldList<Product>)
                : []),
            {
              name: "description",
              label: "Description",
              type: (type === ProductType.WINE) ? "select" : "text",
              mode: "brut",
              selectOptions: descriptionsItems,
              required: false,
            },
            {
              name: "client",
              label: "Client",
              type: "select",
              mode: "brut",
              selectOptions: clientItems,
            },
          ],
        ],
      },
      {
        lg: 5,
        xs: 12,
        elements: [[<StockProductDetails sites={sites} methods={methods} />]],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contenancesItems, descriptionsItems, clientItems, sites, product, type]
  );

  return (
    <FormWithMethods<Product, Yup.AnyObjectSchema>
      methods={methods}
      formCols={formCols}
      schema={schema}
      submitButtonProps={{ fullWidth: true, size: "large" }}
      obj={product}
      setObj={setProduct}
      isNew={product == undefined}
      isEdit={product != undefined}
      defaultValues={defaultValues}
      service={PRODUCT_API}
      withPaper
    />
  );
}
