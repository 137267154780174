import { yupResolver } from "@hookform/resolvers/yup";
import { Icon, styled } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Site, SITE_API } from "../../../@types/site";
import ICONS from "../../../assets/icons";
import { FormCols, FormWithMethods } from "../../../components/hook-form/Form";
import { HOST_API, MAP_API } from "../../../config";
import Page from "../../../layouts/Page";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import { CellarSiteDetails } from "../../../sections/site/CellarSiteDetails";
import MapDraggableMarker from "../../../sections/site/MapDraggableMarker";
import axiosInstance from "../../../utils/axios";

// ----------------------------------------------------------------------
const THEMES = {
  streets: "mapbox://styles/mapbox/streets-v11",
  outdoors: "mapbox://styles/mapbox/outdoors-v11",
  light: "mapbox://styles/mapbox/light-v10",
  dark: "mapbox://styles/mapbox/dark-v10",
  satellite: "mapbox://styles/mapbox/satellite-v9",
  satelliteStreets: "mapbox://styles/mapbox/satellite-streets-v11",
};

const StyledMapContainer = styled("div")(({ theme }) => ({
  zIndex: 0,
  overflow: "hidden",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right": {
    display: "none",
  },
}));

// ----------------------------------------------------------------------
export default function SitePage() {
  const params = useParams();

  const [site, setSite] = useState<Site | undefined>(undefined);
  const title = params.id ? "Site " + site?.name : "Nouveau site";

  const schema = Yup.object().shape({
    name: Yup.string().required("Nom requis"),
  });

  const defaultValues = useMemo(
    () => ({
      id: site?.id ?? undefined,
      name: site?.name ?? "",
      latitude: site?.latitude ?? 48.97165412112263, // default value to Le Baizil Co.Pa.Stock
      longitude: site?.longitude ?? 3.797711508948757,
      phone: site?.phone ?? "",
      address: site?.address ?? "",
      postcode: site?.postcode ?? "",
      city: site?.city ?? "",
      upload_photo:
        site?.upload_photo ??
        (site?.photo?.url ? HOST_API + site?.photo?.url : undefined),
      cellars: site?.cellars ?? [],
      stocks: site?.stocks ?? [],
      photo: site?.photo ?? undefined,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [site]
  );

  const methods = useForm<Site>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue } = methods;
  const longitude = methods.watch("longitude", 0);
  const latitude = methods.watch("latitude", 0);

  const formCols: FormCols<Site>[] = useMemo(
    () => [
      {
        xs: 4,
        elements: [
          [
            {
              name: "upload_photo",
              label: "Photo",
              type: "file",
              required: false,
              accept: "image/*"
            },
          ],
          [
            <StyledMapContainer
              style={{
                aspectRatio: 1,
              }}
            >
              <MapDraggableMarker
                methods={methods}
                isNew={!params.id}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                mapboxAccessToken={MAP_API}
                minZoom={3}
                mapStyle={THEMES.outdoors}
              />
            </StyledMapContainer>,
          ],
        ],
      },
      {
        xs: 4,
        elements: [
          [
            { name: "name", label: "Nom" },
            //{ name: "latitude", label: "Latitude", type: "number" },
            //{ name: "longitude", label: "Longitude", type: "number" },
            { name: "phone", label: "Téléphone", required: false },
            { name: "address", label: "Addresse", required: false },
            { name: "postcode", label: "Code Postal", required: false },
            { name: "city", label: "Ville", required: false },
          ],
          [{ name: "" as never, type: "submit" }],
        ],
      },
      {
        xs: 4,
        elements: [[<CellarSiteDetails methods={methods} />]],
      },
    ],
    [methods, longitude, latitude, params.id]
  );

  useEffect(() => {
    if (!setValue) return;
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance.get(SITE_API + "/" + params.id).then((response: any) => {
        setSite(response.data);
        setValue("cellars", response.data.cellars);
      });
  }, [params.id, setValue]);

  return (
    <Page
      title={title}
      HeaderProps={{
        links: [
          { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
          { name: "Admin" },
          { name: "Liste des sites", href: PATH_ADMIN.site.root },
          { name: "Site" },
        ],
      }}
    >
      <FormWithMethods<Site, Yup.AnyObjectSchema>
        methods={methods}
        formCols={formCols}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        service={SITE_API}
        obj={site}
        setObj={setSite}
        isNew={!params.id}
        isEdit={!!params.id}
        defaultValues={defaultValues}
        withPaper
      />
    </Page>
  );
}
