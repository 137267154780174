import { Theme } from "@mui/material/styles";
import { CheckboxProps } from "@mui/material";
//

import Icon from "@mui/material/Icon";
import ICONS from "../../assets/icons";

// ----------------------------------------------------------------------

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <Icon>{ICONS.checkbox}</Icon>,
        checkedIcon: <Icon>{ICONS.checkedCheckbox}</Icon>,
        indeterminateIcon: <Icon>{ICONS.indeterminateCheckbox}</Icon>,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => ({
          padding: theme.spacing(1),
          ...(ownerState.size === "small" && {
            "& svg": { width: 20, height: 20 },
          }),
          ...(ownerState.size === "medium" && {
            "& svg": { width: 24, height: 24 },
          }),
        }),
      },
    },
  };
}
